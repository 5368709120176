import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Country, State } from "api/resources/global/types"
import { format } from "date-fns"
import { employmentTypeOptions } from "features/Profile/components/WorkExperience/utils"
import { getNavigationPortal } from "features/SignupOnboarding/Navigation/utils"
import { FormPageProps } from "features/SignupOnboarding/types"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useResource } from "utils/hooks/useResource"

import { Calendar } from "@/components/Calendar"

type FormData = {
  title: string | null
  employmentType: string | null
  organisationName: string | null
  startDate: string | null
  stateId: string | null
  countryId: string | null
}

const CurrentWorkExperience = ({
  data,
  updateData,
  onNext,
  ...props
}: FormPageProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      title: data.workExperienceData?.title,
      employmentType: data.workExperienceData?.employmentType,
      organisationName: data.workExperienceData?.organisationName,
      startDate: data.workExperienceData?.startDate,
      stateId: data.workExperienceData?.state?.uuid,
      countryId: data.workExperienceData?.country?.uuid,
    },
  })

  const { countries } = useResource(["countries"])
  const [states, setStates] = useState<State[] | null | undefined>(null)

  const countryId = watch("countryId")

  const _addWorkExperience = async (formData: FormData) => {
    const postData = {
      ...formData,
      organisation_type: "school",
      end_date: null,
      currently_working: true,
      currency_id: null,
      salary: null,
      description: null,
    }

    try {
      if (data.workExperienceData == null) {
        const res = await api.profile.workExperiences.create({ data: postData })
        if (res.isSuccessful) {
          updateData({ workExperienceData: res.data })
        }
      } else {
        const res = await api.profile.workExperiences.update({
          data: postData,
          urlParams: {
            id: data.workExperienceData.id,
          },
        })
        if (res.isSuccessful) {
          updateData({ workExperienceData: res.data })
        }
      }
    } catch (e) {
      console.error("Failed to add work experience", e)
    }
  }

  const onSubmit = handleSubmit(async formData => {
    await _addWorkExperience(formData)
    await onNext()
  })

  useEffect(() => {
    if (countryId) {
      fetchStates(countryId)
    }
  }, [countryId])

  const fetchStates = async (countryId: Country["uuid"]) => {
    const res = await api.global.listStates({
      urlParams: {
        countryId,
      },
      params: {
        page: -1,
      },
    })
    if (res.isSuccessful) {
      setStates(res.data)
    } else {
      setStates(null)
    }
  }

  const formValues = watch()
  const isDisabled = Object.values(formValues).every(value => !value)

  return (
    <div>
      <form className="flex w-full max-w-[480px] flex-col">
        <div className="flex flex-col items-start gap-1 md:flex-row md:items-center">
          <Typography variant="title2" color="onSurface.900">
            Where are you currently teaching?
          </Typography>
        </div>
        <div className="mt-4" />
        <div className="flex w-full flex-col gap-2">
          <div className="flex w-full flex-col gap-2 md:flex-row">
            <TextField
              autoFocus
              label="Title"
              placeholder="Ex: Head of Department"
              type="string"
              {...register("title", {
                required: {
                  value: true,
                  message: "Required",
                },
              })}
              errors={errors.title?.message}
            />

            <Controller
              control={control}
              name="employmentType"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  errors={errors.employmentType?.message}
                  label="Employment Type"
                  ref={ref}
                  options={employmentTypeOptions}
                  value={employmentTypeOptions.find(c => c.value === value)}
                  isClearable
                  mountOnBody
                  onBlur={onBlur}
                  onChange={val => onChange(val?.value)}
                />
              )}
              rules={{
                required: { value: true, message: "Required" },
              }}
            />
          </div>
          <TextField
            label="Organisation/Institute Name"
            placeholder="Ex: Harvard University"
            type="string"
            errors={errors.organisationName?.message}
            {...register("organisationName", {
              required: {
                value: true,
                message: "Required",
              },
            })}
          />

          <Controller
            control={control}
            name="startDate"
            render={({ field: { onChange, value } }) => {
              const selected = value ? new Date(value) : undefined
              return (
                <Popover>
                  <PopoverTrigger className="w-full text-start">
                    <TextField
                      readOnly
                      label="Start Date"
                      placeholder="Click here to select"
                      value={value ? format(new Date(value), "PPP") : undefined}
                      errors={errors.startDate?.message}
                    />
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={selected}
                      required
                      onSelect={v => onChange(v.toISOString())}
                    />
                  </PopoverContent>
                </Popover>
              )
            }}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
        </div>
        <div className="mt-2 flex flex-col gap-2 md:flex-row">
          <Controller
            control={control}
            name="countryId"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Select
                getOptionLabel={({ name }) => name}
                getOptionValue={({ uuid }) => uuid}
                errors={errors.countryId?.message}
                label="country"
                options={countries}
                value={value ? countries.find(c => c.uuid === value) : null}
                isClearable
                mountOnBody
                onBlur={onBlur}
                ref={ref}
                onChange={val => {
                  if (val) {
                    setStates(undefined)
                    setValue("stateId", null)
                    fetchStates(val.uuid)
                  }
                  onChange(val ? val.uuid : "")
                }}
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />

          <Controller
            control={control}
            name="stateId"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Select
                getOptionLabel={({ name }) => name}
                getOptionValue={({ uuid }) => uuid}
                errors={errors.stateId?.message}
                label="State/Region/Province"
                options={states ?? []}
                placeholder="Ex: Dubai"
                value={
                  value ? states && states.find(c => c.uuid === value) : null
                }
                ref={ref}
                isClearable
                mountOnBody
                onBlur={onBlur}
                onChange={val => onChange(val ? val.uuid : "")}
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
        </div>
      </form>
      {getNavigationPortal({
        disableNext: isDisabled,
        onNext: onSubmit,
        onSkip: () => onNext(true),
        ...props,
      })}
    </div>
  )
}

export default CurrentWorkExperience
