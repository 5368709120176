import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getNexusUrl = getServiceURL(SERVICE_PREFIX.nexus)

export const urls = {
  list: () => getNexusUrl(`/v1/references/users/`),
  create: () => getNexusUrl(`/v1/references/users/`),
  update: id => getNexusUrl(`/v1/references/users/${id}/`),
  delete: id => getNexusUrl(`/v1/references/users/${id}/`),
  sendReminder: id => getNexusUrl(`/v1/references/users/${id}/remind/`),
  referenceAnalytics: userId =>
    getNexusUrl(`/v1/references/users/${userId}/analytics/`),
}
