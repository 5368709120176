import {
  Button,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
  Select,
  toast,
} from "@suraasa/placebo-ui"
import api from "api"
import { State } from "api/resources/global/types"
import { WorkLocationInterest } from "api/resources/profile/types"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"
import { useResource } from "utils/hooks/useResource"
import { trackingService } from "utils/tracking"

type Props = {
  onAdd: (data: WorkLocationInterest) => void
  onBack?: () => void
  source?: string
}

const AddDialog = ({
  onBack,
  onAdd,
  onRequestClose,
  source,
  open,
}: Props & { onRequestClose: () => void } & Pick<DialogProps, "open"> & {
    onBack?: () => void
  }) => {
  const { countries } = useResource(["countries"])

  const [states, setStates] = useState<State[]>([])

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
    setError,
  } = useForm<{ countryId: string; stateId: string }>()

  const [loading, setLoading] = useState(false)
  const [statesLoading, setStatesLoading] = useState(false)

  const fetchStates = async (countryId: string) => {
    setStatesLoading(true)
    setStates([])
    setValue("stateId", "")
    const res = await api.global.listStates({
      urlParams: { countryId },
      params: { page: "-1" },
    })

    if (res.isSuccessful) {
      setStates(res.data)
    }
    setStatesLoading(false)
  }

  useEffect(() => {
    if (!open) reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const onSubmit = handleSubmit(async data => {
    setLoading(true)

    const res = await api.profile.locations.create({ data })
    if (res.isSuccessful) {
      trackingService.trackEvent("profile_work_location_interest_added", {
        source,
      })
      onAdd(res.data)
      setLoading(false)
      reset()
      if (onBack) onBack()
    } else {
      if (
        res.errors.fieldErrors?.preferredWorkLocations &&
        typeof res.errors.fieldErrors?.preferredWorkLocations === "string"
      ) {
        setError("countryId", {
          message: res.errors.fieldErrors?.preferredWorkLocations,
        })
        toast.error(res.errors.fieldErrors?.preferredWorkLocations)
      }
      handleErrors(res, { setter: setError })
      setLoading(false)
    }
  })

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle onBack={onBack} className="text-strong">
          Add New Work Location
        </DialogTitle>
      </DialogHeader>
      <DialogBody className="px-3 pb-6 pt-2">
        <form onSubmit={onSubmit} className="flex flex-col gap-3">
          <div>
            <Controller
              control={control}
              name="countryId"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  errors={errors.countryId?.message}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.uuid}
                  label="Country"
                  options={countries}
                  placeholder="Ex: UAE"
                  required
                  value={
                    value
                      ? countries.find(country => country.uuid === value)
                      : null
                  }
                  mountOnBody
                  onBlur={onBlur}
                  onChange={val => {
                    if (val) {
                      fetchStates(val.uuid)
                      onChange(val.uuid)
                    } else {
                      setStates([])
                    }
                  }}
                />
              )}
              rules={{
                required: { value: true, message: "Required" },
              }}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="stateId"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  errors={errors.stateId?.message}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.uuid}
                  isLoading={statesLoading}
                  label="State/Region/Province"
                  required
                  options={states}
                  placeholder="Ex: Dubai"
                  value={
                    value ? states.find(state => state.uuid === value) : null
                  }
                  mountOnBody
                  onBlur={onBlur}
                  onChange={val => {
                    onChange(val?.uuid)
                  }}
                />
              )}
              rules={{
                required: { value: true, message: "Required" },
              }}
            />
          </div>
        </form>
      </DialogBody>

      <DialogFooter>
        <Button
          variant={"outlined"}
          size="sm"
          className="border-onSurface-500 text-onSurface-500"
          onClick={onRequestClose}
        >
          Close
        </Button>
        <Button
          onClick={onSubmit}
          size="sm"
          disabled={loading}
          loading={loading}
        >
          Save
        </Button>
      </DialogFooter>
    </DialogContent>
  )
}

export default AddDialog
