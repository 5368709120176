import { Checkbox, Typography } from "@suraasa/placebo-ui"
import api from "api"
import { ResumeFillerType } from "api/resources/aiTools/types"
import { TestScore } from "api/resources/profile/types"
import { format } from "date-fns"
import { getProficiencyLabel } from "features/Profile/components/Languages/utils"
import { getEmploymentTypeDisplay } from "features/Profile/components/WorkExperience/WorkExperienceItem"
import { Controller, useForm } from "react-hook-form"
import { buildPhoneNumber } from "utils/helpers"

import { ResumeForm } from "."
import BasicDetails from "./BasicDetails"

const checkboxContainerClass =
  "flex items-start gap-2 [&_label]:pointer-events-none"

const AddFromResume = ({
  data,
  id,
  afterSubmit,
}: {
  data: ResumeFillerType
  id: string
  afterSubmit: () => void
}) => {
  const languagesToConsider = data.languages?.filter(item => !item.id)
  const subjectsToConsider = data.subjects?.filter(item => !item.id)
  const skillsToConsider = data.skills?.filter(item => !item.id)
  const projectsToConsider = data.projects?.filter(item => !item.id)
  const publicationsToConsider = data.publications?.filter(item => !item.id)
  const awardsToConsider = data.awards?.filter(item => !item.id)
  const testScoresToConsider = data.testScores?.filter(item => !item.id)
  const interestsToConsider = data.interests?.filter(item => !item.id)
  const workLocationInterestsToConsider = data.workLocationInterests?.filter(
    item => !item.id
  )
  const workExperiencesToConsider = data.workExperiences?.filter(
    item => !item.id
  )
  const qualificationsToConsider = data.qualifications?.filter(item => !item.id)
  const certificationsToConsider = data.certifications?.filter(item => !item.id)

  const { control, handleSubmit } = useForm<ResumeForm>({
    defaultValues: {
      careerAspiration: data.careerAspiration,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      country: data.country,
      state: data.state,
      dateOfBirth: data.dateOfBirth,
      lookingForJobs: data.lookingForJobs,
      gender: data.gender,
      nationality: data.nationality,
      languages: languagesToConsider,
      subjects: subjectsToConsider,
      skills: skillsToConsider,
      awards: awardsToConsider,
      publications: publicationsToConsider,
      projects: projectsToConsider,
      workLocationInterests: workLocationInterestsToConsider,
      interests: interestsToConsider,
      testScores: testScoresToConsider,
      workExperiences: workExperiencesToConsider,
      qualifications: qualificationsToConsider,
      certifications: certificationsToConsider,
    },
  })

  const onSubmit = handleSubmit(async data => {
    const promises: Promise<any>[] = []
    const {
      firstName,
      lastName,
      gender,
      country,
      state,
      dateOfBirth,
      careerAspiration,
      phoneNumber,
      lookingForJobs,
      nationality,
      qualifications = [],
      certifications = [],
      awards = [],
      interests = [],
      publications = [],
      workExperiences = [],
      languages = [],
      skills = [],
      projects = [],
      subjects = [],
      workLocationInterests = [],
      testScores = [],
    } = data

    if (phoneNumber?.code && phoneNumber?.number) {
      promises.push(
        api.profile.update({
          data: {
            phoneNumber: buildPhoneNumber(phoneNumber),
          },
        })
      )
    }

    if (
      firstName ||
      lastName ||
      gender ||
      country ||
      state ||
      dateOfBirth ||
      careerAspiration ||
      lookingForJobs ||
      nationality
    ) {
      promises.push(
        api.profile.update({
          data: {
            ...(firstName ? { firstName: firstName.firstName } : {}),
            ...(lastName ? { lastName: lastName.lastName } : {}),
            ...(gender ? { gender: gender.gender } : {}),
            ...(country ? { country_id: country.uuid } : {}),
            ...(state ? { state_id: state.uuid } : {}),
            ...(dateOfBirth ? { dateOfBirth: dateOfBirth.dateOfBirth } : {}),
            ...(lookingForJobs
              ? { lookingForJobs: lookingForJobs.lookingForJobs }
              : {}),
            ...(nationality ? { nationality: nationality.uuid } : {}),
            ...(careerAspiration
              ? { careerAspiration: careerAspiration.careerAspiration }
              : {}),
          },
        })
      )
    }
    qualifications.forEach(item => {
      promises.push(api.profile.qualification.create({ data: item }))
    })
    certifications.forEach(item => {
      promises.push(api.profile.certification.create({ data: item }))
    })

    workExperiences.forEach(item => {
      promises.push(
        api.profile.workExperiences.create({
          data: {
            ...item,
            countryId: item.country?.uuid,
            stateId: item.state?.uuid,
            subjects: item.subjects?.map(x => x.subject.uuid),
            country: undefined,
            state: undefined,
            updated_field: undefined,
          },
        })
      )
    })

    awards.forEach(item => {
      promises.push(api.profile.awards.create({ data: item }))
    })
    publications.forEach(item => {
      promises.push(api.profile.publications.create({ data: item }))
    })
    testScores.forEach(item => {
      promises.push(api.profile.testScores.create({ data: item }))
    })
    projects.forEach(item => {
      promises.push(api.profile.projects.create({ data: item }))
    })
    skills.forEach(item => {
      promises.push(api.profile.skills.create({ data: item }))
    })

    languages.forEach(item => {
      promises.push(
        api.profile.languages.create({
          data: {
            languageId: item.language.uuid,
            proficiency: item.proficiency,
          },
        })
      )
    })
    subjects.forEach(item => {
      promises.push(
        api.profile.subjects.create({
          data: { subjectId: item.subject.uuid },
        })
      )
    })
    workLocationInterests.forEach(item => {
      promises.push(api.profile.locations.create({ data: item }))
    })
    interests.forEach(item => {
      promises.push(api.profile.interests.create({ data: item }))
    })

    await Promise.allSettled(promises)
    afterSubmit()
  })

  return (
    <div className="grow">
      <div className="rounded-lg bg-primary-50 p-2">
        <Typography variant="smallBody">
          Take a moment to review the suggested additions taken from your
          resume. All checked information will be added to your profile.
        </Typography>
      </div>
      <form id={id} className="flex flex-col divide-y-2" onSubmit={onSubmit}>
        <BasicDetails
          control={control}
          className="py-3"
          basicDetails={{
            firstName: data.firstName,
            lastName: data.lastName,
            gender: data.gender,
            country: data.country,
            state: data.state,
            dateOfBirth: data.dateOfBirth,
            phoneNumber: data.phoneNumber,
            lookingForJobs: data.lookingForJobs,
            nationality: data.nationality,
          }}
        />

        {data.careerAspiration && (
          <Controller
            control={control}
            name="careerAspiration"
            render={({ field: { onChange, value } }) => (
              <div className="py-3">
                <Typography variant="title3" className="mb-1.25">
                  Career Aspiration
                </Typography>

                <Checkbox
                  checked={
                    data.careerAspiration?.careerAspiration ===
                    value?.careerAspiration
                  }
                  containerClass={checkboxContainerClass}
                  onCheckedChange={checked => {
                    if (checked) {
                      onChange(data.careerAspiration)
                    } else {
                      onChange(null)
                    }
                  }}
                  label={
                    <Typography>
                      {data.careerAspiration?.careerAspiration}
                    </Typography>
                  }
                />
              </div>
            )}
          />
        )}

        {workExperiencesToConsider && workExperiencesToConsider.length > 0 && (
          <Controller
            control={control}
            name="workExperiences"
            render={({ field: { value, onChange } }) => (
              <div className="py-3">
                <Typography className="mb-2" variant="title2">
                  Work Experiences
                </Typography>

                <div className="flex flex-col space-y-1.5">
                  {workExperiencesToConsider.map((item, index) => {
                    const getUniqueKey = JSON.stringify

                    return (
                      <Checkbox
                        containerClass={checkboxContainerClass}
                        checked={
                          !!value?.find(
                            x => getUniqueKey(x) === getUniqueKey(item)
                          )
                        }
                        key={index}
                        onCheckedChange={checked => {
                          if (checked) {
                            onChange([...(value || []), item])
                          } else {
                            onChange(
                              value?.filter(
                                x => getUniqueKey(x) !== getUniqueKey(item)
                              )
                            )
                          }
                        }}
                        label={
                          <div>
                            <Typography variant="strong">
                              {item.title}
                            </Typography>
                            <Typography variant="smallBody">
                              {item.organisationName}
                            </Typography>
                            <Typography
                              variant="smallBody"
                              className="text-muted"
                            >
                              {[
                                getEmploymentTypeDisplay(item.employmentType),
                                [item.country?.name, item.state?.name]
                                  .filter(Boolean)
                                  .join(", "),
                              ]
                                .filter(Boolean)
                                .join(" • ")}
                            </Typography>
                            <Typography
                              variant="smallBody"
                              className="text-muted"
                            >
                              {[
                                item.curriculum?.name,
                                item.teachingLevel?.name,
                                item.teachingRole?.name,
                              ]
                                .filter(Boolean)
                                .join(" • ")}
                            </Typography>
                            {item.startDate && (
                              <Typography
                                variant="smallBody"
                                className=" text-muted"
                              >
                                {[
                                  format(new Date(item.startDate), "MMMM yyyy"),
                                  !item.endDate
                                    ? "Present"
                                    : format(
                                        new Date(item.endDate),
                                        "MMMM yyyy"
                                      ),
                                ]
                                  .filter(Boolean)
                                  .join(" - ")}
                              </Typography>
                            )}
                            {item.description && (
                              <Typography
                                variant="smallBody"
                                className="mt-1 text-muted"
                              >
                                {item.description}
                              </Typography>
                            )}
                          </div>
                        }
                      />
                    )
                  })}
                </div>
              </div>
            )}
          />
        )}

        {qualificationsToConsider && qualificationsToConsider.length > 0 && (
          <Controller
            control={control}
            name="qualifications"
            render={({ field: { value, onChange } }) => (
              <div className="py-3">
                <Typography className="mb-2" variant="title2">
                  Qualifications
                </Typography>

                <div className="flex flex-col space-y-1.5">
                  {qualificationsToConsider.map((item, index) => {
                    const getUniqueKey = JSON.stringify

                    return (
                      <Checkbox
                        containerClass={checkboxContainerClass}
                        checked={
                          !!value?.find(
                            x => getUniqueKey(x) === getUniqueKey(item)
                          )
                        }
                        key={index}
                        onCheckedChange={checked => {
                          if (checked) {
                            onChange([...(value || []), item])
                          } else {
                            onChange(
                              value?.filter(
                                x => getUniqueKey(x) !== getUniqueKey(item)
                              )
                            )
                          }
                        }}
                        label={
                          <div>
                            <Typography variant="strong">
                              {item.name}
                            </Typography>
                            <Typography variant="smallBody">
                              {item.organisationName}
                            </Typography>
                            <Typography
                              className="capitalize text-muted"
                              variant="smallBody"
                            >
                              {[
                                item.qualificationLevel?.name?.replace(
                                  /_/g,
                                  " "
                                ),
                                item.qualificationField?.name,
                              ]
                                .filter(Boolean)
                                .join(" - ")}
                            </Typography>
                            {item.startDate && (
                              <Typography
                                variant="smallBody"
                                className=" text-muted"
                              >
                                {[
                                  format(new Date(item.startDate), "MMMM yyyy"),
                                  !item.endDate
                                    ? "Present"
                                    : format(
                                        new Date(item.endDate),
                                        "MMMM yyyy"
                                      ),
                                ]
                                  .filter(Boolean)
                                  .join(" - ")}
                              </Typography>
                            )}
                            {item.grade && (
                              <Typography
                                variant="smallBody"
                                className=" text-muted"
                              >
                                Grade: {item.grade}
                              </Typography>
                            )}
                          </div>
                        }
                      />
                    )
                  })}
                </div>
              </div>
            )}
          />
        )}

        {certificationsToConsider && certificationsToConsider.length > 0 && (
          <Controller
            control={control}
            name="certifications"
            render={({ field: { value, onChange } }) => (
              <div className="py-3">
                <Typography className="mb-2" variant="title2">
                  Certifications
                </Typography>

                <div className="flex flex-col space-y-1.5">
                  {certificationsToConsider.map((item, index) => {
                    const getUniqueKey = JSON.stringify

                    return (
                      <Checkbox
                        containerClass={checkboxContainerClass}
                        checked={
                          !!value?.find(
                            x => getUniqueKey(x) === getUniqueKey(item)
                          )
                        }
                        key={index}
                        onCheckedChange={checked => {
                          if (checked) {
                            onChange([...(value || []), item])
                          } else {
                            onChange(
                              value?.filter(
                                x => getUniqueKey(x) !== getUniqueKey(item)
                              )
                            )
                          }
                        }}
                        label={
                          <div>
                            <Typography variant="strong">
                              {item.name}
                            </Typography>
                            <Typography variant="smallBody">
                              {item.organisationName}
                            </Typography>
                            {item.completionDate && (
                              <Typography
                                variant="smallBody"
                                className=" text-muted"
                              >
                                {[
                                  format(
                                    new Date(item.completionDate),
                                    "MMMM yyyy"
                                  ),
                                  !item.expirationDate
                                    ? null
                                    : format(
                                        new Date(item.expirationDate),
                                        "MMMM yyyy"
                                      ),
                                ]
                                  .filter(Boolean)
                                  .join(" - ")}
                              </Typography>
                            )}
                          </div>
                        }
                      />
                    )
                  })}
                </div>
              </div>
            )}
          />
        )}

        {awardsToConsider && awardsToConsider.length > 0 && (
          <Controller
            control={control}
            name="awards"
            render={({ field: { value, onChange } }) => (
              <div className="py-3">
                <Typography className="mb-2" variant="title2">
                  Awards
                </Typography>

                <div className="flex flex-col space-y-1.5">
                  {awardsToConsider.map((item, index) => (
                    <Checkbox
                      containerClass={checkboxContainerClass}
                      checked={!!value?.find(x => x.title === item.title)}
                      key={index}
                      onCheckedChange={checked => {
                        if (checked) {
                          onChange([...(value || []), item])
                        } else {
                          onChange(value?.filter(x => x.title !== item.title))
                        }
                      }}
                      label={
                        <div>
                          <Typography variant="strong">{item.title}</Typography>
                          <Typography variant="smallBody">
                            {item.issuer}
                          </Typography>
                          <Typography
                            variant="smallBody"
                            className=" text-muted"
                          >
                            {item.issuedOn}
                          </Typography>
                          {item.description && (
                            <Typography
                              variant="smallBody"
                              className="mt-1 text-muted"
                            >
                              {item.description}
                            </Typography>
                          )}
                        </div>
                      }
                    />
                  ))}
                </div>
              </div>
            )}
          />
        )}

        {publicationsToConsider && publicationsToConsider.length > 0 && (
          <Controller
            control={control}
            name="publications"
            render={({ field: { value, onChange } }) => (
              <div className="py-3">
                <Typography className="mb-2" variant="title2">
                  Publications
                </Typography>

                <div className="flex flex-col space-y-1.5">
                  {publicationsToConsider.map((item, index) => (
                    <Checkbox
                      containerClass={checkboxContainerClass}
                      checked={!!value?.find(x => x.title === item.title)}
                      key={index}
                      onCheckedChange={checked => {
                        if (checked) {
                          onChange([...(value || []), item])
                        } else {
                          onChange(value?.filter(x => x.title !== item.title))
                        }
                      }}
                      label={
                        <div>
                          <Typography variant="strong">{item.title}</Typography>
                          <Typography variant="smallBody">
                            {item.publisher}
                          </Typography>
                          <Typography
                            variant="smallBody"
                            className=" text-muted"
                          >
                            {item.publishedOn}
                          </Typography>
                          {item.description && (
                            <Typography
                              variant="smallBody"
                              className="mt-1 text-muted"
                            >
                              {item.description}
                            </Typography>
                          )}
                        </div>
                      }
                    />
                  ))}
                </div>
              </div>
            )}
          />
        )}

        {languagesToConsider && languagesToConsider.length > 0 && (
          <Controller
            control={control}
            name="languages"
            render={({ field: { value, onChange } }) => (
              <div className="py-3">
                <Typography className="mb-2" variant="title2">
                  Languages
                </Typography>

                <div className="flex flex-col space-y-1.5">
                  {languagesToConsider.map((item, index) => (
                    <Checkbox
                      containerClass={checkboxContainerClass}
                      checked={
                        !!value?.find(
                          x => x.language.uuid === item.language.uuid
                        )
                      }
                      key={index}
                      onCheckedChange={checked => {
                        if (checked) {
                          onChange([...(value || []), item])
                        } else {
                          onChange(
                            value?.filter(
                              x => x.language.uuid !== item.language.uuid
                            )
                          )
                        }
                      }}
                      label={
                        <div>
                          <Typography variant="strong">
                            {item.language.name}
                          </Typography>
                          <Typography variant="smallBody">
                            {getProficiencyLabel(item.proficiency)}
                          </Typography>
                        </div>
                      }
                    />
                  ))}
                </div>
              </div>
            )}
          />
        )}

        {subjectsToConsider && subjectsToConsider.length > 0 && (
          <Controller
            control={control}
            name="subjects"
            render={({ field: { value, onChange } }) => (
              <div className="py-3">
                <Typography className="mb-2" variant="title2">
                  Subjects
                </Typography>

                <div className="flex flex-col space-y-1.5">
                  {subjectsToConsider.map((item, index) => (
                    <Checkbox
                      containerClass={checkboxContainerClass}
                      checked={
                        !!value?.find(x => x.subject.uuid === item.subject.uuid)
                      }
                      key={index}
                      onCheckedChange={checked => {
                        if (checked) {
                          onChange([...(value || []), item])
                        } else {
                          onChange(
                            value?.filter(
                              x => x.subject.uuid !== item.subject.uuid
                            )
                          )
                        }
                      }}
                      label={
                        <Typography variant="strong">
                          {item.subject.name}
                        </Typography>
                      }
                    />
                  ))}
                </div>
              </div>
            )}
          />
        )}

        {skillsToConsider && skillsToConsider.length > 0 && (
          <Controller
            control={control}
            name="skills"
            render={({ field: { value, onChange } }) => (
              <div className="py-3">
                <Typography className="mb-2" variant="title2">
                  Skills
                </Typography>

                <div className="flex flex-col space-y-1.5">
                  {skillsToConsider.map((item, index) => (
                    <Checkbox
                      containerClass={checkboxContainerClass}
                      checked={
                        !!value?.find(x => x.skillName === item.skillName)
                      }
                      key={index}
                      onCheckedChange={checked => {
                        console.log(value, item, checked)
                        if (checked) {
                          onChange([...(value || []), item])
                        } else {
                          onChange(
                            value?.filter(x => x.skillName !== item.skillName)
                          )
                        }
                      }}
                      label={
                        <Typography variant="strong">
                          {item.skillName}
                        </Typography>
                      }
                    />
                  ))}
                </div>
              </div>
            )}
          />
        )}

        {projectsToConsider && projectsToConsider.length > 0 && (
          <Controller
            control={control}
            name="projects"
            render={({ field: { value, onChange } }) => (
              <div className="py-3">
                <Typography className="mb-2" variant="title2">
                  Projects
                </Typography>

                <div className="flex flex-col space-y-2">
                  {projectsToConsider.map((item, index) => (
                    <Checkbox
                      containerClass={checkboxContainerClass}
                      checked={!!value?.find(x => x.title === item.title)}
                      key={index}
                      onCheckedChange={checked => {
                        if (checked) {
                          onChange([...(value || []), item])
                        } else {
                          onChange(value?.filter(x => x.title !== item.title))
                        }
                      }}
                      label={
                        <div>
                          <Typography variant="strong">{item.title}</Typography>
                          <Typography variant="smallBody">
                            {[
                              item.startDate,
                              item.startDate === item.endDate
                                ? null
                                : item.endDate,
                            ]
                              .filter(Boolean)
                              .map(x => format(new Date(x!), "MMMM yyyy"))
                              .join(" - ")}
                          </Typography>
                          {item.description && (
                            <Typography
                              className="mt-0.5  text-muted"
                              variant="smallBody"
                            >
                              {item.description}
                            </Typography>
                          )}
                          {item.url && (
                            <Typography
                              asChild
                              className="mt-0.5 text-interactive-400 hover:underline"
                              variant="smallBody"
                            >
                              <a
                                href={item.url}
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                {item.url}
                              </a>
                            </Typography>
                          )}
                        </div>
                      }
                    />
                  ))}
                </div>
              </div>
            )}
          />
        )}

        {workLocationInterestsToConsider &&
          workLocationInterestsToConsider.length > 0 && (
            <Controller
              control={control}
              name="workLocationInterests"
              render={({ field: { value, onChange } }) => (
                <div className="py-3">
                  <Typography className="mb-2" variant="title2">
                    Work Location Interests
                  </Typography>

                  <div className="flex flex-col space-y-2">
                    {workLocationInterestsToConsider.map((item, index) => (
                      <Checkbox
                        containerClass={checkboxContainerClass}
                        checked={
                          !!value?.find(x => x.state?.uuid === item.state?.uuid)
                        }
                        key={index}
                        onCheckedChange={checked => {
                          if (checked) {
                            onChange([...(value || []), item])
                          } else {
                            onChange(
                              value?.filter(
                                x => x.state?.uuid !== item.state?.uuid
                              )
                            )
                          }
                        }}
                        label={
                          <Typography>
                            {[item.state?.name, item.country.name]
                              .filter(Boolean)
                              .join(", ")}
                          </Typography>
                        }
                      />
                    ))}
                  </div>
                </div>
              )}
            />
          )}

        {interestsToConsider && interestsToConsider.length > 0 && (
          <Controller
            control={control}
            name="interests"
            render={({ field: { value, onChange } }) => (
              <div className="py-3">
                <Typography className="mb-2" variant="title2">
                  Interests
                </Typography>

                <div className="flex flex-col space-y-2">
                  {interestsToConsider.map((item, index) => (
                    <Checkbox
                      containerClass={checkboxContainerClass}
                      checked={!!value?.find(x => x.interest === item.interest)}
                      key={index}
                      onCheckedChange={checked => {
                        if (checked) {
                          onChange([...(value || []), item])
                        } else {
                          onChange(
                            value?.filter(x => x.interest !== item.interest)
                          )
                        }
                      }}
                      label={<Typography>{item.interest}</Typography>}
                    />
                  ))}
                </div>
              </div>
            )}
          />
        )}

        {testScoresToConsider && testScoresToConsider.length > 0 && (
          <Controller
            control={control}
            name="testScores"
            render={({ field: { value, onChange } }) => (
              <div className="py-3">
                <Typography className="mb-2" variant="title2">
                  Test Scores
                </Typography>

                <div className="flex flex-col space-y-2">
                  {testScoresToConsider.map((item, index) => {
                    const getUniqueKey = (item: TestScore) =>
                      [
                        item.name,
                        item.score,
                        item.testDate,
                        item.description,
                      ].join(" - ")

                    return (
                      <Checkbox
                        containerClass={checkboxContainerClass}
                        checked={
                          !!value?.find(
                            x => getUniqueKey(x) === getUniqueKey(item)
                          )
                        }
                        key={index}
                        onCheckedChange={checked => {
                          if (checked) {
                            onChange([...(value || []), item])
                          } else {
                            onChange(
                              value?.filter(
                                x => getUniqueKey(x) !== getUniqueKey(item)
                              )
                            )
                          }
                        }}
                        label={
                          <div>
                            <Typography variant="strong">
                              {item.name}
                            </Typography>
                            {item.score && (
                              <Typography variant="smallBody">
                                Score: {item.score}
                              </Typography>
                            )}

                            {item.testDate && (
                              <Typography variant="smallBody">
                                {format(
                                  new Date(item.testDate),
                                  "MMMM d, yyyy"
                                )}
                              </Typography>
                            )}
                            {item.description && (
                              <Typography
                                className="mt-0.5 text-muted"
                                variant="smallBody"
                              >
                                {item.description}
                              </Typography>
                            )}
                          </div>
                        }
                      />
                    )
                  })}
                </div>
              </div>
            )}
          />
        )}
      </form>
    </div>
  )
}

export default AddFromResume
