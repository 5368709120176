import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
  Divider,
  InputLabel,
  Select,
  SplitTextField,
  TextArea,
  TextField,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import { theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import api from "api"
import { Qualification, WorkExperienceType } from "api/resources/profile/types"
import {
  Reference,
  SourceType,
  userReferenceRelationshipChoices,
} from "api/resources/references/types"
import { PhoneNumber } from "api/resources/users/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import ProfileContext from "features/Profile/context"
import { useContext, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import countryCodes from "utils/countryCodes"
import { buildPhoneNumber, mapErrors } from "utils/helpers"

import RemoveDialogShad from "@/common/RemoveDialogShad"

import { ConfirmationCard, getRelationshipLabel } from "./Utils"

const otherOptionValue = "Other"

type FormData = Omit<Reference, "id"> & {
  name: string
  email: string
  position: string
  phoneNumber: PhoneNumber
  yourPositionTitle: string
  userPosition: SelectType
}

type SelectType = {
  name: string
  uuid: string
  type: SourceType | null
}

type Props = Pick<DialogProps, "open"> & {
  data?: Reference | null
  currentWorkExperience?: WorkExperienceType | undefined
  currentQualification?: Qualification | undefined
  handleClose: () => void
}

const options = countryCodes.map(({ dialCode }) => ({
  value: dialCode,
  label: dialCode,
}))

const formatWorkExperience = (
  workExperience: WorkExperienceType | undefined
): string => {
  if (!workExperience) return ""

  const { title, organisationName } = workExperience

  return [title, organisationName].filter(Boolean).join(" at ")
}

const formatQualification = (
  qualification: Qualification | undefined
): string => {
  if (!qualification) return ""

  const { name, organisationName } = qualification

  return [name, organisationName].filter(Boolean).join(" from ")
}

const ReferenceEditDialog = ({
  data,
  open,
  currentWorkExperience,
  currentQualification,
  handleClose,
}: Props) => {
  const { references, workExperiences, academics } = useContext(ProfileContext)

  const createPositionsFromExperience = (): SelectType[] => {
    return [
      ...workExperiences.data.map(item => ({
        uuid: item.id,
        name: formatWorkExperience(item),
        type: SourceType.UserWorkExperience,
      })),
      {
        uuid: otherOptionValue,
        name: otherOptionValue,
        type: null,
      },
    ]
  }
  const createPositionsFromQualification = (): SelectType[] => {
    return [
      ...academics.qualifications.data.map(item => ({
        uuid: item.id.toString(),
        name: formatQualification(item),
        type: SourceType.UserQualification,
      })),
      {
        uuid: otherOptionValue,
        name: otherOptionValue,
        type: null,
      },
    ]
  }
  const positionsFromExperience = createPositionsFromExperience()
  const positionFromQualification = createPositionsFromQualification()

  const determineUserPosition = (): SelectType => {
    if (!data?.source) {
      const name = currentQualification
        ? formatQualification(currentQualification)
        : formatWorkExperience(currentWorkExperience)

      const validPositions = currentQualification
        ? positionFromQualification.filter(pos => pos.name !== otherOptionValue)
        : positionsFromExperience.filter(pos => pos.name !== otherOptionValue)

      const foundPosition =
        validPositions.find(pos => pos.name === name) ??
        (currentQualification
          ? positionFromQualification
          : positionsFromExperience
        ).find(pos => pos.name === otherOptionValue)

      return (
        foundPosition ?? {
          name: otherOptionValue,
          uuid: otherOptionValue,
          type: null,
        }
      )
    }

    const sourcePositions =
      data.source.type == SourceType.UserQualification
        ? positionFromQualification
        : positionsFromExperience

    const matchingPosition =
      sourcePositions.find(pos => pos.uuid === data.source?.id.toString()) ??
      sourcePositions.find(pos => pos.name === otherOptionValue)

    return (
      matchingPosition ?? {
        name: otherOptionValue,
        uuid: otherOptionValue,
        type: null,
      }
    )
  }

  const determinePositionTitle = (): string | undefined => {
    if (data?.source) {
      return undefined
    }

    return data?.userPosition
  }

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    clearErrors,
    unregister,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      name: data?.referrer.name,
      email: data?.referrer.email,
      phoneNumber: data?.referrer.phoneNumber
        ? {
            code: `+${data.referrer.phoneNumber.code}`,
            number: data.referrer.phoneNumber.number,
          }
        : undefined,
      position: data?.referrer.position,
      endDate: data?.endDate,
      startDate: data?.startDate,
      organisationName: data?.organisationName,
      message: data?.message,
      relationship: data?.relationship,
      userPosition: determineUserPosition(),
      yourPositionTitle: determinePositionTitle(),
    },
  })

  const [step, setStep] = useState<
    "referrer-name" | "referrer-details" | "confirmation" | undefined
  >("referrer-name")

  useEffect(() => {
    if (data) {
      setStep("referrer-details")
    }
  }, [])

  const isXs = useMediaQuery(theme.breakpoints.down("xs"))
  const isEditable = Boolean(data)
  const userPosition = watch("userPosition")
  const name = watch("name")

  const [referrerName, setReferrerName] = useState(
    data?.referrer.name ?? "Referrer"
  )

  const referrerEmail = watch("email")
  const hasChangedEmail = referrerEmail !== data?.referrer.email

  const formatData = (data: FormData) => {
    const baseData = {
      referrer: {
        name: data.name,
        email: data.email,
        position: data.position,
        phoneNumber: buildPhoneNumber(data.phoneNumber),
      },
      relationship: data.relationship,
      userPosition:
        userPosition.uuid == otherOptionValue
          ? data.yourPositionTitle
          : data.userPosition.name,
      organisation_name: data.organisationName || undefined,
      start_date: data.startDate || undefined,
      end_date: data.endDate || undefined,
      message: data.message?.trim() === "" ? null : data.message,
    }

    if (userPosition.uuid !== otherOptionValue) {
      return {
        ...baseData,
        source: {
          id: userPosition.uuid,
          type: userPosition.type,
        },
      }
    }
    return baseData
  }

  const onSubmit = handleSubmit(async formData => {
    try {
      if (data?.id) {
        await api.references.updateReference({
          data: formatData(formData),
          urlParams: { id: data.id },
        })
        updateReferences()
        if (hasChangedEmail) {
          setStep("confirmation")
        } else {
          handleClose()
        }
      } else {
        await api.references.createReference({
          data: formatData(formData),
        })
        updateReferences()
        setStep("confirmation")
      }
    } catch (error) {
      console.error(error)
      if (error instanceof APIError) {
        if (error.errors.fieldErrors?.referrer) {
          error.errors.fieldErrors = {
            ...error.errors.fieldErrors,
            ...((error.errors.fieldErrors.referrer as object) || {}),
          }
          mapErrors(setError, error, [
            ["name"],
            ["email"],
            ["phoneNumber"],
            ["position"],
          ])
        }
        mapErrors(setError, error, [
          ["endDate"],
          ["startDate"],
          ["organisationName"],
          ["message"],
          ["relationship"],
          ["userPosition"],
        ])
      }
    }
  })

  const handleRemove = async () => {
    if (!data) return

    try {
      await api.references.deleteReference({
        urlParams: { id: data.id },
      })
      updateReferences()
      toast.success("Removed successfully.")
      handleClose()
    } catch (error) {
      if (error instanceof APIError) {
        toast.error(error.message)
      } else {
        toast.error("An unexpected error occurred")
      }
    }
  }

  const updateReferences = async () => {
    try {
      references.refetch()
      workExperiences.refetch(false)
      academics.qualifications.refetch(false)
    } catch (e) {
      console.error(e)
    }
  }

  const selectedWorkExp = userPosition
    ? workExperiences.data.find(exp => exp.id === userPosition.uuid)
    : null

  const selectedQualification = userPosition
    ? academics.qualifications.data.find(
        exp => exp.id.toString() === userPosition.uuid
      )
    : null

  const hasSelectedOther = userPosition?.uuid === otherOptionValue

  const hasEmptyField = fieldName => {
    return (
      (selectedWorkExp && !selectedWorkExp[fieldName]) ||
      (selectedQualification && !selectedQualification[fieldName])
    )
  }

  return (
    <>
      <Dialog open={open} onOpenChange={handleClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              <Typography variant="strong">
                {isEditable ? "Edit" : "Add"} Reference
              </Typography>
            </DialogTitle>
          </DialogHeader>

          <DialogBody>
            {step === "confirmation" && (
              <ConfirmationCard
                isReminder={false}
                referrerName={referrerName}
              />
            )}
            {step === "referrer-name" && (
              <form
                className="flex flex-col gap-2"
                onSubmit={e => {
                  e.preventDefault()
                }}
              >
                <TextField
                  errors={errors.name}
                  helperText={errors.name?.message}
                  required
                  label="Name of the Referrer"
                  placeholder="Ex: Ashley Mathews"
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Required",
                    },
                    onBlur: e => {
                      const name = e.target.value
                      if (name) {
                        setReferrerName(name)
                      } else {
                        setReferrerName("Referrer")
                      }
                    },
                  })}
                />
              </form>
            )}
            {step === "referrer-details" && (
              <form className="flex flex-col gap-2">
                <TextField
                  errors={errors.name?.message}
                  required
                  label="Name of the Referrer"
                  placeholder="Ex: Ashley Mathews"
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Required",
                    },
                    onBlur: e => {
                      const name = e.target.value
                      if (name) {
                        setReferrerName(name)
                      } else {
                        setReferrerName("Referrer")
                      }
                    },
                  })}
                />

                <TextField
                  errors={errors.email?.message}
                  required
                  label={`Email of ${referrerName}`}
                  type="email"
                  placeholder="Ex: ashleymathews@xyz.org"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Required",
                    },
                  })}
                />

                <div>
                  <InputLabel
                    label={`Phone Number of ${referrerName}`}
                    required
                  />
                  <Controller
                    control={control}
                    name="phoneNumber"
                    render={({ field }) => (
                      <SplitTextField
                        fullWidth
                        error={Boolean(errors.phoneNumber)}
                        helperText={
                          errors.phoneNumber?.number?.message ||
                          errors.phoneNumber?.code?.message ||
                          errors.phoneNumber?.message
                        }
                        selectProps={{
                          placeholder: "+91",
                          options,
                          value: options.find(
                            c => c.value === field?.value?.code
                          ),
                          onChange: val => {
                            clearErrors()
                            if (val) setValue("phoneNumber.code", val.value)
                          },
                        }}
                        textFieldProps={{
                          type: "number",
                          placeholder: "Enter phone number",
                          value: field?.value?.number ?? undefined,
                          onChange: val => {
                            clearErrors()
                            setValue("phoneNumber.number", val.target.value)
                          },
                        }}
                      />
                    )}
                    rules={{
                      required: { value: true, message: "Required" },
                    }}
                  />
                </div>

                <TextField
                  errors={errors.position?.message}
                  required
                  label={`Position of ${referrerName}`}
                  placeholder="Ex: Principal"
                  {...register("position", {
                    required: {
                      value: true,
                      message: "Required",
                    },
                  })}
                />

                <Controller
                  control={control}
                  name="relationship"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      errors={errors.relationship?.message}
                      required
                      label={`How do you know ${referrerName}`}
                      options={userReferenceRelationshipChoices}
                      value={
                        value
                          ? { value, label: getRelationshipLabel(value) }
                          : null
                      }
                      ref={ref}
                      isClearable
                      onBlur={onBlur}
                      onChange={val => {
                        onChange(val?.value || null)
                      }}
                    />
                  )}
                  rules={{
                    required: { value: true, message: "Required" },
                  }}
                />

                <Divider className="my-1" />

                <Controller
                  control={control}
                  name="userPosition"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      errors={errors.userPosition?.message}
                      required
                      label="Your Position at the time?"
                      // @ts-expect-error types are not ideal in new select
                      options={[
                        ...positionsFromExperience,
                        ...positionFromQualification,
                      ]
                        .filter(
                          (pos, index, self) =>
                            index === self.findIndex(p => p.uuid === pos.uuid)
                        )
                        .map(pos => ({
                          label: pos.name,
                          value: pos,
                        }))}
                      value={
                        value
                          ? {
                              label: value.name,
                              value: value,
                            }
                          : null
                      }
                      ref={ref}
                      isClearable
                      fullWidth
                      onBlur={onBlur}
                      onChange={selectedOption => {
                        onChange(selectedOption?.value || null)
                        if (selectedOption?.value !== otherOptionValue) {
                          const workExp = workExperiences.data.find(
                            exp => exp.id === selectedOption?.value
                          )

                          const qualification =
                            academics.qualifications.data.find(
                              // @ts-expect-error types are not ideal in new select
                              exp => exp.id === selectedOption?.value
                            )

                          if (workExp?.title || qualification?.name)
                            unregister("yourPositionTitle")
                          if (
                            workExp?.organisationName ||
                            qualification?.organisationName
                          )
                            unregister("organisationName")
                          if (workExp?.startDate || qualification?.startDate)
                            unregister("startDate")

                          unregister("endDate")
                        }
                      }}
                    />
                  )}
                  rules={{
                    required: { value: true, message: "Required" },
                  }}
                />

                {(hasSelectedOther ||
                  selectedWorkExp ||
                  selectedQualification) && (
                  <>
                    {hasSelectedOther && (
                      <TextField
                        errors={errors.yourPositionTitle?.message}
                        required
                        label="Specify your position"
                        placeholder="Ex: Head of Department"
                        {...register("yourPositionTitle", {
                          required: {
                            value: true,
                            message: "Required",
                          },
                        })}
                      />
                    )}

                    {(hasSelectedOther ||
                      hasEmptyField("organisationName")) && (
                      <TextField
                        errors={errors.organisationName?.message}
                        required
                        label="Organisation Name"
                        placeholder="Ex: XYZ School"
                        {...register("organisationName", {
                          required: {
                            value: true,
                            message: "Required",
                          },
                        })}
                      />
                    )}

                    <div
                      className={clsx("flex gap-3", {
                        "flex-col": isXs,
                      })}
                    >
                      {(hasSelectedOther || hasEmptyField("startDate")) && (
                        <TextField
                          errors={errors.startDate?.message}
                          required
                          label="Start Date"
                          placeholder="Ex: Jan 2021"
                          type="date"
                          {...register("startDate", {
                            required: { value: true, message: "Required" },
                          })}
                        />
                      )}

                      <TextField
                        errors={errors.endDate?.message}
                        label="End Date"
                        placeholder="Ex: May 2021"
                        type="date"
                        {...register("endDate")}
                      />
                    </div>
                  </>
                )}

                <TextArea
                  rows={4}
                  errors={errors.message?.message}
                  label="Include a personalised message (optional)"
                  placeholder="Type here..."
                  {...register("message", {
                    maxLength: {
                      value: 3000,
                      message: "Try to keep it short",
                    },
                  })}
                />
              </form>
            )}
          </DialogBody>
          {step === "referrer-name" && (
            <DialogFooter>
              <Button
                disabled={!name}
                onClick={() => {
                  setStep("referrer-details")
                }}
              >
                Continue
              </Button>
            </DialogFooter>
          )}
          {step === "referrer-details" && (
            <DialogFooter className="items-center justify-between">
              <div>
                {isEditable && (
                  <RemoveDialogShad
                    trigger={
                      <Button variant="text" color="critical">
                        Remove
                      </Button>
                    }
                    title="Remove Reference?"
                    onRemove={handleRemove}
                  >
                    <Typography variant="smallBody">
                      Are you sure you want to remove this reference from your
                      profile?
                    </Typography>
                  </RemoveDialogShad>
                )}
              </div>
              <Button loading={isSubmitting} onClick={onSubmit}>
                {isEditable
                  ? hasChangedEmail
                    ? "Save and Send"
                    : "Save Details"
                  : "Send"}
              </Button>
            </DialogFooter>
          )}
          {step === "confirmation" && (
            <DialogFooter>
              <Button
                onClick={() => {
                  handleClose()
                }}
              >
                Okay
              </Button>
            </DialogFooter>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ReferenceEditDialog
