import axios from "api/axios"
import {
  ProfileStrengthLevelWiseCriteria,
  ProfileStrengthType,
} from "api/resources/profile/profileStrength/types"
import { APIResponse, ConfigWithURLParams } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { urls } from "./urls"

export default {
  retrieve: async ({
    urlParams,
  }: ConfigWithURLParams<"userId">): Promise<
    APIResponse<ProfileStrengthType>
  > => {
    try {
      const res = await axios.get(
        urls.profileStrength.retrieve(urlParams.userId)
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  criteria: {
    retrieve: async (): Promise<
      APIResponse<ProfileStrengthLevelWiseCriteria>
    > => {
      try {
        const res = await axios.get(urls.criteria.retrieve())
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
}
