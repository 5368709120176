import Paper from "features/AItools/components/Paper"
import { isNumberArray } from "features/AItools/helper"

import AIToolsError from "../../../helpers/AIToolsError"
import { InputDisplay } from "../../../helpers/InputDisplay"
import { LessonPlannerReturnType } from "../../../LessonPlanGenerator/useLessonPlanner"
import ToolOutput from "../../ToolOutput"
import Content, { ContentType } from "./Content"
export type ProOutputProps = Pick<
  LessonPlannerReturnType,
  | "lessonPlanResponseIds"
  | "overviewData"
  | "currentResponseId"
  | "setResponseIds"
  | "refetchOverview"
  | "promptDataId"
  | "onBack"
  | "streamPro"
  | "isError"
> &
  ContentType
const ProOutput = (props: ProOutputProps) => {
  const {
    lessonPlanResponseIds,
    overviewData,
    isLoading,
    regenerateResponse,
    userVote,
    currentStreamValues,
    finalizedOutputs,
    setCurrentResponseId,
    refetchOverview,
    promptDataId,
    onBack,
    isError,
  } = props

  const data =
    overviewData &&
    !isNumberArray(overviewData.responseIds) &&
    overviewData.responseIds.length > 0
      ? overviewData.responseIds
      : lessonPlanResponseIds

  return (
    <div className="mb-4 flex flex-col gap-2">
      {!isError && <InputDisplay data={{ ...overviewData }} />}

      <div className="flex flex-col gap-2">
        {isError && <AIToolsError onClick={onBack} />}
        {!isError && (
          <>
            {data &&
            data.filter(item => {
              if (!overviewData || !overviewData.responseIds.length) {
                return "hasOutput" in item
              } else {
                return true
              }
            }).length ? (
              data
                .filter(item => {
                  if (!overviewData || !overviewData.responseIds.length) {
                    return "hasOutput" in item
                  } else {
                    return true
                  }
                })
                .map((responseId, index) => {
                  const sequence = index + 1
                  return (
                    <Content
                      onBack={onBack}
                      handoutIds={responseId.handoutIds}
                      narrationIds={responseId.narrationIds}
                      responseIds={responseId.responseIds}
                      sequence={sequence}
                      key={index}
                      isLoading={isLoading}
                      regenerateResponse={regenerateResponse}
                      userVote={userVote}
                      currentStreamValues={currentStreamValues}
                      finalizedOutputs={finalizedOutputs}
                      setCurrentResponseId={setCurrentResponseId}
                      disableTabs={
                        overviewData && overviewData.responseIds.length === 0
                      }
                      refetchOverview={() => {
                        refetchOverview(promptDataId)
                      }}
                    />
                  )
                })
            ) : (
              <Paper className="p-0">
                <ToolOutput isLoading />
              </Paper>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ProOutput
