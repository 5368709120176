import { createPortal } from "react-dom"

import Navigation, { NavigationProps } from "."

export const getNavigationPortal = (props: NavigationProps) => {
  const element = document.querySelector(
    "#sign-up-onboarding-navigation-portal"
  )

  if (!element) return null

  return createPortal(<Navigation {...props} />, element)
}
