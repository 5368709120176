import { toast } from "@suraasa/placebo-ui"
import { useQueries, useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { context } from "global/Context/context"
import { useFeatureToggle } from "global/FeatureToggleProvider"
import { useContext, useMemo, useState } from "react"
import { useParams } from "react-router-dom"

import LineSpinner from "./components/ProfileStrength/LineSpinner"
import ProfileContext, { defaultContextValue } from "./context"
// import ReOrderingFeatureOnboarding from "./components/ReOrderingFeatureOnboarding"
import ProfilePage from "./ProfilePage"
import { usePreviewMode } from "./utils"

const Profile = () => {
  const { previewMode, isViewingAsOtherPlatform } = usePreviewMode()

  const profileStrengthIsEnabled = useFeatureToggle().profileStrength.isEnabled

  const { fetchProfile } = useContext(context)

  const { username } = useParams<{ username?: string }>()

  const isPublic = !!username || previewMode || isViewingAsOtherPlatform

  const [
    profileQuery,
    academicsQuery,
    workExperienceQuery,
    achievementsQuery,
    languageQuery,
    interestsQuery,
    skillEvidencesQuery,
    skillsQuery,
    masterclassCertificatesQuery,
    referencesQuery,
  ] = useQueries({
    queries: [
      {
        queryKey: queries.profile.profileData(username).queryKey,
        queryFn: () =>
          api.profile.retrieve({
            urlParams: {
              username: username ?? "",
            },
          }),
      },
      {
        queryKey: queries.profile.listAcademics(username).queryKey,
        queryFn: async () => {
          const academics = await api.profile.listAcademics({
            urlParams: { username: username ?? "" },
          })
          try {
            const references = await api.references.listReferences()
            const qualificationsWithRefs = academics.qualifications.map(
              exp => ({
                ...exp,
                references: references?.filter(ref => ref.source?.id == exp.id),
              })
            )
            academics.qualifications = qualificationsWithRefs
          } catch (e) {
            console.error(e)
          }
          return academics
        },
        initialData: {
          certifications: defaultContextValue.academics.certifications.data,
          qualifications: defaultContextValue.academics.qualifications.data,
        },
      },
      {
        queryKey: queries.profile.listWorkExperiences(username).queryKey,
        queryFn: async () => {
          const workExperiences = await api.profile.workExperiences.list({
            urlParams: { username: username ?? "" },
          })
          try {
            const references = await api.references.listReferences()
            const workExperiencesWithRefs = workExperiences.map(exp => ({
              ...exp,
              references: references?.filter(
                ref => ref.source?.id.toString() === exp.id
              ),
            }))
            return workExperiencesWithRefs
          } catch (e) {
            console.error(e)
          }
          return workExperiences
        },
        initialData: defaultContextValue.workExperiences.data,
      },
      {
        queryKey: queries.profile.listAchievements(username).queryKey,
        queryFn: () =>
          api.profile.listAchievements({
            urlParams: { username: username ?? "" },
          }),
        initialData: {
          publications: defaultContextValue.achievements.publications.data,
          awards: defaultContextValue.achievements.awards.data,
          testScores: defaultContextValue.achievements.testScores.data,
          projects: defaultContextValue.achievements.projects.data,
        },
      },
      {
        queryKey: queries.profile.listLanguage(username).queryKey,
        queryFn: () =>
          api.profile.languages.list({
            urlParams: { username: username ?? "" },
          }),
        initialData: defaultContextValue.languages.data,
      },
      {
        queryKey: queries.profile.listInterests(username).queryKey,
        queryFn: () =>
          api.profile.interests.list({
            urlParams: { username: username ?? "" },
          }),
        initialData: {
          preferredWorkLocations: defaultContextValue.workLocationInterest.data,
          interests: defaultContextValue.interests.data,
          subjects: defaultContextValue.subjects.data,
        },
        select: data => {
          return {
            ...data,
            preferredWorkLocations: [...data.preferredWorkLocations].sort(
              (a, b) => a.sequence - b.sequence
            ),
          }
        },
      },
      {
        queryKey: queries.profile.skillEvidences(username).queryKey,
        queryFn: () =>
          api.profile.skillEvidences.overview({
            urlParams: { username: username ?? "" },
          }),
        initialData: defaultContextValue.skillEvidences,
      },
      {
        queryKey: queries.profile.skills(username).queryKey,
        queryFn: () =>
          api.profile.skills.list({
            urlParams: { username: username ?? "" },
          }),
        initialData: defaultContextValue.skills.data,
        select: data => data?.sort((a, b) => a.sequence - b.sequence),
      },
      {
        queryKey:
          queries.profile.listMasterclassCertificates(username).queryKey,
        queryFn: () =>
          api.profile.credentials.listMasterclassCertificates({
            urlParams: { username: username ?? "" },
          }),
        initialData: defaultContextValue.masterclasses,
      },
      {
        queryKey: queries.profile.references().queryKey,
        queryFn: () => api.references.listReferences(),
        initialData: defaultContextValue.references.data,
      },
    ],
  })

  const publicUserId = username ? profileQuery.data?.user.uuid : undefined

  const videoPortfolioQuery = useQuery({
    queryKey: queries.profile.videoPortfolio(publicUserId).queryKey,
    queryFn: () =>
      publicUserId
        ? api.profile.videoPortfolio.retrieve({
            urlParams: { userId: publicUserId! },
          })
        : api.profile.videoPortfolio.retrieveSelf(),
  })

  const profileStrengthQuery = useQuery({
    enabled: profileStrengthIsEnabled,
    queryKey: queries.profile.profileStrength(publicUserId).queryKey,
    queryFn: () =>
      api.profile.strength.retrieve({
        urlParams: { userId: username ? publicUserId! : "" },
      }),
  })

  const profileStrengthCriteriaQuery = useQuery({
    enabled: profileStrengthIsEnabled,
    queryKey: queries.profile.profileStrengthCriteria().queryKey,
    queryFn: () => api.profile.strength.criteria.retrieve(),
  })

  const referenceAnalyticsQuery = useQuery({
    enabled: Boolean(profileQuery.data?.user.uuid),
    queryKey: queries.profile.referenceAnalytics(profileQuery.data?.user.uuid)
      .queryKey,
    queryFn: () =>
      api.references.referenceAnalytics({
        urlParams: {
          userId: profileQuery.data?.user.uuid ?? "",
        },
      }),
    initialData: defaultContextValue.referenceAnalytics.data,
  })

  const certifications = useMemo(
    () =>
      academicsQuery.data?.certifications ||
      defaultContextValue.academics.certifications.data,
    [academicsQuery.data]
  )
  const qualifications = useMemo(
    () =>
      academicsQuery.data?.qualifications ||
      defaultContextValue.academics.qualifications.data,
    [academicsQuery.data]
  )

  const publications = useMemo(
    () =>
      achievementsQuery.data?.publications ||
      defaultContextValue.achievements.publications.data,
    [achievementsQuery.data]
  )

  const awards = useMemo(
    () =>
      achievementsQuery.data?.awards ||
      defaultContextValue.achievements.awards.data,
    [achievementsQuery.data]
  )

  const testScores = useMemo(
    () =>
      achievementsQuery.data?.testScores ||
      defaultContextValue.achievements.testScores.data,
    [achievementsQuery.data]
  )

  const projects = useMemo(
    () =>
      achievementsQuery.data?.projects ||
      defaultContextValue.achievements.projects.data,
    [achievementsQuery.data]
  )
  const workLocationInterest = useMemo(
    () =>
      interestsQuery.data?.preferredWorkLocations ||
      defaultContextValue.workLocationInterest.data,

    [interestsQuery.data]
  )

  const interests = useMemo(
    () => interestsQuery.data?.interests || defaultContextValue.interests.data,
    [interestsQuery.data]
  )
  const subjects = useMemo(
    () => interestsQuery.data?.subjects || defaultContextValue.subjects.data,
    [interestsQuery.data]
  )
  const skillEvidences = useMemo(
    () =>
      skillEvidencesQuery.data
        ? skillEvidencesQuery.data
        : defaultContextValue.skillEvidences,
    [skillEvidencesQuery.data]
  )

  const [isPollingProfileStrength, setIsPollingProfileStrength] =
    useState(false)

  useQuery({
    queryKey: queries.profile.profileStrength().queryKey,
    queryFn: () => api.profile.strength.retrieve({ urlParams: { userId: "" } }),
    refetchInterval: 1000,
    enabled: isPollingProfileStrength && profileStrengthIsEnabled,
    structuralSharing(oldData, newData) {
      const progressHasChanged =
        oldData?.progress !== newData?.progress ||
        oldData?.levelName !== newData?.levelName

      if (progressHasChanged) {
        console.log("> Progress has changed")
        setIsPollingProfileStrength(false)
      }

      console.table(
        {
          old: oldData,
          new: newData,
        },
        ["levelName", "progress"]
      )

      return newData
    },
  })

  const pollProfileStrength = () => {
    if (!profileStrengthIsEnabled) return

    setIsPollingProfileStrength(true)

    toast("Updating Profile Strength", {
      className: "bg-onSurface-900 text-white rounded-xl",
      duration: 4000,
      closeButton: false,
      icon: <LineSpinner size="16px" color="white" stroke="1px" />,
    })

    setTimeout(() => {
      setIsPollingProfileStrength(false)
    }, 10000)
  }

  return (
    <ProfileContext.Provider
      value={{
        videoPortfolioURL: {
          data:
            videoPortfolioQuery.data?.videoPortfolio ||
            defaultContextValue.videoPortfolioURL.data,
          refetch: () => {
            videoPortfolioQuery.refetch()
            pollProfileStrength()
          },
        },
        masterclasses:
          masterclassCertificatesQuery.data ||
          defaultContextValue.masterclasses,
        isPublic,
        profile: profileQuery.data || defaultContextValue.profile,
        updateProfile: () => {
          profileQuery.refetch()
          fetchProfile()
          pollProfileStrength()
        },
        languages: {
          data: languageQuery.data || defaultContextValue.languages.data,
          refetch: () => {
            languageQuery.refetch()
            pollProfileStrength()
          },
        },
        achievements: {
          awards: {
            data: awards,
            refetch: () => achievementsQuery.refetch(),
          },
          projects: {
            data: projects,
            refetch: () => achievementsQuery.refetch(),
          },
          publications: {
            data: publications,
            refetch: () => achievementsQuery.refetch(),
          },
          testScores: {
            data: testScores,
            refetch: () => achievementsQuery.refetch(),
          },
        },
        workExperiences: {
          data:
            workExperienceQuery.data ||
            defaultContextValue.workExperiences.data,
          refetch: (updateProfileStrength = true) => {
            workExperienceQuery.refetch()
            if (updateProfileStrength) pollProfileStrength()
          },
        },
        references: {
          data: referencesQuery.data || defaultContextValue.references.data,
          refetch: (updateProfileStrength = true) => {
            referencesQuery.refetch()

            if (updateProfileStrength) pollProfileStrength()
          },
        },
        academics: {
          qualifications: {
            data: qualifications,
            refetch: (updateProfileStrength = true) => {
              academicsQuery.refetch()
              if (updateProfileStrength) pollProfileStrength()
            },
          },
          certifications: {
            data: certifications,
            refetch: () => {
              academicsQuery.refetch()
              pollProfileStrength()
            },
          },
        },
        subjects: {
          data: subjects,
          refetch: () => {
            interestsQuery.refetch()
            pollProfileStrength()
          },
        },
        workLocationInterest: {
          data: workLocationInterest,
          refetch: () => {
            interestsQuery.refetch()
            pollProfileStrength()
          },
        },
        interests: {
          data: interests,
          refetch: () => {
            interestsQuery.refetch()
            pollProfileStrength()
          },
        },
        skillEvidences,
        skills: {
          data: skillsQuery.data || defaultContextValue.skills.data,
          refetch: () => skillsQuery.refetch(),
        },
        referenceAnalytics: {
          data:
            referenceAnalyticsQuery.data ||
            defaultContextValue.referenceAnalytics.data,
          refetch: referenceAnalyticsQuery.refetch,
        },
        loading:
          profileQuery.isLoading ||
          academicsQuery.isLoading ||
          workExperienceQuery.isLoading ||
          achievementsQuery.isLoading ||
          languageQuery.isLoading ||
          interestsQuery.isLoading ||
          videoPortfolioQuery.isLoading ||
          skillEvidencesQuery.isLoading ||
          skillsQuery.isLoading ||
          masterclassCertificatesQuery.isLoading,

        // Profile Strength
        profileStrengthCriteria: profileStrengthCriteriaQuery.data,
        profileStrength: profileStrengthQuery,
        isPollingProfileStrength,
      }}
    >
      <ProfilePage />
    </ProfileContext.Provider>
  )
}

export default Profile
