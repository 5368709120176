import { AuthData } from "api/resources/users/types"
import snakeCase from "lodash/snakeCase"
import posthog from "posthog-js"
import { BROWSER_STORAGE_KEYS } from "utils/constants"
import { getAuthInfo } from "utils/helpers"
import { webengage } from "utils/webengage"

import { GA } from "./ga"

type Service = "WebEngage" | "GA" | "PostHog"

const allServices: Service[] = ["WebEngage", "GA", "PostHog"]

export const trackingService = {
  initUser: (
    authInfo: {
      user: Pick<AuthData["user"], "firstName" | "lastName" | "uuid" | "email">
    } & Pick<AuthData, "shadow">,
    services = allServices
  ) => {
    if (authInfo.shadow) return

    GA.setUserId(authInfo.user.uuid)

    try {
      if (webengage && services.includes("WebEngage")) {
        webengage.user.login(authInfo.user.uuid)
        webengage.user.setAttribute("we_email", authInfo.user.email)
        webengage.user.setAttribute("we_first_name", authInfo.user.firstName)
        webengage.user.setAttribute("we_last_name", authInfo.user.lastName)
      }
    } catch (e) {
      console.warn("Error initialising user on WebEngage", e)
    }
  },
  trackEvent: (eventName: string, data?: object, services = allServices) => {
    // console.log(`Tracking ${eventName} on services `, services, data)

    if (getAuthInfo()?.shadow) return

    const utmParams = trackingService.getUTMParams()

    // Adding UTM params into all events (picked up from cache if not present in the URL)
    data = {
      ...utmParams,
      ...(data || {}),
    }

    try {
      if (services.includes("PostHog")) {
        posthog.capture(eventName, data)
      }
    } catch (e) {
      console.warn(`Error tracking event on PostHog: ${eventName}`, data)
      console.warn(e)
    }

    try {
      if (services.includes("GA")) {
        // @ts-expect-error we can't type this
        GA.trackEvent(eventName, data)
      }
    } catch (e) {
      console.warn(`Error tracking event on GA: ${eventName}`, data)
      console.warn(e)
    }

    try {
      if (webengage && services.includes("WebEngage")) {
        webengage.track(eventName, data)
      }
    } catch (e) {
      console.warn(`Error tracking event on WebEngage: ${eventName}`, data)
      console.warn(e)
    }
  },
  setUserAttribute: (key: string, value: any, services = allServices) => {
    if (getAuthInfo()?.shadow) return

    // console.log(`> Tracking user attribute: ${key}=${value}`)
    try {
      if (webengage && services.includes("WebEngage")) {
        webengage.user.setAttribute(key, value)
      }
    } catch (e) {
      console.warn(`Error tracking user attribute on WebEngage: ${key}`, value)
      console.warn(e)
    }
  },

  getSignupSource: (source?: string) => {
    const searchParams = new URLSearchParams(window.location.search)

    const utmParams = trackingService.getUTMParams()

    const value =
      source ||
      searchParams.get("signup_source") ||
      utmParams.utm_source ||
      document.referrer ||
      "direct"

    return value
  },

  getUTMParams: (useCache = true): UTMParams => {
    const getUTMParamValueFromStorage = (key: string) => {
      const storedParams = sessionStorage.getItem(
        BROWSER_STORAGE_KEYS.sessionUtmParams
      )
      if (!storedParams) return null

      try {
        const params = JSON.parse(storedParams)
        return params[key] || null
      } catch (e) {
        return null
      }
    }

    const params = new URLSearchParams(window.location.search)

    const keys = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_term",
      "utm_content",
    ]

    const utmParams = {}

    keys.forEach(key => {
      const value =
        params.get(key) || (useCache ? getUTMParamValueFromStorage(key) : null)
      if (value) utmParams[key] = value
    })

    return utmParams
  },
}

type UTMParams = {
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
  utm_term?: string
  utm_content?: string
}

/**
 *
 * @param params Prefer writing object keys in snake case. If you don't then they'll automatically be converted to snake case.
 */
export const trackElementOnPH = (params: Record<string, any>) => {
  const formattedParams: Record<string, any> = {
    "data-ph-autocapture": true,
  }
  Object.entries(params).forEach(([key, value]) => {
    formattedParams[`data-ph-capture-attribute-${snakeCase(key)}`] = value
  })

  return formattedParams
}
