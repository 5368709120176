const ReferenceIcon = ({ color }) => (
  <svg
    width="12"
    height="10"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.985535 4.75669C0.985535 1.8823 2.44689 0.445099 5.36959 0.445099H5.91307V2.51032H5.36959C4.01694 2.51032 3.34061 3.18665 3.34061 4.5393V5.98858L5.9493 5.91611V10.4451H0.985535V4.75669ZM8.05075 4.75669C8.05075 1.8823 9.51211 0.445099 12.4348 0.445099H12.9783V2.51032H12.4348C11.058 2.51032 10.3696 3.18665 10.3696 4.5393V5.98858L13.0145 5.91611V10.4451H8.05075V4.75669Z"
      fill={color}
    />
  </svg>
)

export default ReferenceIcon
