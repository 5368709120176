import { ErrorMessage, InputLabel, TextField } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { useEffect, useState } from "react"

export enum AddressTypeEnum {
  COUNTRY = "country",
  STATE = "administrative_area_level_1",
  DISTRICT = "administrative_area_level_3",
  CITY = "locality",
  AREA = "sublocality_level_1",
  POLITICAL = "political",
  POSTALCODE = "postal_code",
}

export type AddressComponents = {
  longText: string
  shortText: string
  types: AddressTypeEnum[]
}[]

export type GoogleLocationSelectValue = {
  fullWidth?: boolean
  className?: string
  placeHolder?: string
  onChange?: (addressComponents: AddressComponents) => void
  label?: string
  required?: boolean
  error?: boolean
  helperText?: string
  value?: {
    city?: string | null
    country?: string | null
    state?: string | null
  }
}

const hasValue = (value: GoogleLocationSelectValue["value"]) =>
  value?.city || value?.state || value?.country

const GoogleLocationSelect = ({
  value,
  className,
  onChange,
  placeHolder,
  fullWidth,
  label,
  required,
  error,
  helperText,
}: GoogleLocationSelectValue) => {
  const [showPicker, setShowPicker] = useState(!hasValue(value))

  const handlePlaceChange = (e: any) => {
    if (onChange) onChange(e.target.value?.addressComponents || [])
  }

  const countries: string[] = []

  useEffect(() => {
    const picker = document.getElementById("place-picker")

    if (picker) {
      picker.addEventListener("gmpx-placechange", e => {
        console.log(e)
        handlePlaceChange(e)
      })
    }
  }, [])

  return (
    <div className={clsx("flex flex-col px-px", className)}>
      <InputLabel label={label} required={required} />

      {!showPicker && value && (
        <TextField
          readOnly
          onClick={() => {
            setShowPicker(true)
          }}
          className="[&_div]:h-[46px]"
          value={[value.city, value.state, value.country]
            .filter(Boolean)
            .join(", ")}
        />
      )}

      {/* https://github.com/googlemaps/extended-component-library/blob/HEAD/src/place_picker/README.md */}
      {/* @ts-expect-error We don't have types for this because this is a web component */}
      <gmpx-place-picker
        id="place-picker"
        class={clsx("w-full", {
          "max-w-[298px]": !fullWidth,
          hidden: !showPicker,
          "[&_input]:!bg-critical-300 !border-critical-500 [&_input]:!border-critical-500":
            error,
        })}
        style={{
          borderRadius: 8,
        }}
        country={countries}
        placeholder={placeHolder}
      />
      {error && <ErrorMessage title={helperText} className="mt-1" />}
    </div>
  )
}

export default GoogleLocationSelect
