import React from "react"

import style from "./LineSpinner.module.css"

const LineSpinner = ({
  size = "40px",
  color = "black",
  speed = "1s",
  stroke = "3px",
}: {
  size?: string
  color?: string
  speed?: string
  stroke?: string
}) => {
  return (
    <div
      className={style.container}
      style={
        {
          "--uib-size": size,
          "--uib-color": color,
          "--uib-speed": speed,
          "--uib-stroke": stroke,
        } as React.CSSProperties
      }
    >
      <div className={style.line} />
      <div className={style.line} />
      <div className={style.line} />
      <div className={style.line} />
      <div className={style.line} />
      <div className={style.line} />
      <div className={style.line} />
      <div className={style.line} />
      <div className={style.line} />
      <div className={style.line} />
      <div className={style.line} />
      <div className={style.line} />
    </div>
  )
}

export default LineSpinner
