import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getNexusURL = getServiceURL(SERVICE_PREFIX.nexus)

export const urls = validateUrls({
  profileStrength: {
    retrieve: userId =>
      userId
        ? getNexusURL(`/v1/profile-strengths/public/${userId}/`)
        : getNexusURL(`/v1/profile-strengths/`),
  },
  criteria: {
    retrieve: () => getNexusURL(`/v1/profile-strengths/levels/requirements/`),
  },
})
