import { Typography } from "@suraasa/placebo-ui"
import {
  Button,
  IconButton,
  Tag,
  Theme,
  Tooltip,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import { Email } from "api/resources/settings/types"
import VerifyEmailButton from "features/Settings/ContactDetails/VerifyEmailButton"
import { Trash } from "iconoir-react"
import React from "react"
import { useTheme } from "react-jss"

const getDeleteTooltipText = (
  isPrimary: boolean | null,
  isSignupEmail: boolean | null
) => {
  if (isPrimary) return "Primary email cannot be deleted"

  if (isSignupEmail)
    return "This email was used to create your account. It cannot be deleted."

  return ""
}

const EmailCard = ({
  email,
  onDelete,
  onSetPrimaryEmail,
}: {
  email: Email
  onDelete: (emailData: Email) => void
  onSetPrimaryEmail: (emailData: Email) => void
}) => {
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const isPrimaryEmailVerified = email.isPrimary && email.isVerified

  const canDelete = !email.isPrimary && !email.signupEmail

  return (
    <div className="flex items-center justify-between p-2">
      <div className="me-1 w-[90%]">
        <div className="flex flex-col items-start break-words sm:flex-row sm:flex-wrap sm:items-center">
          <Typography
            variant={isXs ? "strongSmallBody" : "strong"}
            className="me-2 break-all"
          >
            {email.email}
          </Typography>
          {email.isPrimary && (
            <Tag
              label={isPrimaryEmailVerified ? "Primary" : "Unverified"}
              color={isPrimaryEmailVerified ? "success" : "warning"}
              className="mt-0.5 sm:mt-0"
            />
          )}
        </div>
        {email.isPrimary &&
          (email.isVerified ? (
            <Typography variant="smallBody" className="mt-1 text-onSurface-500">
              This email will be use for account-related notifications and can
              also be used for password resets.
            </Typography>
          ) : (
            <Typography
              variant="smallBody"
              className="mt-1.5 text-onSurface-500"
            >
              Unverified email address cannot receive notifications or be used
              to reset your password.
            </Typography>
          ))}

        {!email.isPrimary && (
          <Button
            variant="text"
            nudge="left"
            className="mt-1"
            onClick={() => onSetPrimaryEmail(email)}
          >
            Set as Primary Email
          </Button>
        )}
      </div>
      <div className="self-start">
        {email.isVerified ? (
          <Tooltip
            title={getDeleteTooltipText(email.isPrimary, email.signupEmail)}
            align="center"
            disabled={canDelete}
          >
            <IconButton disabled={!canDelete} onClick={() => onDelete(email)}>
              <Trash
                height={22}
                color={
                  canDelete
                    ? theme.colors.critical[500]
                    : theme.colors.secondary[400]
                }
              />
            </IconButton>
          </Tooltip>
        ) : (
          <VerifyEmailButton />
        )}
      </div>
    </div>
  )
}

export default EmailCard
