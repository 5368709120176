import "@suraasa/placebo-ui-legacy"

import { toast } from "@suraasa/placebo-ui"
import api from "api"
import { RestrictionType } from "api/resources/learningItems/types"
import { AuthData, PhoneNumber } from "api/resources/users/types"
import { APIError, transformKeys } from "api/utils"
import { format } from "date-fns"
import { PlatformsWhichCanViewLearnerProfile } from "features/Profile/utils"
import camelCase from "lodash/camelCase"
import { FieldValues, UseFormSetError } from "react-hook-form"
import routes from "routes"

import {
  BROWSER_STORAGE_KEYS,
  DEFAULT_LANGUAGE_CODE,
  Platforms,
  USER_TYPE,
} from "./constants"
import { USER_PREFS_KEY_NAME } from "./userPreferenceManager"

export const formatDate = (
  date: string | undefined,
  formatStr = "MMMM d, yyyy"
) => (date ? format(new Date(date), formatStr) : "")

// check session and local storage support
export function isStorageSupported() {
  try {
    localStorage.setItem("__TEST_LOCAL_STORAGE", "true")
    localStorage.removeItem("__TEST_LOCAL_STORAGE")
    sessionStorage.setItem("__TEST_SESSION_STORAGE", "true")
    sessionStorage.removeItem("__TEST_SESSION_STORAGE")
  } catch (e) {
    // alerting the user to prevent further errors or App issues
    alert(
      "Your browser has cookies disabled. Make sure that your cookies are enabled. Press OK to reload"
    )
    // reload browser to prevent further error popups and check if user enabled storage
    window.location.reload()
    return false
  }
  // returns true if storages are supported
  return true
}

export function getAuthInfo(keyName: string = BROWSER_STORAGE_KEYS.auth) {
  // checking storage access before accessing token
  const authInfo = isStorageSupported()
    ? localStorage.getItem(keyName) || sessionStorage.getItem(keyName)
    : null
  if (authInfo !== null) {
    const newAuthInfoObj: AuthData = transformKeys(
      JSON.parse(authInfo),
      camelCase
    )
    return newAuthInfoObj
  }

  return authInfo
}

export function getOtherPlatformAuthInfo(
  platform: PlatformsWhichCanViewLearnerProfile
): AuthData | null {
  switch (platform) {
    case Platforms.school: {
      return getAuthInfo("schoolAuth")
    }
    case Platforms.centerAdmin: {
      return getAuthInfo("centreAdminAuth")
    }
  }
}

export function setOtherPlatformAuthInfo(
  authInfo: AuthData,
  platform: PlatformsWhichCanViewLearnerProfile
): number {
  switch (platform) {
    case Platforms.school: {
      localStorage.setItem("schoolAuth", JSON.stringify(authInfo))
      return 0
    }
    case Platforms.centerAdmin: {
      localStorage.setItem("centreAdminAuth", JSON.stringify(authInfo))
      return 0
    }
  }
}

export function getUserLanguage() {
  const { language } = BROWSER_STORAGE_KEYS
  // checking storage access before accessing default language
  return isStorageSupported()
    ? localStorage.getItem(language) || DEFAULT_LANGUAGE_CODE
    : DEFAULT_LANGUAGE_CODE
}

export function saveAuthInfo(authInfo: AuthData) {
  const { auth } = BROWSER_STORAGE_KEYS

  const oldAuthToken = getAuthInfo()?.accessToken
  const newAuthToken = authInfo.accessToken
  // checking storage access before saving token
  if (isStorageSupported()) {
    localStorage.setItem(
      auth,
      JSON.stringify({
        ...authInfo,
        expiresAt:
          oldAuthToken !== newAuthToken
            ? // ? moment(Date.now())
              //     // Reduce expiry time by 10 minutes so we can refresh prematurely
              //     .add(authInfo.expiresIn - 10 * 60, "seconds")
              //     .toISOString()
              new Date().toISOString()
            : authInfo.expiresAt,
      })
    )
  }
}

export function clearAuthInfo() {
  const AllKeys = BROWSER_STORAGE_KEYS
  const keysToNotRemove = [AllKeys.language, USER_PREFS_KEY_NAME]

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.entries(AllKeys).forEach(([_, value]) => {
    if (!keysToNotRemove.includes(value)) {
      localStorage.removeItem(value)
    }
  })

  sessionStorage.clear()
}

export function clearOtherPlatformAuthInfo(
  platform: Platforms.centerAdmin | Platforms.school
): number {
  switch (platform) {
    case Platforms.school: {
      localStorage.removeItem("schoolAuth")
      return 0
    }
    case Platforms.centerAdmin: {
      localStorage.removeItem("centreAdminAuth")
      return 0
    }
  }
}

export function isUUIDString(str: string) {
  const UUIDv4Regex =
    /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
  return UUIDv4Regex.test(str)
}

export function pluralize(
  word: string,
  count: number,
  {
    endsWithVowel,
    skipCount,
    plural,
  }: {
    endsWithVowel?: boolean
    skipCount?: boolean
    plural?: string
  } = {
    endsWithVowel: false,
    skipCount: false,
    plural: "",
  }
) {
  let str = `${count} `

  if (skipCount) {
    str = ""
  }

  if (plural) {
    return `${count !== 1 ? `${str}${plural}` : `${str}${word}`}`
  }

  return `${
    count !== 1 ? `${str}${word}${endsWithVowel ? "es" : "s"}` : `${str}${word}`
  }`
}

export const buildUserName = (user: {
  firstName: string
  lastName: string | null
}) => {
  return [user.firstName, user.lastName].filter(Boolean).join(" ")
}

export const generateObjectURL = (file: File) => {
  const URL = window.URL || window.webkitURL
  return URL.createObjectURL(file)
}

/**
 * @param setter setter to set errors in the form
 * @param fieldErrors errors from the API
 * @param fields Format: [BackendKey, FrontendKey] OR [key] (if both key names are same)
 */
export const mapErrors = <T extends FieldValues>(
  setter: UseFormSetError<T>,
  err: any,
  fields: ([string, string] | [string])[]
) => {
  if (!narrowError(err)) {
    throw new Error(
      "Error supplied to mapErrors is not an instance of APIError. Throwing original error",
      err
    )
  }

  const {
    errors: { fieldErrors, message },
  } = err

  if (message) {
    toast.error(message)
  }

  fields.forEach(pair => {
    if (!fieldErrors) return

    const key = pair[0]
    const message = Array.isArray(fieldErrors[key])
      ? fieldErrors[key].join("; ")
      : fieldErrors[key]

    if (pair.length === 1) {
      if (message) {
        setter(
          key as any,
          {
            message: message as string,
          },
          { shouldFocus: true }
        )
      }
    }
    if (pair.length === 2) {
      const frontendKey = pair[1]
      if (message) {
        setter(
          frontendKey as any,
          { message: message as string },
          { shouldFocus: true }
        )
      }
    }
  })
}

/**
 * @deprecated Use `mapErrors` instead
 */
export const handleErrors = <T extends FieldValues>(
  errors: APIError,
  options?: {
    setter?: UseFormSetError<T>
  }
) => {
  const {
    errors: { message, fieldErrors },
  } = errors
  if (message) {
    toast.error(message)
  }

  if (options?.setter && fieldErrors) {
    for (const [k, v] of Object.entries(fieldErrors)) {
      if (v)
        options.setter(k as any, { message: typeof v === "string" ? v : v[0] })
    }
    return
  }

  // if there was no message, but there were fieldErrors without a setter, we need to show them to the user
  if (!message && fieldErrors && !options?.setter) {
    const firstError = Object.entries(fieldErrors)[0]
    if (firstError) {
      const [k, v] = firstError
      toast.error(`Error on key '${k}'`, {
        description: typeof v === "string" ? v : JSON.stringify(v[0]),
      })
    }
  }
}

export const saveBlobAsFile = ({
  data,
  type,
  name,
}: {
  data: any
  type: string
  name: string
}) => {
  const blob = new Blob([data], { type })
  const blobData = window.URL.createObjectURL(blob)
  downloadFile(blobData, name)
}

export const downloadFile = (href: string, name: string) => {
  const link = document.createElement("a")
  link.href = href
  link.download = name.replace(/[^a-zA-Z0-9-_.]/g, "")
  link.target = "_blank"
  link.click()
  setTimeout(() => {
    window.URL.revokeObjectURL(href)
  }, 100)
}

export const openFile = (href: string) => {
  const link = document.createElement("a")
  link.href = href
  link.target = "_blank"
  link.click()
  setTimeout(() => {
    window.URL.revokeObjectURL(href)
  }, 100)
}

export function convertHexToRGBA(hex: string, opacity: number) {
  const tempHex: string = hex.replace("#", "")

  const r = parseInt(tempHex.substring(0, 2), 16)
  const g = parseInt(tempHex.substring(2, 4), 16)
  const b = parseInt(tempHex.substring(4, 6), 16)

  return `rgba(${r},${g},${b},${opacity / 100})`
}

export function narrowError(e: unknown): e is APIError {
  return e instanceof APIError
}

export const getPlatformURL = (
  platform: "jobs" | "suraasa" | "sso" | "hire",
  url: string
) => {
  let domain

  if (platform === "hire") {
    domain = import.meta.env.VITE_HIRE_PLATFORM_URL
  }
  if (platform === "jobs") {
    domain = import.meta.env.VITE_JOBS_PLATFORM_URL
  }
  if (platform === "suraasa") {
    domain = import.meta.env.VITE_SURAASA_PLATFORM_URL
  }
  if (platform === "sso") {
    domain = import.meta.env.VITE_SSO_URL
  }

  return `${domain}${url}`
}

export const MBToBytes = (mb: number) => mb * 1000000

export const generateAuthCode = async () => {
  try {
    const res = await api.users.generateAuthCode({
      data: { platform: USER_TYPE },
    })
    return res.code
  } catch (e) {
    return null
  }
}

export const getLearningItemURL = ({
  itemSlug,
  parentSlug,
  parentType,
  route,
  queryParams,
}: {
  itemSlug: string
  parentSlug?: string
  parentType?: string
  route: string
  queryParams?: Record<string, string>
}) => {
  let url = route.replace(":slug", itemSlug)
  if (parentSlug) {
    url = url.replace(":parentSlug", parentSlug)
  }
  if (parentType) {
    url = url.replace(":learningItemType", parentType)
  }

  if (queryParams) {
    const params = new URLSearchParams(queryParams)
    url = `${url}?${params.toString()}`
  }

  return url
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const getITOShareReferralText = (
  link: string,
  options?: {
    discount?: boolean
    encode?: boolean
  }
) => {
  const text = `Hey! 😊

I'll be participating in the International Teachers' Olympiad 2023.
It's a fantastic opportunity for us teachers to get feedback on our teaching skills and get recognition for it!!

I found all the details in this video here: https://suraasa.co/ito-details

If you like it too, ${
    options?.discount
      ? "you can use my referral link for ₹50 discount"
      : "you can use this link to register"
  }.
${link}`

  if (options?.encode) return encodeURIComponent(text)
  return text
}

export const getITOCertificateShareText = (type: string, url: string) => {
  if (type === "international-teacher-olympiad-2023-participation") {
    return `Glad to have attempted the International Teachers’ Olympiad 2023 by Suraasa. It was a truly enriching experience to test my pedagogy skills!😊

I look forward to keep growing and learning more!

Here is a memoir of my participation: ${url}

#TrueTeachingPotential #Suraasa`
  }

  let opener
  switch (type) {
    case "international-teacher-olympiad-2023-participation":
      opener =
        "Super happy to share that I am one of the highly motivated teachers globally."
      break
    case "international-teacher-olympiad-2023-excellence":
      opener =
        "Super happy to share that I stand among the top 100 teachers globally."
      break
    case "international-teacher-olympiad-2023-merit-30-percentile":
      opener =
        "Super happy to share that I stand among the top 30% teachers globally."
      break
    case "international-teacher-olympiad-2023-merit-10-percentile":
      opener =
        "Super happy to share that I stand among the top 10% teachers globally."
      break
    case "international-teacher-olympiad-2023-merit-1-percentile":
      opener =
        "Super happy to share that I stand among the top 1% teachers globally."
      break
    default:
      break
  }

  return `${opener} Special thanks to the International Teachers’ Olympiad by Suraasa for giving all teachers such a wonderful opportunity of assessing their skills and getting celebrated for it. I am thrilled to proceed to the next level of growth in my teaching career and discover my #TrueTeachingPotential!\nCheck out my certificate: ${url}`
}

export function scrollToElementAdjusted(selector: string, offset: number) {
  const element = document.querySelector(selector)
  if (!element) return

  const elementPosition = element.getBoundingClientRect().top
  const offsetPosition = elementPosition + window.pageYOffset - offset

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  })
}

/**
 * @param inputString "%%Bravo%%!! ";
 * @returns ["%%Bravo%%", "!!"]
 */
export function splitStringWithPattern(inputString: string) {
  const regex = /(%%[^%]+%%)\s*/
  return inputString.split(regex).filter(Boolean)
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes"

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const capitalizeFirstLetter = (str?: string) => {
  if (typeof str !== "string" || !str) return ""
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const humaniseBytes = (bytes: number) => {
  const megabytes = bytes / (1024 * 1024)
  const formattedMB =
    megabytes % 1 === 0 ? megabytes.toFixed(0) : megabytes.toFixed(1)

  if (megabytes < 1) {
    return `${(megabytes * 1024).toFixed(0)}KB`
  }

  return `${formattedMB}MB`
}

export const redirectToLogin = () => {
  console.log("> Redirecting to login")
  const url = new URL(getPlatformURL("sso", "/"))
  url.searchParams.append("platform", USER_TYPE)
  url.searchParams.append("redirect-url", window.location.href)
  window.location.href = url.toString()
}

export const redirectToLogout = () => {
  console.log("> Redirecting to logout")
  const url = new URL(getPlatformURL("sso", "/logout"))
  url.searchParams.append("platform", USER_TYPE)
  url.searchParams.append("redirect-url", window.location.href)
  window.location.href = url.toString()
}

export const generateModuleAccessRequestLink = ({
  modules,
  type,
}: {
  modules: string[]
  type: RestrictionType
}) => {
  const moduleNameDisplay = (() => {
    if (modules.length === 1) return `The affected module is '${modules[0]}'.`

    return `The affected modules are ${modules.map(x => `'${x}'`).join(", ")}.`
  })()

  const { body, subject } = (() => {
    switch (type) {
      case RestrictionType.PAUSED: {
        const subject = encodeURIComponent("Issue with Paused Module Access")
        const body = encodeURIComponent(`Hello Suraasa Team,

I noticed that my access to ${pluralize(
          "module",
          modules.length
        )} has been paused.
${moduleNameDisplay}

Could you please help me understand the reason for this and how to restore access?
Thank you for your support!`)

        return { body, subject }
      }
      case RestrictionType.REVOKED: {
        const subject = encodeURIComponent("Issue with Revoked Module Access")
        const body = encodeURIComponent(`Hello Suraasa Team,

I noticed that my access to ${pluralize(
          "module",
          modules.length
        )} has been revoked.
${moduleNameDisplay}

Could you please help me understand the reason for this and how to restore access?
Thank you for your support!`)

        return { body, subject }
      }
      case RestrictionType.EXPIRED: {
        const subject = encodeURIComponent(
          "Request to Extend Module Access Expiry Date"
        )
        const body = encodeURIComponent(`Hello Suraasa Team,

I am reaching out to request an extension of the expiry date for ${pluralize(
          "module",
          modules.length
        )}.
${moduleNameDisplay}

Could you please assist me in extending my access to this module?
Thank you for your support!`)

        return { body, subject }
      }
    }
  })()

  return `mailto:care@suraasa.com?body=${body}&subject=${subject}`
}

export const buildPhoneNumber = ({
  countryCode,
  code,
  number,
}: {
  countryCode?: number | string | null
  code?: number | string | null
  number?: string | null
}) => {
  if (!number) return ""
  countryCode = code || countryCode
  if (!countryCode || !number) {
    return ""
  }

  let prefix = "+"
  if (countryCode.toString().includes("+")) {
    prefix = ""
  }
  return `${prefix}${countryCode}${number}`
}
