import { toast, Typography } from "@suraasa/placebo-ui"
import api from "api"
import SelectableCard from "features/SignupOnboarding/Components/SelectableCard"
import { getNavigationPortal } from "features/SignupOnboarding/Navigation/utils"
import { FormPageProps } from "features/SignupOnboarding/types"
import { useState } from "react"

const options = [
  { label: "Yes", value: true },
  { label: "No", value: false },
]

const ExperienceInTeaching = ({
  data,
  updateData,
  onNext,
  ...props
}: FormPageProps) => {
  const [hasExperienceInTeaching, setHasExperienceInTeaching] = useState<
    boolean | null
  >(data.hasExperienceInTeaching ?? null)

  const handleSelection = (value: boolean) => {
    setHasExperienceInTeaching(value)
    updateData({ hasExperienceInTeaching: value })
  }

  const updateBackend = async (value: boolean) => {
    try {
      await api.profile.updateMiscData({
        data: {
          has_experience_in_teaching: value,
        },
      })
    } catch (e) {
      console.error("Failed to update backend:", e)
    }
  }

  const handleSubmit = async () => {
    if (hasExperienceInTeaching === null) {
      toast.error("Please select an option")
      return
    }
    await updateBackend(hasExperienceInTeaching)
    await onNext()
  }

  const isDisabled = hasExperienceInTeaching === null

  return (
    <div className="flex flex-col items-start">
      <Typography variant="title2" color="onSurface.900">
        Do you have experience in teaching?
      </Typography>
      <div className="mt-4 flex w-full max-w-[480px] flex-col gap-1">
        {options.map(option => (
          <SelectableCard
            key={option.label}
            selected={hasExperienceInTeaching === option.value}
            type="radio"
            onClick={() => handleSelection(option.value)}
          >
            <Typography variant="subtitle2">{option.label}</Typography>
          </SelectableCard>
        ))}
      </div>
      {getNavigationPortal({
        disableNext: isDisabled,
        onNext: handleSubmit,
        ...props,
      })}
    </div>
  )
}

export default ExperienceInTeaching
