import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
  IconButton,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { APIError } from "api/utils"
import clsx from "clsx"
import { ArrowSeparateVertical, Plus } from "iconoir-react"
import { useContext, useEffect, useState } from "react"
import { handleErrors } from "utils/helpers"

import RemoveDialogShad from "@/common/RemoveDialogShad"

import ProfileContext from "../../context"
import DraggableItem from "../ReOrderingFeatureOnboarding/DraggableItem"
import useDraggable from "../ReOrderingFeatureOnboarding/DraggableItem/useDraggable"
import AddDialog from "./AddDialog"

const SkillsDialog = ({
  open,
  handleDialogClose,
}: { handleDialogClose: () => void } & Pick<DialogProps, "open">) => {
  const {
    skills: { data, refetch },
  } = useContext(ProfileContext)

  const hasNoItems = data.length === 0
  const [changePriority, setChangePriority] = useState(false)

  const skillList = useDraggable({ data })

  const [openAddSkillDialog, setOpenAddSkill] = useState(hasNoItems)
  const [skillToDelete, setSkillToDelete] = useState<string | null>(null)

  const [removeDialog, setRemoveDialog] = useState(false)

  const handleRemove = async () => {
    if (!skillToDelete) return

    try {
      await api.profile.skills.delete({
        urlParams: { id: skillToDelete },
      })
      refetch()
      setSkillToDelete(null)
    } catch (e) {
      toast.error("Unable to process your request")
      console.error(e)
    }
    setRemoveDialog(false)
  }

  const { mutate: handleUpdateSequence, isLoading: loadingSequence } =
    useMutation({
      mutationFn: (data: { id: number; sequence: number }[]) =>
        api.profile.skills.updateSequence({
          data: { skills: data },
        }),
      onSuccess: () => {
        setChangePriority(false)
        // const newData = skillList.processData(false)
        refetch()
      },
      onError: err => {
        if (err instanceof APIError) {
          handleErrors(err)
        }
      },
    })

  useEffect(() => {
    if (open) setOpenAddSkill(hasNoItems)

    if (!open) {
      setOpenAddSkill(false)
      setChangePriority(false)
      skillList.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, hasNoItems])

  return (
    <>
      <RemoveDialogShad
        title="Remove Skill"
        onRemove={handleRemove}
        isOpen={removeDialog}
        setIsOpen={setRemoveDialog}
      >
        <Typography variant="smallBody">
          Are you sure you want to remove{" "}
          <b>{data.find(item => item.id === skillToDelete)?.skillName}</b> from
          your profile?
        </Typography>
      </RemoveDialogShad>
      <Dialog open={open} onOpenChange={handleDialogClose}>
        {openAddSkillDialog ? (
          <AddDialog
            handleBack={hasNoItems ? undefined : () => setOpenAddSkill(false)}
            open={openAddSkillDialog}
            onAdd={() => refetch()}
            onRequestClose={() => handleDialogClose()}
          />
        ) : (
          <DialogContent>
            <DialogHeader>
              <DialogTitle className="text-strong">Skills</DialogTitle>
            </DialogHeader>
            <DialogBody className="p-0">
              <div className="flex items-center justify-between border-b border-b-onSurface-200 p-2">
                <Button
                  startAdornment={<Plus className="!size-2.5" />}
                  variant="text"
                  onClick={() => setOpenAddSkill(true)}
                >
                  Add New Skill
                </Button>
                <IconButton
                  variant={changePriority ? "filled" : "plain"}
                  size="xs"
                  className="!grid sm:!hidden [&>svg]:size-2.5"
                  onClick={() => setChangePriority(true)}
                >
                  <ArrowSeparateVertical />
                </IconButton>

                <Button
                  startAdornment={
                    <ArrowSeparateVertical className="!size-2.5" />
                  }
                  variant="text"
                  className="!hidden sm:!flex"
                  onClick={() => setChangePriority(true)}
                >
                  Change Priority
                </Button>
              </div>
              <div className="px-2">
                {skillList.data.length > 0 &&
                  skillList.data.map((item, i) => (
                    <DraggableItem
                      showTags={false}
                      onDelete={() => {
                        setSkillToDelete(item.id)
                        setRemoveDialog(true)
                      }}
                      className={clsx(
                        "[&>div]:mx-1.5 [&>div]:py-1.5 sm:[&>div]:mx-1",
                        {
                          "[&>div]:border-b [&>div]:border-onSurface-200":
                            skillList.data.length - 1 !== i,
                        }
                      )}
                      isDraggable={changePriority}
                      key={item.id}
                      index={i}
                      onDragStart={skillList.onDragStart}
                      onDrop={skillList.onDrop}
                      onDragOver={skillList.onDragOver}
                    >
                      <Typography
                        className="mr-2 text-onSurface-800"
                        variant="strong"
                      >
                        {item.skillName}
                      </Typography>
                    </DraggableItem>
                  ))}
              </div>
            </DialogBody>
            <DialogFooter>
              {changePriority && (
                <div className="flex justify-end">
                  <Button
                    variant="outlined"
                    color="critical"
                    size="sm"
                    onClick={() => {
                      skillList.reset()
                      setChangePriority(false)
                    }}
                    className="mr-1"
                  >
                    Discard
                  </Button>
                  <Button
                    loading={loadingSequence}
                    onClick={() => {
                      const newData = skillList.processData(true)
                      handleUpdateSequence(newData)
                    }}
                    size="sm"
                  >
                    Save
                  </Button>
                </div>
              )}
              {!changePriority && (
                <Button
                  onClick={handleDialogClose}
                  className="ml-auto !block border-onSurface-500 text-onSurface-500"
                  size="sm"
                  variant="outlined"
                >
                  Close
                </Button>
              )}
            </DialogFooter>
          </DialogContent>
        )}
      </Dialog>
    </>
  )
}

export default SkillsDialog
