import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui"
import { Divider } from "@suraasa/placebo-ui-legacy"
import { Certification, Qualification } from "api/resources/profile/types"
import { PROFILE_ONBOARDING_TARGETS } from "features/Profile/ProfileOnboarding/constants"
import { ProfileQuickAccessTabs } from "features/Profile/utils"
import { Plus } from "iconoir-react"
import { useContext, useEffect, useState } from "react"

import ProfileContext from "../../context"
import Section from "../Section"
import SectionContent from "../Section/SectionContent"
import SectionHeading from "../Section/SectionHeading"
import SectionTitle from "../Section/SectionTitle"
import Certifications from "./Certifications"
import CertificationsDialog from "./Certifications/EditDialog"
import Qualifications from "./Qualifications"
import QualificationsDialog from "./Qualifications/EditDialog"

const Academics = () => {
  const {
    academics: { qualifications, certifications },
    isPublic,
  } = useContext(ProfileContext)
  const [qualificationsDialogOpen, toggleQualificationsDialog] = useState(false)

  const [certificationsDialogOpen, toggleCertificationsDialog] = useState(false)

  const [qualificationToEdit, setQualificationToEdit] = useState<
    Qualification["id"] | null
  >(null)
  const [certificationToEdit, setCertificationToEdit] = useState<
    Certification["id"] | null
  >(null)

  const handleEdit = (id: number, type: "qualification" | "certification") => {
    if (type === "qualification") {
      toggleQualificationsDialog(true)
      setQualificationToEdit(id)
    }

    if (type === "certification") {
      toggleCertificationsDialog(true)
      setCertificationToEdit(id)
    }
  }

  useEffect(() => {
    // Clear qualificationToEdit when dialog is closed
    if (qualificationToEdit && !qualificationsDialogOpen) {
      setQualificationToEdit(null)
    }
    // Clear certificationToEdit when dialog is closed
    else if (certificationToEdit && !certificationsDialogOpen) {
      setCertificationToEdit(null)
    }
  }, [
    qualificationToEdit,
    qualificationsDialogOpen,
    certificationToEdit,
    certificationsDialogOpen,
  ])

  return (
    <Section
      className={PROFILE_ONBOARDING_TARGETS.academics}
      id={ProfileQuickAccessTabs.otherDetails}
    >
      <SectionHeading
        heading={
          <SectionTitle
            actionIcon={
              isPublic ? undefined : (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <IconButton>
                      <Plus className="!size-3" />
                    </IconButton>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuGroup>
                      <DropdownMenuItem
                        onClick={() => toggleQualificationsDialog(true)}
                      >
                        Qualification
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => toggleCertificationsDialog(true)}
                      >
                        Certification
                      </DropdownMenuItem>
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              )
            }
            title="Academics"
          />
        }
        xPadding={2}
      />
      <SectionContent xPadding={2}>
        <QualificationsDialog
          id={qualificationToEdit}
          open={qualificationsDialogOpen}
          setOpen={toggleQualificationsDialog}
        />

        {qualifications.data.length !== 0 && (
          <div className="pt-1">
            <Typography variant="subtitle2">Qualifications</Typography>

            {qualifications.data.map((item, index) => (
              <>
                <Qualifications
                  key={index}
                  qualification={item}
                  onEdit={id => handleEdit(id, "qualification")}
                />
                {index < qualifications.data.length - 1 && (
                  <Divider color="onSurface.200" weight="light" />
                )}
              </>
            ))}
          </div>
        )}

        <CertificationsDialog
          id={certificationToEdit}
          open={certificationsDialogOpen}
          setOpen={() => toggleCertificationsDialog(false)}
        />

        {certifications.data.length !== 0 && (
          <div className="pt-1">
            <Typography className="mt-1" variant="subtitle2">
              Certifications
            </Typography>
            <Certifications onEdit={id => handleEdit(id, "certification")} />
          </div>
        )}

        {qualifications.data.length === 0 &&
          certifications.data.length === 0 && (
            <Typography className="text-onSurface-400" variant="smallBody">
              {isPublic
                ? "Looks like there's nothing here."
                : "You haven’t added any Academic Details. Click on + icon to add one."}
            </Typography>
          )}
      </SectionContent>
    </Section>
  )
}

export default Academics
