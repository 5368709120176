import {
  Button,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
  TextField,
} from "@suraasa/placebo-ui"
import api from "api"
import { Interest } from "api/resources/profile/types"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"

type Props = {
  onAdd: (data: Interest) => void
  handleBack?: () => void
}

const AddDialog = ({
  handleBack,
  onAdd,
  onRequestClose,
}: Props & { onRequestClose: () => void } & Pick<DialogProps, "open">) => {
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<Interest>()

  useEffect(() => {
    reset()
  }, [reset])

  const onSubmit = handleSubmit(async ({ interest }) => {
    const apiData = {
      interest,
    }

    const res = await api.profile.interests.create({ data: apiData })
    if (res.isSuccessful) {
      onAdd(res.data)
      if (handleBack) handleBack()
    } else {
      handleErrors(res, { setter: setError })
    }
  })

  return (
    <>
      <DialogContent>
        <DialogHeader>
          <DialogTitle onBack={handleBack} className="text-strong">
            Add New Interest & Hobby
          </DialogTitle>
        </DialogHeader>
        <DialogBody>
          <form onSubmit={onSubmit}>
            <div>
              <TextField
                errors={errors.interest?.message}
                label="Interest & Hobby"
                placeholder="Ex: Reading"
                {...register("interest", {
                  required: { value: true, message: "Required" },
                })}
              />
            </div>
          </form>
        </DialogBody>
        <DialogFooter>
          <Button
            variant={"outlined"}
            size="sm"
            className="border-onSurface-500 text-onSurface-500"
            onClick={onRequestClose}
          >
            Close
          </Button>
          <Button
            onClick={onSubmit}
            size="sm"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </>
  )
}

export default AddDialog
