import { Button } from "@suraasa/placebo-ui"
import AsyncBuilder from "components/AsyncBuilder"
import { NavArrowLeft, NavArrowRight } from "iconoir-react"

export type NavigationProps = {
  onPrevious: () => Promise<void>
  onNext: () => Promise<void>
  onSkip?: () => Promise<void>
  disableNext: boolean
  isLastPage: boolean
  isFirstPage: boolean
}

const Navigation = ({
  onPrevious,
  isLastPage,
  isFirstPage,
  onNext,
  onSkip,
  disableNext,
}: NavigationProps) => {
  const isXs = window.innerWidth < 660

  return (
    <div className="flex items-center py-1.5 sm:py-2">
      {!isFirstPage && (
        <AsyncBuilder
          handler={onPrevious}
          render={({ onClick, loading }) => (
            <Button
              loading={loading}
              onClick={onClick}
              variant="text"
              startAdornment={<NavArrowLeft />}
              label="Back"
              className="-ms-1"
              color="black"
            />
          )}
        />
      )}
      <div className="grow" />
      {onSkip && (
        <AsyncBuilder
          handler={onSkip}
          render={({ onClick, loading }) => (
            <Button
              loading={loading}
              onClick={onClick}
              variant="text"
              label="Skip For Now"
              className="text-black"
            />
          )}
        />
      )}
      <div className="w-4" />
      <AsyncBuilder
        handler={onNext}
        render={({ onClick, loading }) => (
          <Button
            disabled={disableNext}
            size={isXs ? "sm" : undefined}
            onClick={onClick}
            loading={loading}
            variant="filled"
            endAdornment={isLastPage ? null : <NavArrowRight />}
            label={isLastPage ? "Finish" : "Next"}
            color="black"
            className="text-white"
          />
        )}
      />
    </div>
  )
}

export default Navigation
