import {
  Button,
  Checkbox,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Typography,
} from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import axios from "axios"
import { useResumeProfileFiller } from "features/AItools/components/Generator/ResumeProfileFiller/Provider"
import ProfileContext from "features/Profile/context"
import { useContext, useState } from "react"
import { toast } from "react-toastify"
import { trackingService } from "utils/tracking"

import DragNDropPDF from "./DragNDropPDF"

const CancelToken = axios.CancelToken
let cancelAxiosRequest: () => void

const AddResume = ({
  open,
  onClose,
  source,
}: {
  open?: boolean
  onClose: () => void
  source?: string
}) => {
  const { updateProfile } = useContext(ProfileContext)
  const [resume, setResume] = useState<File | null>(null)
  const [shouldGenerateData, setShouldGenerateData] = useState<boolean>(true)

  const [uploadPercentage, setUploadPercentage] = useState<number>()

  const handleFileUpload = (files: File) => {
    setResume(files)
  }

  const resumeFiller = useResumeProfileFiller()

  const { mutate, isLoading } = useMutation({
    mutationFn: async (file: File) => {
      const uploadFile = new FormData()
      uploadFile.append("resume", file)

      const options = {
        headers: {
          "Content-Type": "multipart/form-data",
        },

        onUploadProgress: (progressEvent: ProgressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          if (percent <= 100 && percent > 0) {
            setUploadPercentage(percent)
          }
        },
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancelAxiosRequest = c
        }),
      }

      return api.profile.update({
        data: uploadFile,
        ...options,
      })
    },
    onSuccess: res => {
      if (res.isSuccessful) {
        trackingService.trackEvent("profile_resume_uploaded", {
          source,
        })

        updateProfile()
        clearInput()

        if (shouldGenerateData) {
          resumeFiller.create.mutate()
        }

        onClose()
      } else {
        cancelAxiosRequest()
        setUploadPercentage(undefined)
        toast.error("Something went wrong while uploading your resume")
      }
    },
  })

  const clearInput = () => {
    const inputEl = document.getElementById(
      "resume-pdf-input"
    ) as HTMLInputElement
    if (inputEl) {
      inputEl.value = ""
    }

    setUploadPercentage(undefined)
    setResume(null)
  }

  const toggleShouldGenerateData = () => {
    setShouldGenerateData(!shouldGenerateData)
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-[408px]">
        <DialogHeader hideClose className="!border-0 pb-0">
          <DialogTitle>
            <Typography variant="title3">Upload Resume</Typography>
          </DialogTitle>
        </DialogHeader>
        <DialogBody className="px-2 pb-2">
          <div className="flex flex-col">
            <DragNDropPDF
              inputId="resume-pdf-input"
              allowedExtensions={[".pdf"]}
              instructions={["Only .pdf files. 10MB max file size."]}
              maxSize={10}
              uploadProgress={uploadPercentage}
              onChange={files => {
                if (files?.length) {
                  handleFileUpload(files[0] as File)
                }
                if (!files) {
                  clearInput()
                }
              }}
            />
            {resumeFiller.isFeatureEnabled && (
              <div className="mt-2">
                <Checkbox
                  containerClass="flex items-start gap-1 text-sm text-muted"
                  onClick={toggleShouldGenerateData}
                  checked={shouldGenerateData}
                  label="Use the information from my resume to update my profile"
                />
              </div>
            )}
          </div>
        </DialogBody>
        <DialogFooter className="!border-0">
          <Button
            size="sm"
            color="black"
            onClick={() => {
              clearInput()
              if (uploadPercentage !== undefined) {
                cancelAxiosRequest()
                setUploadPercentage(undefined)
              } else {
                onClose()
              }
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (resume) {
                mutate(resume)
              }
            }}
            loading={isLoading || resumeFiller.create.isLoading}
            disabled={!resume}
            size="sm"
          >
            Upload Resume
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default AddResume
