import { Button, Typography } from "@suraasa/placebo-ui"
import { getNavigationPortal } from "features/SignupOnboarding/Navigation/utils"
import { FormPageProps } from "features/SignupOnboarding/types"
import { getAuthInfo } from "utils/helpers"

const DirectingToMoreQuestions = ({ ...props }: FormPageProps) => {
  const auth = getAuthInfo()
  const name = auth ? auth.user.firstName : ""

  return (
    <div className="mt-20 flex w-3/4 flex-col items-start justify-center">
      {name ? (
        <Typography variant="title2">Great Going, {name}!</Typography>
      ) : (
        <Typography variant="title2">Great Going!</Typography>
      )}

      <div className="h-1"></div>

      <Typography variant="subtitle1" className="text-onSurface-600">
        Almost done! You just need to share some additional details for a truly
        personalised experience.
      </Typography>
      <div className="h-3"></div>

      <Button color="black" onClick={() => props.onNext()} size="lg">
        Okay
      </Button>

      {getNavigationPortal({
        disableNext: false,
        ...props,
      })}
    </div>
  )
}

export default DirectingToMoreQuestions
