import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { APIError } from "api/utils"
import clsx from "clsx"
import { ArrowSeparateVertical, Plus } from "iconoir-react"
import { useContext, useEffect, useState } from "react"
import { BROWSER_STORAGE_KEYS, UpdateProfileEnum } from "utils/constants"
import { handleErrors } from "utils/helpers"

import RemoveDialogShad from "@/common/RemoveDialogShad"

import ProfileContext from "../../context"
import DraggableItem from "../ReOrderingFeatureOnboarding/DraggableItem"
import useDraggable from "../ReOrderingFeatureOnboarding/DraggableItem/useDraggable"
import Instructions from "../ReOrderingFeatureOnboarding/Instructions"
import AddDialog from "./AddDialog"

const { reorderOnboarding: updateProfileDialog } = BROWSER_STORAGE_KEYS

const WorkLocationDialog = ({
  open,
  onRequestClose,
  customAction,
  source,
}: { onRequestClose: () => void } & Pick<DialogProps, "open"> & {
    customAction?: JSX.Element
    source?: string
  }) => {
  const {
    workLocationInterest: { data, refetch },
  } = useContext(ProfileContext)

  const hasNoItems = data.length === 0

  const [changePriority, setChangePriority] = useState(false)
  const [openAddWorkLocationDialog, setOpenAddWorkLocationDialog] =
    useState(false)
  const [workLocationToDelete, setWorkLocationToDelete] = useState<
    number | null
  >(null)
  const [removeDialog, setRemoveDialog] = useState(false)

  const locationList = useDraggable({ data })

  const { mutateAsync: handleRemove } = useMutation({
    mutationFn: () =>
      api.profile.locations.delete({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        urlParams: { id: workLocationToDelete! },
      }),
    onSuccess: () => {
      if (workLocationToDelete) {
        refetch()
        setWorkLocationToDelete(null)
      }
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })
  const { mutate: handleUpdateSequence, isLoading: loadingSequence } =
    useMutation({
      mutationFn: (data: { id: number; sequence: number }[]) =>
        api.profile.locations.updateSequence({ data: { workLocations: data } }),
      onSuccess: () => {
        // const newData = locationList.processData(false)
        refetch()
      },
      onError: err => {
        if (err instanceof APIError) {
          handleErrors(err)
        }
      },
    })

  useEffect(() => {
    if (open) setOpenAddWorkLocationDialog(hasNoItems)

    if (!open) {
      setOpenAddWorkLocationDialog(false)
      setChangePriority(false)
      locationList.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, hasNoItems])

  const changePriorityFnc = () => {
    setChangePriority(true)

    const updateStatus: string[] = JSON.parse(
      localStorage.getItem(updateProfileDialog) ?? "[]"
    )

    if (!updateStatus.includes(UpdateProfileEnum.LOCATIONS)) {
      updateStatus.push(UpdateProfileEnum.LOCATIONS)
    }

    localStorage.setItem(updateProfileDialog, JSON.stringify(updateStatus))
  }

  function selectedDeleteLocation() {
    return data.find(item => item.id === workLocationToDelete)
  }

  return (
    <Dialog open={open} onOpenChange={onRequestClose}>
      {openAddWorkLocationDialog || hasNoItems ? (
        <AddDialog
          open={openAddWorkLocationDialog}
          onAdd={() => refetch()}
          onBack={
            hasNoItems ? undefined : () => setOpenAddWorkLocationDialog(false)
          }
          source={source}
          onRequestClose={onRequestClose}
        />
      ) : (
        <>
          <RemoveDialogShad
            title="Remove Work Location"
            onRemove={async () => {
              await handleRemove()
            }}
            isOpen={removeDialog}
            setIsOpen={setRemoveDialog}
          >
            <Typography variant="smallBody">
              Are you sure you want to remove{" "}
              <b>
                {selectedDeleteLocation()?.state?.name},{" "}
                {selectedDeleteLocation()?.country.name}
              </b>{" "}
              from your profile?
            </Typography>
          </RemoveDialogShad>
          <DialogContent className="flex flex-col !p-0">
            <DialogHeader>
              <DialogTitle className="text-[16px] font-semibold">
                Work Locations Interests
              </DialogTitle>
            </DialogHeader>
            <DialogBody className="p-0">
              <div className="flex items-center justify-between border-b border-onSurface-200 px-1.5 py-2">
                <Button
                  startAdornment={<Plus className="!size-2.5" />}
                  variant="text"
                  onClick={() => setOpenAddWorkLocationDialog(true)}
                >
                  Add New Location
                </Button>
                <IconButton
                  onClick={changePriorityFnc}
                  variant={changePriority ? "filled" : "plain"}
                  size="xs"
                  className="!grid sm:!hidden [&>svg]:size-2.5"
                >
                  <ArrowSeparateVertical />
                </IconButton>

                <Button
                  startAdornment={
                    <ArrowSeparateVertical className="!size-2.5" />
                  }
                  variant="text"
                  className="!hidden sm:!flex"
                  onClick={changePriorityFnc}
                >
                  Change Priority
                </Button>
              </div>
              {changePriority && (
                <Instructions
                  type="locations"
                  title="Change Work Location Interests Priority"
                  description="Drag your main preferred work location to the top to make it your primary work location interest. Similarly, your second location will be marked as your secondary work location interest."
                  disclaimer="We match your profile with the right schools based on your primary and secondary work location interests."
                />
              )}
              <div>
                {locationList.data.length > 0 &&
                  locationList.data.map((item, index) => (
                    <DraggableItem
                      showTags
                      onDelete={() => {
                        setWorkLocationToDelete(item.id)
                        setRemoveDialog(true)
                      }}
                      className={clsx("[&>div]:mx-3 [&>div]:py-1.5", {
                        "[&>div]:border-b [&>div]:border-onSurface-200":
                          locationList.data.length - 1 !== index,
                      })}
                      isDraggable={changePriority}
                      index={index}
                      key={item.id}
                      onDragStart={locationList.onDragStart}
                      onDrop={locationList.onDrop}
                      onDragOver={locationList.onDragOver}
                    >
                      <div className="mr-2 flex items-center">
                        <img
                          alt=""
                          className="mr-2 h-2.5 w-3.5"
                          src={`/assets/flags/${item.country.isoCode}.svg`}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null // prevents looping
                            currentTarget.src = "/assets/flags/placeholder.png"
                          }}
                        />
                        <div>
                          {item.state && (
                            <Typography className="inline" variant="strong">
                              {item.state.name},{" "}
                            </Typography>
                          )}
                          <Typography className="inline" variant="body">
                            {item.country.name}
                          </Typography>
                        </div>
                      </div>
                    </DraggableItem>
                  ))}
              </div>
            </DialogBody>
            <DialogFooter>
              {changePriority && (
                <div className="flex justify-end">
                  <Button
                    variant="outlined"
                    color="critical"
                    size="sm"
                    onClick={() => {
                      locationList.reset()
                      setChangePriority(false)
                    }}
                    className="mr-1"
                  >
                    Discard
                  </Button>
                  <Button
                    onClick={() => {
                      const newData = locationList.processData(true)
                      handleUpdateSequence(newData)
                      setChangePriority(false)
                    }}
                    loading={loadingSequence}
                    size="sm"
                  >
                    Save
                  </Button>
                </div>
              )}
              {!changePriority && !customAction && (
                <Button
                  onClick={onRequestClose}
                  className="ml-auto !block border-muted text-muted"
                  size="sm"
                  variant="outlined"
                >
                  Close
                </Button>
              )}
              {!changePriority && customAction}
            </DialogFooter>
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}

export default WorkLocationDialog
