import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Typography,
} from "@suraasa/placebo-ui"
import { InfoCircle } from "iconoir-react"

type InstructionType = "subjects" | "locations"

type VideoType = {
  [key in InstructionType]: string
}

const web: VideoType = {
  subjects: "https://assets.suraasa.com/lms/reordering-gifs/subjects%20web.mp4",
  locations:
    "https://assets.suraasa.com/lms/reordering-gifs/countries%20web.mp4",
}
const mobile: VideoType = {
  subjects:
    "https://assets.suraasa.com/lms/reordering-gifs/subjects%20mobile.mp4",
  locations:
    "https://assets.suraasa.com/lms/reordering-gifs/countries%20mobile.mp4",
}

const Instructions = ({
  title,
  description,
  disclaimer,
  type,
}: {
  title?: string
  description?: string
  disclaimer?: string
  type: InstructionType
}) => {
  const finalTitle = title ? title : `Change Priority`
  const finalDescription = description
    ? description
    : `Drag your main preferred item to the top to make it your primary item interest. Similarly, your second location will be marked as your secondary item interest.`

  if (!open) {
    return null
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <button className="mb-1 mt-2 flex space-x-1.5 border border-highlight-200 bg-highlight-50 p-2 text-start  sm:mx-2 sm:rounded-lg">
          <InfoCircle
            width={20}
            height={20}
            className="shrink-0 text-highlight-700"
          />
          <Typography variant="smallBody">
            {disclaimer}{" "}
            <span className="text-primary-500">Click here to know how</span>
          </Typography>
        </button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{finalTitle}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <div>
            <Typography className="mb-2">{finalDescription}</Typography>
            <video
              src={web[type]}
              className="hidden sm:block"
              controls={false}
              width="100%"
              autoPlay
              disablePictureInPicture
              loop
              muted
              playsInline
            />
            <video
              src={mobile[type]}
              className="block sm:hidden"
              controls={false}
              width="100%"
              autoPlay
              disablePictureInPicture
              loop
              muted
              playsInline
            />
          </div>
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}

export default Instructions
