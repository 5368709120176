import { Typography } from "@suraasa/placebo-ui"
import { Container } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import React from "react"

const StickyTIABar = ({
  slotEnd,
  show = false,
}: {
  show?: boolean
  slotEnd: React.ReactNode | Element | JSX.Element
}) => {
  return (
    <div
      className={clsx(
        "sticky top-7 z-2 w-full bg-[linear-gradient(235.38deg,#39AFFD_4.23%,#477FFF_95.84%)] shadow-[0px_6px_15px_rgba(0,0,0,0.05)] sm:top-7"
      )}
    >
      <div
        className={clsx("duration-[0.5s] grid grid-rows-[0fr] transition-all", {
          "grid-rows-[1fr]": show,
        })}
      >
        <div className="overflow-hidden">
          <Container>
            <div className="flex flex-col items-center justify-between py-2 sm:flex-row">
              <Typography
                style={{
                  fontFamily: "Space Grotesk Bold",
                }}
                className="!text-2xl !leading-[31px] text-surface-500"
              >
                Teacher Impact Awards
              </Typography>
              {/* @ts-expect-error react version issues */}
              <>{slotEnd}</>
            </div>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default StickyTIABar
