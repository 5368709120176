import {
  Button,
  CircularProgress,
  Sheet,
  SheetBody,
  SheetContent,
  SheetHeader,
  SheetTitle,
  Typography,
} from "@suraasa/placebo-ui"
import { ResumeFillerType } from "api/resources/aiTools/types"
import { context } from "global/Context/context"
import { ArrowRight } from "iconoir-react"
import { useContext, useEffect, useState } from "react"

import AddFromResume from "./AddFromResume"
import { useResumeProfileFiller } from "./Provider"
import UpdateFromResume from "./UpdateFromResume"

export type ResumeForm = {
  firstName: ResumeFillerType["firstName"]
  lastName: ResumeFillerType["lastName"]
  gender: ResumeFillerType["gender"]
  country: ResumeFillerType["country"]
  state: ResumeFillerType["state"]
  dateOfBirth: ResumeFillerType["dateOfBirth"]
  careerAspiration: ResumeFillerType["careerAspiration"]
  qualifications: ResumeFillerType["qualifications"]
  certifications: ResumeFillerType["certifications"]
  workExperiences: ResumeFillerType["workExperiences"]
  publications: ResumeFillerType["publications"]
  awards: ResumeFillerType["awards"]
  languages: ResumeFillerType["languages"]
  projects: ResumeFillerType["projects"]
  skills: ResumeFillerType["skills"]
  subjects: ResumeFillerType["subjects"]
  workLocationInterests: ResumeFillerType["workLocationInterests"]
  lookingForJobs: ResumeFillerType["lookingForJobs"]
  nationality: ResumeFillerType["nationality"]
  testScores: ResumeFillerType["testScores"]
  phoneNumber: ResumeFillerType["phoneNumber"]
  interests: ResumeFillerType["interests"]
}

const ResumeProfileFiller = ({
  open,
  onClose,
  data,
}: {
  open: boolean
  onClose: () => void
  data: ResumeFillerType
}) => {
  const [step, setStep] = useState<null | "add" | "update">(null)

  const [hasToUpdate, setHasToUpdate] = useState(false)
  const { fetchProfile } = useContext(context)
  const resumeFiller = useResumeProfileFiller()

  const isXs = window.innerWidth < 640

  useEffect(() => {
    const checkForNullAndNonNullIds = () => {
      const fieldsToCheck = [
        data.qualifications,
        data.certifications,
        data.workExperiences,
        data.publications,
        data.awards,
        data.languages,
        data.skills,
        data.projects,
        data.subjects,
        data.workLocationInterests,
        data.interests,
        data.testScores,
      ]

      const additionalFieldsForAdd = [
        data.firstName?.firstName,
        data.lastName?.lastName,
        data.gender?.gender,
        data.country?.uuid,
        data.state?.uuid,
        data.phoneNumber,
        data.dateOfBirth?.dateOfBirth,
        data.nationality?.uuid,
        data.careerAspiration?.careerAspiration,
      ]

      const hasDataToAdd =
        fieldsToCheck.some(field => field?.some(item => item?.id === null)) ||
        additionalFieldsForAdd.some(field => field != null)

      const hasDataToUpdate = fieldsToCheck.some(field =>
        field?.some(item => item?.id !== null)
      )

      if (hasDataToAdd) {
        setStep("add")
      } else if (hasDataToUpdate) {
        setStep("update")
      }
      setHasToUpdate(hasDataToUpdate)
    }

    checkForNullAndNonNullIds()
  }, [data])

  return (
    <Sheet open={open} onOpenChange={onClose}>
      <SheetContent
        side="bottom"
        className="max-w-5xl"
        height={95}
        closeWhenInteractOutside
      >
        <SheetHeader>
          <SheetTitle>
            {step === "add" ? "Add from Resume" : "Update from Resume"}
          </SheetTitle>
        </SheetHeader>
        <SheetBody className="relative">
          {step === null && (
            <div className="absolute left-0 top-0 z-1 flex size-full items-center justify-center bg-white">
              <CircularProgress />
            </div>
          )}
          {step === "add" && (
            <AddFromResume
              data={data}
              id="resume-add"
              afterSubmit={() => {
                if (hasToUpdate) {
                  setStep("update")
                } else {
                  resumeFiller.saveChoicesAndReset
                    .mutateAsync(data)
                    .then(() => {
                      setStep(null)
                      fetchProfile()
                      onClose()
                    })
                }
              }}
            />
          )}
          {step === "update" && (
            <UpdateFromResume
              data={data}
              id="resume-update"
              afterSubmit={() => {
                resumeFiller.saveChoicesAndReset.mutateAsync(data).then(() => {
                  setStep(null)
                  fetchProfile()
                  onClose()
                })
              }}
            />
          )}

          <div className="sticky bottom-0 left-0 flex w-full items-center justify-between rounded-lg border border-solid border-surface-300 bg-white p-2 shadow-[0px_8px_25px_0px_#00000026]">
            <Typography>
              {step === "add" ? "Save these changes?" : "Add these updates?"}
            </Typography>
            <Button
              type="submit"
              form={step === "add" ? "resume-add" : "resume-update"}
              endAdornment={hasToUpdate && step === "add" && <ArrowRight />}
              size={isXs ? "sm" : "md"}
              className="sm:px-4"
            >
              {step === "add" && hasToUpdate ? "Continue" : "Save"}
            </Button>
          </div>
        </SheetBody>
      </SheetContent>
    </Sheet>
  )
}

export default ResumeProfileFiller
