import {
  Dialog,
  DialogBody,
  DialogContent,
  Typography,
} from "@suraasa/placebo-ui"
import { LinearProgress } from "@suraasa/placebo-ui-legacy"
import { ToolType } from "api/resources/aiTools/types"
import Stars from "assets/AITools/stars-gradient.json"
import { useEffect, useState } from "react"
import Lottie from "react-lottie"

import TextSlider from "./TextSlider"

const LoadingText: Record<ToolType, string[]> = {
  [ToolType.lessonPlan]: [
    "Generating Lesson Plan...📝",
    "Cross-referencing educational benchmarks... 📊",
    "Fine-tuning learning outcomes... 🎯",
    "Constructing interdisciplinary connections... 🔗",
    "Integrating interactive teaching methods... 🎮",
    "Mapping out curriculum pathways... 🗺️",
    "Adopting innovative teaching models... 🚀",
    "Configuring lesson plan frameworks... 📐",
    "Balancing cognitive challenges... ⚖️",
    "Harmonising with educational trends... 📈",
    "Tailoring assessments for proficiency... ✅",
    "Iterating through pedagogical data... 🔄",
    "Building scaffolds for knowledge retention... 🛠️",
    "Encoding adaptive learning strategies... 🧠",
    "Formatting instructional materials... 📜",
    "Evolving with student-centred approaches... 🌟",
    "Analysing educational standards... 📚",
    "Organising lesson structures... 📝",
    "Compiling teaching methodologies... 🎓",
    "Processing curriculum data... 📊",
    "Optimising learning objectives... 🎯",
    "Synthesising instructional content... 🖥️",
    "Customising lesson sequences... 🔄",
    "Generating assessment strategies... 📈",
    "Aligning with pedagogical frameworks... 🧩",
    "Loading educational resources... 📖",
    "Evaluating student needs... 🧠",
    "Adapting to diverse learning styles... 🌐",
    "Refining lesson plan algorithms... ⚙️",
    "Incorporating feedback loops... 🔄",
    "Crafting engaging activities... 🔍",
  ],
  [ToolType.lessonPlanPro]: [
    "Generating Lesson Plan...📝",
    "Cross-referencing educational benchmarks... 📊",
    "Fine-tuning learning outcomes... 🎯",
    "Constructing interdisciplinary connections... 🔗",
    "Integrating interactive teaching methods... 🎮",
    "Mapping out curriculum pathways... 🗺️",
    "Adopting innovative teaching models... 🚀",
    "Configuring lesson plan frameworks... 📐",
    "Balancing cognitive challenges... ⚖️",
    "Harmonising with educational trends... 📈",
    "Tailoring assessments for proficiency... ✅",
    "Iterating through pedagogical data... 🔄",
    "Building scaffolds for knowledge retention... 🛠️",
    "Encoding adaptive learning strategies... 🧠",
    "Formatting instructional materials... 📜",
    "Evolving with student-centred approaches... 🌟",
    "Analysing educational standards... 📚",
    "Organising lesson structures... 📝",
    "Compiling teaching methodologies... 🎓",
    "Processing curriculum data... 📊",
    "Optimising learning objectives... 🎯",
    "Synthesising instructional content... 🖥️",
    "Customising lesson sequences... 🔄",
    "Generating assessment strategies... 📈",
    "Aligning with pedagogical frameworks... 🧩",
    "Loading educational resources... 📖",
    "Evaluating student needs... 🧠",
    "Adapting to diverse learning styles... 🌐",
    "Refining lesson plan algorithms... ⚙️",
    "Incorporating feedback loops... 🔄",
    "Crafting engaging activities... 🔍",
  ],
  [ToolType.rubrics]: [
    "Generating Rubrics...📐",
    "Analysing assessment criteria... 📊",
    "Structuring grading frameworks... 📝",
    "Developing evaluation parameters... 📏",
    "Calibrating proficiency levels... 🎯",
    "Crafting detailed scoring guidelines... 📈",
    "Aligning with learning objectives... 🧩",
    "Adapting to diverse assessment methods... 🔄",
    "Customising rubric components... 📐",
    "Evaluating performance indicators... 🏆",
    "Integrating feedback mechanisms... 🔍",
    "Refining grading rubric algorithms... ⚙️",
    "Encoding criteria for holistic assessment... 🧠",
    "Fine-tuning rubric descriptors... 🔍",
    "Adopting best practices in assessment... 🌟",
    "Designing rubrics for comprehensive evaluation... 🌐",
    "Synthesising grading standards... 📚",
    "Constructing assessment benchmarks... 📊",
    "Personalising rubric categories... 🖊️",
    "Evaluating performance metrics... 📈",
    "Balancing qualitative and quantitative measures... ⚖️",
    "Adapting rubrics for various subjects... 🧪",
    "Aligning with curriculum objectives... 🎯",
    "Configuring rubric weighting... ⚙️",
    "Fine-tuning rubric descriptors... 📝",
    "Mapping proficiency levels... 🗺️",
    "Embedding assessment criteria... 🎚️",
    "Calibrating for fair evaluation... 📐",
    "Customising rubric structures... 🏗️",
    "Analysing learning outcomes for grading... 📚",
    "Incorporating diverse assessment methodologies... 🔄",
  ],
  [ToolType.rubricsPro]: [
    "Generating Rubrics...📐",
    "Analysing assessment criteria... 📊",
    "Structuring grading frameworks... 📝",
    "Developing evaluation parameters... 📏",
    "Calibrating proficiency levels... 🎯",
    "Crafting detailed scoring guidelines... 📈",
    "Aligning with learning objectives... 🧩",
    "Adapting to diverse assessment methods... 🔄",
    "Customising rubric components... 📐",
    "Evaluating performance indicators... 🏆",
    "Integrating feedback mechanisms... 🔍",
    "Refining grading rubric algorithms... ⚙️",
    "Encoding criteria for holistic assessment... 🧠",
    "Fine-tuning rubric descriptors... 🔍",
    "Adopting best practices in assessment... 🌟",
    "Designing rubrics for comprehensive evaluation... 🌐",
    "Synthesising grading standards... 📚",
    "Constructing assessment benchmarks... 📊",
    "Personalising rubric categories... 🖊️",
    "Evaluating performance metrics... 📈",
    "Balancing qualitative and quantitative measures... ⚖️",
    "Adapting rubrics for various subjects... 🧪",
    "Aligning with curriculum objectives... 🎯",
    "Configuring rubric weighting... ⚙️",
    "Fine-tuning rubric descriptors... 📝",
    "Mapping proficiency levels... 🗺️",
    "Embedding assessment criteria... 🎚️",
    "Calibrating for fair evaluation... 📐",
    "Customising rubric structures... 🏗️",
    "Analysing learning outcomes for grading... 📚",
    "Incorporating diverse assessment methodologies... 🔄",
  ],
  [ToolType.quiz]: [
    "Generating Quiz...📊",
    "Compiling question databases... 📚",
    "Crafting diverse question types... ❓",
    "Analysing knowledge domains... 🧠",
    "Generating randomised quizzes... 🔄",
    "Aligning questions with learning objectives... 🎯",
    "Configuring difficulty levels... 🔍",
    "Customising quiz structures... 📝",
    "Integrating multimedia elements... 🖼️",
    "Adapting to various question formats... 📋",
    "Balancing for fair assessment... ⚖️",
    "Implementing adaptive questioning... 🔄",
    "Encoding answer choices... 🅰️🅱️🅾️",
    "Constructing timed assessments... ⏱️",
    "Incorporating feedback mechanisms... 🌟",
    "Reviewing for accuracy and relevance... ✔️",
    "Sorting questions by cognitive levels... 🧠",
    "Personalising quiz difficulty settings... 🌟",
    "Adopting question validation protocols... ✅",
    "Building question banks for versatility... 🏦",
    "Tailoring questions for various learning styles... 🔄",
    "Implementing adaptive question sequencing... 🔄",
    "Evaluating question relevance... 🔍",
    "Embedding multimedia resources into questions... 🖼️",
    "Optimising for user engagement... 🎮",
    "Analysing question performance data... 📊",
    "Balancing question distribution... ⚖️",
    "Structuring quiz navigation... 🗺️",
    "Reviewing and refining quiz parameters... 📝",
    "Coding auto-grading mechanisms... 🖥️",
    "Generating answer explanations... 📚",
  ],
  [ToolType.quizPro]: [
    "Generating Quiz...📊",
    "Compiling question databases... 📚",
    "Crafting diverse question types... ❓",
    "Analysing knowledge domains... 🧠",
    "Generating randomised quizzes... 🔄",
    "Aligning questions with learning objectives... 🎯",
    "Configuring difficulty levels... 🔍",
    "Customising quiz structures... 📝",
    "Integrating multimedia elements... 🖼️",
    "Adapting to various question formats... 📋",
    "Balancing for fair assessment... ⚖️",
    "Implementing adaptive questioning... 🔄",
    "Encoding answer choices... 🅰️🅱️🅾️",
    "Constructing timed assessments... ⏱️",
    "Incorporating feedback mechanisms... 🌟",
    "Reviewing for accuracy and relevance... ✔️",
    "Sorting questions by cognitive levels... 🧠",
    "Personalising quiz difficulty settings... 🌟",
    "Adopting question validation protocols... ✅",
    "Building question banks for versatility... 🏦",
    "Tailoring questions for various learning styles... 🔄",
    "Implementing adaptive question sequencing... 🔄",
    "Evaluating question relevance... 🔍",
    "Embedding multimedia resources into questions... 🖼️",
    "Optimising for user engagement... 🎮",
    "Analysing question performance data... 📊",
    "Balancing question distribution... ⚖️",
    "Structuring quiz navigation... 🗺️",
    "Reviewing and refining quiz parameters... 📝",
    "Coding auto-grading mechanisms... 🖥️",
    "Generating answer explanations... 📚",
  ],
  [ToolType.handout]: [
    "Crafting captivating narratives for your lesson...📖",
    "Tuning the voice of knowledge...🎙️",
    "Weaving global tales into your plan...🌍",
    "Painting stories with words...🎨",
    "Searching for the perfect storyline...🔍",
    "Transforming textbooks into tales...📚",
    "Adding a sprinkle of storytelling magic...🌟",
    "Setting the stage for your lesson's drama...🎭",
    "Composing the melody of your narrative...🎼",
    "Engaging young minds with compelling plots...🧠",
    "Mixing science with stories...🔬",
    "Plotting the arc of your educational journey...📈",
    "Growing a garden of knowledge through narration...🌱",
    "Bookmarking the best tales for your topic...🔖",
    "Visualising scenes for immersive learning...👀",
    "Giving a voice to your lesson's characters...🗣️",
    "Illuminating concepts with narrative light...💡",
    "Directing the script of your lesson plan...🎥",
    "Crafting stories that win students' hearts...🏆",
    "Launching your lesson into a narrative adventure...🚀",
  ],
  [ToolType.narration]: [
    "Analysing your lesson plan...🔍",
    "Gathering educational content...📚",
    "Designing visually appealing handouts...🎨",
    "Fine-tuning handouts for maximum engagement...🔧",
    "Infusing handouts with interactive elements...✍️",
    "Tailoring content to your teaching style...📝",
    "Adding a touch of magic to your lesson...🌟",
    "Enhancing handouts for better learning outcomes...📈",
    "Incorporating scientific concepts...🔬",
    "Integrating arts and creativity...🎭",
    "Embedding mathematical problems...🧮",
    "Exploring global perspectives...🌍",
    "Including gamified elements...🎲",
    "Creating handouts that tell a story...📖",
    "Linking to additional resources...🔗",
    "Encouraging critical thinking...🤔",
    "Illuminating key concepts...💡",
    "Focusing on student engagement...👀",
    "Launching your lesson into new heights...🚀",
    "Preparing handouts that make learning a celebration...🎉",
  ],
  [ToolType.subjectiveAssessment]: [
    "Crafting personalised questions for deeper understanding...📝",
    "Probing minds with thought-provoking prompts...🧠",
    "Painting a canvas of subjective assessments...🎨",
    "Tailoring assessments to your lesson's objectives...🔍",
    "Analysing learning outcomes for customised questions...📊",
    "Infusing creativity into evaluation...🌟",
    "Stimulating critical thinking with unique queries...🤔",
    "Integrating key concepts into subjective assessments...📚",
    "Exploring diverse perspectives in questions...🌐",
    "Setting the stage for reflective responses...🎭",
    "Formulating assessments that spark curiosity...🔬",
    "Illuminating students' understanding through tailored questions...💡",
    "Composing assessments that resonate with learners...🎼",
    "Launching a journey of introspection and discovery...🚀",
    "Elevating the depth of understanding with nuanced assessments...📈",
    "Crafting challenges that inspire academic excellence...🏆",
    "Cultivating a garden of critical thinking skills...🌱",
    "Focusing on individual learner's perspectives...👀",
    "Aiming for the heart of your lesson's goals...🎯",
    "Connecting global themes to personal insights...🌍",
  ],
  [ToolType.subjectiveAssessmentPro]: [
    "Crafting personalised questions for deeper understanding...📝",
    "Probing minds with thought-provoking prompts...🧠",
    "Painting a canvas of subjective assessments...🎨",
    "Tailoring assessments to your lesson's objectives...🔍",
    "Analysing learning outcomes for customised questions...📊",
    "Infusing creativity into evaluation...🌟",
    "Stimulating critical thinking with unique queries...🤔",
    "Integrating key concepts into subjective assessments...📚",
    "Exploring diverse perspectives in questions...🌐",
    "Setting the stage for reflective responses...🎭",
    "Formulating assessments that spark curiosity...🔬",
    "Illuminating students' understanding through tailored questions...💡",
    "Composing assessments that resonate with learners...🎼",
    "Launching a journey of introspection and discovery...🚀",
    "Elevating the depth of understanding with nuanced assessments...📈",
    "Crafting challenges that inspire academic excellence...🏆",
    "Cultivating a garden of critical thinking skills...🌱",
    "Focusing on individual learner's perspectives...👀",
    "Aiming for the heart of your lesson's goals...🎯",
    "Connecting global themes to personal insights...🌍",
  ],
  [ToolType.assignment]: [
    "Assembling personalised assignments...📚",
    "Tailoring tasks to your curriculum...🔍",
    "Crafting puzzles for curious minds...🧩",
    "Generating thought-provoking questions...📝",
    "Infusing creativity into homework...🎨",
    "Mixing up scientific experiments...🔬",
    "Composing musical challenges...🎼",
    "Exploring global topics in assignments...🌍",
    "Setting the stage for literary analysis...🎭",
    "Calculating math problems for all levels...🧮",
    "Creating reading assignments that spark imagination...📖",
    "Developing goal-oriented tasks...🎯",
    "Illuminating new concepts through assignments...💡",
    "Designing projects for real-world application...📈",
    "Adding a touch of magic to learning activities...🌟",
    "Launching assignments that reach new heights...🚀",
    "Scripting video projects for creative expression...🎥",
    "Crafting challenges to inspire excellence...🏆",
    "Planting seeds of knowledge with each task...🌱",
    "Focusing on engaging and interactive assignments...👀",
  ],
  [ToolType.assignmentPro]: [
    "Assembling personalised assignments...📚",
    "Tailoring tasks to your curriculum...🔍",
    "Crafting puzzles for curious minds...🧩",
    "Generating thought-provoking questions...📝",
    "Infusing creativity into homework...🎨",
    "Mixing up scientific experiments...🔬",
    "Composing musical challenges...🎼",
    "Exploring global topics in assignments...🌍",
    "Setting the stage for literary analysis...🎭",
    "Calculating math problems for all levels...🧮",
    "Creating reading assignments that spark imagination...📖",
    "Developing goal-oriented tasks...🎯",
    "Illuminating new concepts through assignments...💡",
    "Designing projects for real-world application...📈",
    "Adding a touch of magic to learning activities...🌟",
    "Launching assignments that reach new heights...🚀",
    "Scripting video projects for creative expression...🎥",
    "Crafting challenges to inspire excellence...🏆",
    "Planting seeds of knowledge with each task...🌱",
    "Focusing on engaging and interactive assignments...👀",
  ],
  [ToolType.coverLetter]: [
    "Personalizing your introduction... 👋",
    "Highlighting your skills... 🎯",
    "Adapting to the job description...💼",
    "Crafting a compelling narrative... ✍️",
    "Matching your cover letter with your resume... 🔍",
    "Finalizing your conclusion... 📝",
    "Proofreading for perfection... 📖",
  ],

  [ToolType.activityPlanner]: [
    "Generating creative activities that will keep your students engaged and excited 🎉...",
    "Curating a list of interactive classroom activities just for you 🧩...",
    "Planning engaging activities that promote active learning 🎨...",
    "Brainstorming creative ideas to boost student motivation 🚀...",
    "Loading a variety of classroom activities to keep lessons lively 🎤...",
    "Finding the perfect activity to match your teaching goals 🎯...",
    "Generating unique activities that encourage collaboration and fun 🤝...",
    "Searching for new ways to make learning more interactive ✨...",
    "Creating activities that transform lessons into memorable experiences 🌟...",
    "Designing activities that cater to every student’s learning style 🎭...",
    "Preparing creative activities that spark curiosity and excitement 🔍...",
    "Bringing fresh ideas to your classroom to enhance engagement 🌱...",
    "Compiling fun activities that promote hands-on learning ✋...",
    "Crafting activities that turn everyday lessons into adventures 🌍...",
    "Enriching your lessons with dynamic and impactful activities 🏫...",
    "Unlocking new ways to captivate your students' attention 🔓...",
    "Matching engaging activities with your curriculum needs 📚...",
    "We’re crafting experiences that inspire students to learn joyfully 🎨...",
    "Loading creative ideas to make every lesson unforgettable 💡...",
    "Setting up activities that combine fun with learning effortlessly ⚡...",
  ],
  [ToolType.videoSummary]: [
    "Analyzing the video content for key moments... 🎥",
    "Summarizing the main ideas from the video... 📝",
    "Fetching deep insights from the video analysis... 🧠",
    "Decoding video frames to understand the context... 🎬",
    "Processing the data to create a concise summary... ⏳",
    "AI is working hard to understand the video... 🤖",
    "Gathering important points to build the summary... 📚",
    "Breaking down the video to extract critical details... 🔍",
    "We’re almost there, just a little more processing... 🤞",
    "Unveiling hidden details from the video content... 🎁",
    "Fine-tuning the summary to ensure accuracy... 🎻",
    "Wrapping up the analysis for final insights... 🎀",
  ],
  [ToolType.unitPlanner]: [
    "Drafting a comprehensive unit plan to organize your curriculum efficiently 📚...",
    "Designing detailed unit plans that streamline your teaching process 📝...",
    "Loading all the elements to create a thorough unit plan for you 📋...",
    "Structuring your unit plan to keep every lesson on track and engaging 🗂️...",
    "Generating a clear and organized plan for your entire teaching unit 🗒️...",
    "Preparing unit plans that align with your teaching objectives and goals 🎯...",
    "Building a step-by-step guide to help you teach with confidence 🛠️...",
    "Creating a cohesive unit plan that enhances learning continuity ✨...",
    "Compiling curriculum elements into a structured and effective plan 📖...",
    "Organizing lesson content to make your unit flow smoothly from start to finish ⏳...",
    "Mapping out units to ensure comprehensive coverage of all key topics 🗺️...",
    "Reviewing your curriculum to create a balanced and engaging unit plan ⚖️...",
    "Breaking down complex subjects into manageable, teachable units 🧩...",
    "Finalizing unit plans that help you save time and focus on teaching 🕒...",
    "Integrating learning objectives into a cohesive and actionable plan 🎓...",
    "Aligning your unit plan with curriculum standards and student needs 📏...",
    "Optimizing the sequence of lessons to maximize student understanding 🚀...",
    "Arranging topics to build a logical progression in your teaching units 🔍...",
    "Curating content and resources to support every part of your unit 🗃️...",
    "Planning units that provide clarity and direction for your teaching journey 🌟...",
  ],
}

const AiToolsLoading = ({
  open,
  type,
}: {
  open: boolean
  onClose?: () => void
  type: ToolType
}) => {
  return (
    <Dialog open={open}>
      <DialogContent overlayClassName="z-[31]" className="z-[32] shadow-2xl">
        <DialogBody>
          <div className="relative p-2">
            {/* <IconButton
          color="black"
          className="absolute right-2 top-2"
          onClick={onClose}
        >
          <Xmark />
        </IconButton> */}
            {/* @ts-expect-error react version issues */}
            <Lottie
              isClickToPauseDisabled
              style={{
                cursor: "default",
                marginBottom: 10,
                width: 60,
                height: 60,
                marginLeft: 0,
              }}
              speed={1.75}
              options={{
                loop: true,
                autoplay: true,
                animationData: Stars,
              }}
            />
            <TextSlider textArray={LoadingText[type]} className="h-8 sm:h-6" />
            <Typography className="mb-2 mt-0.5 text-onSurface-600 sm:mt-1">
              This might take a minute
            </Typography>

            {open && <FakeProgress />}
          </div>
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}

const FakeProgress = () => {
  const [progress, setProgress] = useState<number>(0)

  useEffect(() => {
    let timer: NodeJS.Timeout

    let elapsedTimeInSeconds = 0

    const updateProgress = () => {
      elapsedTimeInSeconds += 1
      if (elapsedTimeInSeconds <= 60) {
        const newProgress = (elapsedTimeInSeconds / 60) * 95
        setProgress(newProgress)
      } else {
        setProgress(95)
      }
    }
    // eslint-disable-next-line prefer-const
    timer = setInterval(updateProgress, 1000)

    return () => {
      setProgress(0)
      clearInterval(timer)
    }
  }, [])

  return <LinearProgress value={progress} />
}

export default AiToolsLoading
