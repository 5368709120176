import { Typography } from "@suraasa/placebo-ui"
import api from "api"
import GoogleLocationSelect, {
  AddressComponents,
  AddressTypeEnum,
} from "components/GoogleLocationSelect.tsx"
import { getNavigationPortal } from "features/SignupOnboarding/Navigation/utils"
import { AfterSignUpData, FormPageProps } from "features/SignupOnboarding/types"
import { useState } from "react"

const Location = ({ data, updateData, onNext, ...props }: FormPageProps) => {
  const [location, setLocation] = useState<AfterSignUpData["currentLocation"]>(
    data.currentLocation || { city: null, country: null, state: null }
  )

  const updateBackend = async (params: AfterSignUpData["currentLocation"]) => {
    try {
      const payload: { [key: string]: string } = {}
      if (params.city) payload.city = params.city
      if (params.state) payload.state_name = params.state
      if (params.country) payload.country_name = params.country

      await api.profile.update({
        data: payload,
      })
    } catch (e) {
      console.error("Failed to update backend:", e)
    }
  }

  const handleLocationChange = (addressComponents: AddressComponents) => {
    const getComponent = (type: AddressTypeEnum) => {
      const component = addressComponents.find(comp =>
        comp.types.includes(type)
      )
      return component ? component.longText : null
    }

    const city = getComponent(AddressTypeEnum.CITY)
    const state = getComponent(AddressTypeEnum.STATE)
    const country = getComponent(AddressTypeEnum.COUNTRY)

    const updatedLocation = {
      city,
      state,
      country,
    }

    updateData({ currentLocation: updatedLocation })
    setLocation(updatedLocation)
  }

  const handleSubmit = async () => {
    // if (isSeekingJob === null) {
    //   toast.error("Please select an option")
    //   return
    // }
    await updateBackend(location)
    await onNext()
  }

  const isDisabled =
    location.country === null &&
    location.city === null &&
    location.state === null

  return (
    <div className="flex flex-col items-start">
      <Typography variant="title2" color="onSurface.900">
        Where are you currently situated?
      </Typography>
      <div className="mt-3"></div>
      <GoogleLocationSelect
        className="w-full max-w-[480px]"
        value={location}
        label="In which city do you live?"
        placeHolder="Enter your city"
        fullWidth
        onChange={val => {
          handleLocationChange(val)
        }}
      />

      {getNavigationPortal({
        disableNext: isDisabled,
        onNext: handleSubmit,
        ...props,
      })}
    </div>
  )
}

export default Location
