import { Button, Typography } from "@suraasa/placebo-ui"
import { ArrowRight } from "iconoir-react"

const WelcomeToSuraasa = ({ onStart }: { onStart: () => void }) => {
  return (
    <div>
      <div className="mx-2 md:mx-12">
        <img
          src="https://assets.suraasa.com/logos/v2/mini.webp"
          alt="logo"
          width={80}
          height={80}
        />
        <div className="h-4.5"></div>
        <Typography variant="title1">Welcome To Suraasa</Typography>
        <div className="h-2"></div>
        <Typography variant="largeBody" className="max-w-xl text-onSurface-500">
          You’re in the right place to discover teaching resources, courses, AI
          tools, and job opportunities around the world.
          <br />
          <br />
          To tailor your experience, we’d love to learn a bit more about you.
        </Typography>
        <div className="h-6"></div>
        <Button
          onClick={onStart}
          color="black"
          size="lg"
          endAdornment={<ArrowRight className="!size-2" />}
        >
          Get Started
        </Button>
      </div>
    </div>
  )
}

export default WelcomeToSuraasa
