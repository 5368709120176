import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import clsx from "clsx"
import { Plus, Trash } from "iconoir-react"
import { useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { handleErrors } from "utils/helpers"

import RemoveDialogShad from "@/common/RemoveDialogShad"

import ProfileContext from "../../context"
import AddDialog from "./AddDialog"
import { getProficiencyLabel } from "./utils"

const useStyles = createUseStyles(theme => ({
  dialogContent: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
  },
  addLanguages: {
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
  },

  languageContainer: {
    display: "flex",
    padding: theme.spacing(1.5, 3, 1.5, 0),
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.colors.critical[500],
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
    "&:last-child": {
      border: "none",
    },
  },
  addLanguageContainer: {
    padding: theme.spacing(2.5, 3, 5.5, 3),
    display: "flex",
    gap: 24,
    flexDirection: "column",
  },
  removeLanguageContent: {
    padding: theme.spacing(2),
  },
}))

const LanguageDialog = ({
  open,
  onRequestClose,
  source,
}: {
  onRequestClose: () => void
  open: boolean
  source?: string
}) => {
  const classes = useStyles()

  const {
    languages: { data, refetch },
  } = useContext(ProfileContext)

  const hasNoItems = data.length === 0

  const [openAddLanguageDialog, setOpenAddLanguageDialog] = useState(false)
  const [languageToDelete, setLanguageToDelete] = useState<number | null>(null)
  const [removeDialog, setRemoveDialog] = useState(false)

  const handleRemove = async () => {
    if (!languageToDelete) return

    const res = await api.profile.languages.delete({
      urlParams: { id: languageToDelete },
    })
    if (res.isSuccessful) {
      refetch()
      setLanguageToDelete(null)
    } else {
      handleErrors(res)
    }

    setRemoveDialog(false)
  }

  useEffect(() => {
    if (open) setOpenAddLanguageDialog(hasNoItems)
  }, [open, hasNoItems])

  return (
    <Dialog open={open} onOpenChange={onRequestClose}>
      <DialogContent>
        {openAddLanguageDialog || hasNoItems ? (
          <AddDialog
            source={source}
            open={openAddLanguageDialog}
            onAdd={() => refetch()}
            onBack={
              hasNoItems ? undefined : () => setOpenAddLanguageDialog(false)
            }
            onRequestClose={onRequestClose}
          />
        ) : (
          <>
            <RemoveDialogShad
              title="Remove Language"
              onRemove={handleRemove}
              isOpen={removeDialog}
              setIsOpen={setRemoveDialog}
            >
              <Typography variant="smallBody">
                Are you sure you want to remove{" "}
                <b>
                  {
                    data.find(item => item.id === languageToDelete)?.language
                      .name
                  }
                </b>{" "}
                language from your profile?
              </Typography>
            </RemoveDialogShad>
            <DialogHeader>
              <DialogTitle className="text-strong">Languages</DialogTitle>
            </DialogHeader>

            <DialogBody className="flex flex-col p-0">
              <div className={clsx(classes.addLanguages, "px-1.5 py-2")}>
                <Button
                  startAdornment={<Plus className="!size-2.5" />}
                  variant="text"
                  onClick={() => setOpenAddLanguageDialog(true)}
                >
                  Add New Language
                </Button>
              </div>
              <div className="flex flex-col pl-3">
                {data.map(item => (
                  <div className={classes.languageContainer} key={item.id}>
                    <div>
                      <Typography
                        className="pb-0.5 text-onSurface-800"
                        variant="strong"
                      >
                        {item.language.name}
                      </Typography>
                      <Typography
                        className="text-onSurface-500"
                        variant="smallBody"
                      >
                        {getProficiencyLabel(item.proficiency)}
                      </Typography>
                    </div>
                    <IconButton
                      color="critical"
                      onClick={() => {
                        setLanguageToDelete(item.id)
                        setRemoveDialog(true)
                      }}
                    >
                      <Trash className="!size-3" />
                    </IconButton>
                  </div>
                ))}
              </div>
            </DialogBody>
            <DialogFooter>
              <Button
                size="sm"
                onClick={onRequestClose}
                variant="outlined"
                color="black"
                className="border-onSurface-500 text-onSurface-500"
              >
                Close
              </Button>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default LanguageDialog
