import aiTools from "./resources/aiTools"
import applicationForm from "./resources/applicationForm"
import assessments from "./resources/assessments"
import assignments from "./resources/assignments"
import batch from "./resources/batch"
import credentials from "./resources/credentials"
import discussions from "./resources/discussions"
import feedback from "./resources/feedback"
import global from "./resources/global"
import itoAssessment from "./resources/ito/assessments"
import itoReferAndEarn from "./resources/ito/referAndEarn"
import itoRegistrations from "./resources/ito/registrations"
import itoReports from "./resources/ito/reports"
import learningItems from "./resources/learningItems"
import masterclasses from "./resources/masterclasses"
import notifications from "./resources/notifications"
import orders from "./resources/orders"
import payment from "./resources/payment"
import profile from "./resources/profile"
import profileStrength from "./resources/profile/profileStrength"
import references from "./resources/references"
import sales from "./resources/sales"
import schedule from "./resources/schedule"
import settings from "./resources/settings"
import subscriptions from "./resources/subscriptions"
import tia from "./resources/tia"
import users from "./resources/users"

export default {
  discussions,
  assignments,
  masterclasses,
  feedback,
  users,
  assessments,
  ito: {
    assessments: itoAssessment,
    reports: itoReports,
    referAndEarn: itoReferAndEarn,
    registrations: itoRegistrations,
  },
  profile: {
    ...profile,
    strength: profileStrength,
  },
  credentials,
  settings,
  global,
  orders,
  subscriptions,
  learningItems,
  applicationForm,
  schedule,
  payment,
  notifications,
  aiTools,
  tia,
  batch,
  sales,
  references,
}
