import React from "react"
import { Helmet, HelmetProps } from "react-helmet"

interface Props extends HelmetProps {
  data: {
    title: string
    description?: string
    url?: string
    coverImage?: string
    type?: string
  }
  dynamicContent?: string | null
}

// Format: <dynamic content> | <page name> | Suraasa Learn
const ReactHelmet = ({ data, dynamicContent, ...props }: Props) => {
  const type = data.type || "website"

  const title = dynamicContent
    ? `${dynamicContent} | ${data.title}`
    : data.title

  return (
    // @ts-expect-error react version issues
    <Helmet
      title={title}
      defaultTitle="Suraasa"
      titleTemplate="%s | Suraasa Learn"
      {...props}
    >
      {/* <!-- Primary Meta Tags --> */}
      {title && <meta name="title" content={title} />}
      {data.description && (
        <meta name="description" content={data.description} />
      )}

      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content={type} />
      {data.url && <meta property="og:url" content={data.url} />}
      {title && <meta property="og:title" content={title} />}
      {data.description && (
        <meta property="og:description" content={data.description} />
      )}
      {data.coverImage && (
        <meta property="og:image" content={data.coverImage} />
      )}

      {/* <!-- Twitter --> */}
      {data.coverImage && (
        <meta property="twitter:card" content={data.coverImage} />
      )}
      {data.url && <meta property="twitter:url" content={data.url} />}
      {title && <meta property="twitter:title" content={title} />}
      {data.description && (
        <meta property="twitter:description" content={data.description} />
      )}
      {data.description && (
        <meta property="twitter:image" content={data.description} />
      )}
    </Helmet>
  )
}

export default ReactHelmet
