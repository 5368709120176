import { IconButton, Tag, Typography } from "@suraasa/placebo-ui"
import ProfileContext from "features/Profile/context"
import { Edit, Plus } from "iconoir-react"
import { useContext, useState } from "react"
import { pluralize } from "utils/helpers"

import Section from "../Section"
import SectionContent from "../Section/SectionContent"
import SectionHeading from "../Section/SectionHeading"
import SectionTitle from "../Section/SectionTitle"
import SkillsDialog from "./SkillsDialog"

const Skills = () => {
  const [openDialog, setOpenDialog] = useState(false)

  const {
    isPublic,
    skills: { data },
  } = useContext(ProfileContext)

  const handleClose = () => {
    setOpenDialog(!openDialog)
  }

  return (
    <>
      <SkillsDialog handleDialogClose={handleClose} open={openDialog} />
      <Section>
        <SectionHeading
          heading={
            <SectionTitle
              actionIcon={
                isPublic ? undefined : (
                  <IconButton
                    onClick={() => setOpenDialog(true)}
                    className="[&>svg]:!size-3"
                  >
                    {data.length > 0 ? <Edit /> : <Plus />}
                  </IconButton>
                )
              }
              itemCount={data.length}
              title={pluralize("Additional Skill", data.length, {
                skipCount: true,
              })}
            />
          }
        />
        <SectionContent className="flex flex-wrap gap-1.5" xPadding={2}>
          {data.length > 0 ? (
            data.map((item, i) => (
              <Tag key={i} size="sm">
                {item.skillName}
              </Tag>
            ))
          ) : (
            <Typography className="text-onSurface-400" variant="smallBody">
              {isPublic
                ? "Looks like there's nothing here."
                : "You haven’t added any skills. Click on + icon to add one."}
            </Typography>
          )}
        </SectionContent>
      </Section>
    </>
  )
}

export default Skills
