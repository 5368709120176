import api from "api"
import { WorkLocationInterest } from "api/resources/profile/types"
import { context } from "global/Context/context"
import { useFeatureToggle } from "global/FeatureToggleProvider"
import { useContext, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import routes from "routes"
import { BROWSER_STORAGE_KEYS } from "utils/constants"
import { sleep } from "utils/helpers"
import useHideGlobalBottomNavigation from "utils/hooks/useGlobalHideBottomNavigation"
import useHideTopNavigation from "utils/hooks/useHideTopNavigation"
import { trackingService } from "utils/tracking"
import { UserPreferenceManager } from "utils/userPreferenceManager"

import Animation from "./Components/Animation"
import {
  OnboardingFlowManager,
  OnboardingStep,
} from "./Components/OnboardingFlowManager"
import TestimonialSection from "./Components/Testimonials"
import { testimonials } from "./Components/Testimonials/data"
import WelcomeToSuraasa from "./Screens/WelcomeToSuraasa"
import { AfterSignUpData } from "./types"

const SignUpOnboarding = () => {
  useHideTopNavigation()
  useHideGlobalBottomNavigation()

  const featureToggles = useFeatureToggle()

  const { profile } = useContext(context)

  const [currentPage, setCurrentPage] = useState(0)
  const [animationDirection, setAnimationDirection] = useState(1)
  const [isOnboarding, setIsOnboarding] = useState(false)
  const [pages, setPages] = useState<OnboardingStep[]>([])
  const [data, setData] = useState<AfterSignUpData>({
    profilePicture: null,
    isTeachingInSchool: null,
    hasExperienceInTeaching: null,
    subjects: [],
    gradeInterests: [],
    gradesTaught: [],
    desiredOutcome: [],
    curricula: [],
    seekingJob: null,
    yearsOfExperience: null,
    dateOfBirth: null,
    jobLocationPreference: { country: null, state: null, city: null },
    workExperienceData: null,
    currentLocation: { country: null, state: null, city: null },
  })

  const fetchData = async () => {
    try {
      const data: AfterSignUpData = {
        profilePicture: null,
        isTeachingInSchool: null,
        hasExperienceInTeaching: null,
        subjects: [],
        gradeInterests: [],
        gradesTaught: [],
        desiredOutcome: [],
        curricula: [],
        seekingJob: null,
        yearsOfExperience: null,
        dateOfBirth: null,
        jobLocationPreference: {
          city: null,
          state: null,
          country: null,
        },
        currentLocation: { city: null, country: null, state: null },
        workExperienceData: null,
      }

      await Promise.allSettled([
        api.profile.getMiscData().then(res => {
          data["isTeachingInSchool"] = res?.currentlyTeachingInSchool
          data["hasExperienceInTeaching"] = true
          data["desiredOutcome"] = res?.desiredMilestones || []
        }),
        api.profile.getAdditionalData().then(res => {
          data["gradeInterests"] = res?.gradeInterests || []
          data["gradesTaught"] = res?.gradesTaught || []
          data["curricula"] = res?.curriculaTaught || []
        }),
        api.profile
          .retrieve({
            urlParams: {},
          })
          .then(res => {
            data["profilePicture"] = res?.picture
            data["seekingJob"] = res?.lookingForJobs
            data["yearsOfExperience"] = res?.yearsOfExperience
            data["dateOfBirth"] = res?.dateOfBirth
              ? new Date(res.dateOfBirth)
              : null
            data["currentLocation"] = {
              city: res?.city || null,
              state: res?.state?.name || null,
              country: res?.country?.name || null,
            }
          }),
        api.profile.interests.list({ urlParams: {} }).then(res => {
          const preferredWorkLocation: WorkLocationInterest | null =
            res?.preferredWorkLocations?.[0] || null

          data["jobLocationPreference"] = {
            city: preferredWorkLocation?.city || null,
            state: preferredWorkLocation?.state?.name || null,
            country: preferredWorkLocation?.country?.name || null,
          }

          data["subjects"] = res?.subjects.map(subject => subject.subject) || []
        }),
        api.profile.workExperiences.list({ urlParams: {} }).then(res => {
          const workExperience =
            res
              ?.slice()
              .reverse()
              .find(val => val.currentlyWorking === true) || null

          if (!workExperience) return
          data["workExperienceData"] = {
            id: workExperience.id,
            title: workExperience.title,
            employmentType: workExperience.employmentType ?? "",
            organisationName: workExperience.organisationName,
            startDate: workExperience.startDate,
            country: workExperience.country,
            state: workExperience.state,
          }
        }),
      ])

      if (data) setData(data)

      const flowManager = new OnboardingFlowManager({
        data,
        source: profile.user.source,
      })

      const flowPages = flowManager.getFlow()
      setPages(flowPages)

      const firstIncompleteStep = flowManager.getFirstIncompleteStep(flowPages)
      setCurrentPage(firstIncompleteStep)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const updateData = (newData: Partial<AfterSignUpData>) => {
    setData(prev => {
      const updatedData = { ...prev, ...newData }

      const flowManager = new OnboardingFlowManager({
        data: updatedData,
        source: profile.user.source,
      })
      const flowPages = flowManager.getFlow()
      setPages(flowPages)

      return updatedData
    })
  }

  const goToPreviousPage = async () => {
    setAnimationDirection(-1)
    setCurrentPage(prev => prev - 1)
  }

  const goToNextPage = async (skipped = false) => {
    if (skipped)
      trackingService.trackEvent("signup_onboarding_q_skipped", {
        question_id: pages[currentPage].id,
      })
    else
      trackingService.trackEvent("signup_onboarding_q_answered", {
        question_id: pages[currentPage].id,
      })

    if (currentPage === pages.length - 1) {
      await onFinish()
      return
    }

    setAnimationDirection(1)
    setCurrentPage(prev => prev + 1)
  }

  const buildPageIndicator = () => (
    <div className="flex flex-row">
      {[...Array(pages.length)].map((_, index) => (
        <div
          key={index}
          className={`mx-0.5 h-1 w-2 rounded-full ${
            currentPage === index ? "bg-black" : "bg-gray-300"
          }`}
        />
      ))}
    </div>
  )

  const [searchParams] = useSearchParams()

  const onFinish = async () => {
    UserPreferenceManager.set(
      BROWSER_STORAGE_KEYS.signupOnboardingCompleted,
      "true"
    )
    trackingService.trackEvent("signup_onboarding_completed")
    await sleep(2000)

    const next = searchParams.get("next") || routes.home
    const url = new URL(next, window.location.origin)
    window.location.href = url.toString()
  }

  useEffect(() => {
    trackingService.trackEvent("signup_onboarding_presented")
  }, [])

  if (!featureToggles.platformOnboarding.isEnabled) return null

  const Component = pages[currentPage]?.component

  return (
    <div className="grid h-screen bg-white lg:grid-cols-12">
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      {/* <pre className="absolute right-0 top-0">
        {JSON.stringify(
          pages.map(x => x.id),
          null,
          2
        )}
      </pre>
      <pre className="absolute right-0 top-0">
        {JSON.stringify({ currentPage }, null, 2)}
      </pre> */}
      {!isOnboarding && (
        <div className="col-span-12 flex flex-col justify-center lg:col-span-7">
          <WelcomeToSuraasa
            onStart={() => {
              trackingService.trackEvent("signup_onboarding_started")
              setIsOnboarding(true)
            }}
          />
        </div>
      )}

      <div
        className="col-span-12 mx-2 mt-2 flex flex-col justify-between sm:mx-4 lg:col-span-7 xl:mx-20"
        style={{
          display: isOnboarding ? "flex" : "none",
        }}
      >
        <div>
          <img
            src="https://assets.suraasa.com/logos/v2/full-dark.webp"
            alt="logo"
            width={200}
          />
          {pages[currentPage] && (
            <div className="mb-12 mt-4">
              {buildPageIndicator()}
              <div className="mt-6">
                <Animation
                  id={`page-${currentPage}`}
                  direction={animationDirection}
                >
                  <Component
                    key={pages[currentPage].id}
                    updateData={updateData}
                    data={data}
                    onNext={goToNextPage}
                    onPrevious={goToPreviousPage}
                    totalPages={pages.length}
                    isFirstPage={currentPage === 0}
                    isLastPage={currentPage === pages.length - 1}
                  />
                </Animation>
              </div>
            </div>
          )}
        </div>
        <div
          className="sticky bottom-0 left-0 w-full border-t bg-white"
          id="sign-up-onboarding-navigation-portal"
        />
      </div>

      <div className="relative hidden lg:col-span-5 lg:block">
        <div
          className="absolute left-0 top-0 z-0 size-full min-h-screen bg-surface-100"
          style={{
            backgroundImage: `radial-gradient(circle at 47% 100%, hsla(42, 100%, 76%, 1) 0%, transparent 56%),
          radial-gradient(circle at 16% 99%, hsla(270, 63%, 78%, 1) 0%, transparent 22%),
          radial-gradient(circle at 75% 98%, hsla(190, 100%, 77%, 1) 8%, transparent 28%)`,
          }}
        />
        <TestimonialSection testimonials={testimonials} />
      </div>
    </div>
  )
}

export default SignUpOnboarding
