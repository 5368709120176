import { Checkbox } from "@suraasa/placebo-ui"
import { PropsWithChildren } from "react"
import { PropsWithClassName } from "utils/types"

const CheckListItem = ({
  children,
  className,
  checked,
  onChange,
}: PropsWithClassName<
  PropsWithChildren<{
    checked?: boolean
    onChange: (checked: boolean) => void
  }>
>) => {
  return (
    <Checkbox
      containerClass="flex items-start space-x-2"
      className={className}
      checked={checked}
      onCheckedChange={onChange}
      label={children as any}
    />
  )
}

export default CheckListItem
