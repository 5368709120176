import { Button, IconButton, Tag, Typography } from "@suraasa/placebo-ui"
import { Qualification, UserAcademicStatus } from "api/resources/profile/types"
import { SourceType } from "api/resources/references/types"
import clsx from "clsx"
import { format } from "date-fns"
import twitterVerifiedBadge from "features/Profile/assets/twitter-verified-badge.png"
import { Edit } from "iconoir-react"
import { Mail } from "lucide-react"
import { useContext, useState } from "react"
import { createUseStyles } from "react-jss"

import ProfileContext from "../../../context"
import ReferenceEditDialog from "../../References/EditDialog"
import { PublicReferenceCard, ReferenceCard } from "../../References/Utils"
import PreviewEvidenceList from "../EvidenceList"

const useStyles = createUseStyles(theme => ({
  details: {
    position: "relative",

    "& .edit-button": {
      position: "absolute",
      zIndex: "1",
      background: "white",

      [theme.breakpoints.down("sm")]: {
        right: "0px",
      },
      [theme.breakpoints.up("md")]: {
        pointerEvents: "none",
        right: "-30px",
        opacity: "0",
        transition: ".2s all",
      },
    },
    "&:hover .edit-button": {
      [theme.breakpoints.up("md")]: {
        opacity: "1",
        right: "0px",
        pointerEvents: "all",
      },
    },
  },
}))

const Qualifications = ({
  qualification,
  onEdit,
}: {
  onEdit: (id: Qualification["id"]) => void
  qualification: Qualification
}) => {
  const classes = useStyles()

  const [openDialog, setOpenDialog] = useState(false)
  const { isPublic, referenceAnalytics } = useContext(ProfileContext)

  const referenceCount =
    referenceAnalytics.data?.qualification[qualification.id] || 0

  return (
    <>
      {openDialog && (
        <ReferenceEditDialog
          currentQualification={qualification}
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
        />
      )}
      <div className="ml-2 pt-2">
        <div className={clsx(classes.details, "pb-2")}>
          {!isPublic && !qualification.isVerified && (
            <IconButton
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className="edit-button"
              color="black"
              onClick={() => onEdit(qualification.id)}
            >
              <Edit className="!size-3" />
            </IconButton>
          )}

          <div className="mb-0.5 flex flex-wrap items-center gap-1">
            <Typography variant="strongSmallBody">
              {qualification.name}
            </Typography>
            {qualification.isVerified && (
              <img
                alt="✅"
                height="24px"
                src={twitterVerifiedBadge}
                width="24px"
              />
            )}
            {qualification.status === UserAcademicStatus.IN_PROGRESS && (
              <Tag color="onSurface">In Progress</Tag>
            )}
          </div>

          {qualification.qualificationLevel && (
            <Typography className="mb-0.25" variant="smallBody">
              {qualification.qualificationLevel.name}
            </Typography>
          )}

          <Typography className="mb-0.25" variant="smallBody">
            {qualification.organisationName}
          </Typography>

          {qualification.startDate && (
            <Typography className="mb-0.5" variant="smallBody">
              {[
                format(new Date(qualification.startDate), "MMMM yyyy"),
                qualification.endDate
                  ? format(new Date(qualification.endDate), "MMMM yyyy")
                  : "Present",
              ].join(" - ")}
            </Typography>
          )}

          {qualification.evidences && qualification.evidences.length > 0 && (
            <PreviewEvidenceList data={qualification.evidences} />
          )}
          {isPublic && referenceCount > 0 && (
            <PublicReferenceCard
              count={referenceCount}
              referenceType={SourceType.UserQualification}
            />
          )}

          {!isPublic &&
            qualification.references?.map((ref, ind) => (
              <ReferenceCard key={ind} data={ref} />
            ))}

          {!isPublic && (
            <Button
              className="mt-2"
              onClick={() => setOpenDialog(true)}
              variant="text"
              startAdornment={<Mail />}
            >
              Add Reference
            </Button>
          )}
        </div>
      </div>
    </>
  )
}

export default Qualifications
