import { Typography } from "@suraasa/placebo-ui"
import React, { useEffect, useState } from "react"

import { Testimonial } from "./data"

interface TestimonialSectionProps {
  testimonials: Testimonial[]
}

const FADE_DURATION = 750
const NEXT_TESTIMONIAL_DELAY = 10 * 1000

const TestimonialSection: React.FC<TestimonialSectionProps> = React.memo(
  ({ testimonials }) => {
    const [index, setIndex] = useState(0)

    const [fade, setFade] = useState(true)

    useEffect(() => {
      const interval = setInterval(() => {
        setFade(false)
        setTimeout(() => {
          setIndex(prev => (prev + 1) % testimonials.length)
          setFade(true)
        }, FADE_DURATION)
      }, NEXT_TESTIMONIAL_DELAY)

      return () => clearInterval(interval)
    }, [testimonials])

    const testimonial = testimonials[index]

    return (
      <div
        className={`relative z-10 mx-14 flex h-full flex-col items-center justify-center transition-opacity duration-1000 ${
          fade ? "opacity-100" : "opacity-0"
        }`}
      >
        <Typography variant="body" className="text-center text-onSurface-600">
          “{testimonial.quote}”
        </Typography>
        <div className="h-4.5"></div>
        <div className="relative size-6 overflow-hidden rounded-full">
          <img
            src={testimonial.image}
            alt={testimonial.name}
            className="size-full object-cover"
          />
        </div>
        <div className="h-1"></div>
        <Typography variant="strongSmallBody" className="text-onSurface-600">
          {testimonial.name}
        </Typography>
        <div className="h-0.25"></div>
        <Typography variant="smallBody" className="text-onSurface-500">
          {testimonial.designation}
        </Typography>
      </div>
    )
  }
)

TestimonialSection.displayName = "TestimonialSection"

export default TestimonialSection
