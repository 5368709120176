import {
  Avatar,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
  Typography,
} from "@suraasa/placebo-ui"
import {
  Button,
  Chip,
  Container,
  IconButton,
  theme,
  toast,
  Tooltip,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import api from "api"
import { CoverStyle } from "api/resources/profile/types"
import clsx from "clsx"
import ShareSheet from "components/ShareSheet"
import TabBar from "components/TabBar"
import useTabs from "components/TabBar/hooks/useTabs"
import CoverLetter from "features/AItools/components/Generator/CoverLetter"
import ProfileContext from "features/Profile/context"
import { PROFILE_ONBOARDING_TARGETS } from "features/Profile/ProfileOnboarding/constants"
import { ProfileQuickAccessTabs, usePreviewMode } from "features/Profile/utils"
import { useFeatureToggle } from "global/FeatureToggleProvider"
import {
  BadgeCheck,
  Copy,
  Edit,
  Mail,
  MapPin,
  MoreVert,
  Phone,
  ShareAndroid,
} from "iconoir-react"
import { useContext, useEffect, useRef, useState } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import { useSearchParams } from "react-router-dom"
import { BROWSER_STORAGE_KEYS } from "utils/constants"
import {
  buildUserName,
  getAuthInfo,
  pluralize,
  redirectToLogin,
} from "utils/helpers"
import { useEnrollments } from "utils/hooks/useEnrollments"
import useHasVisited from "utils/hooks/useHasVisited"
import { UserPreferenceManager } from "utils/userPreferenceManager"

import InterviewQuestionsKit from "../Kits/InterviewQuestionsKit"
import JobApplicationKit from "../Kits/JobApplicationKit"
import { Badge } from "../ProfileStrength/assets"
import { useProfileStrength } from "../ProfileStrength/useProfileStrength"
import AddProfileSectionDialog from "./AddProfileSectionDialog"

const getImageUrl = (imageName?: CoverStyle, isSmDown?: boolean) => {
  if (!imageName) {
    return undefined
  }

  let baseUrl = "https://assets.suraasa.com/lms/profile-assets/cover-images/"

  if (isSmDown) {
    baseUrl += "mobile/"
  }

  return `${baseUrl}${imageName}.webp`
}
export const formatWorkDuration = (days: number) => {
  if (days < 365) {
    const months = Math.floor(days / 30)

    if (months === 0) return "Less than a month"
    return pluralize("month", months)
  }

  const eightMonthsInYears = 8 / 12

  const years = days / 365
  const threshold = Math.trunc(years) + eightMonthsInYears

  if (years > threshold) return `${Math.trunc(years)}+ years`

  return `${pluralize("year", Math.floor(years))}`
}

const initialTabs = [
  {
    content: () => <div />,
    name: ProfileQuickAccessTabs.about,
  },
  {
    content: () => <div />,
    name: ProfileQuickAccessTabs.resume,
  },
  {
    content: () => <div />,
    name: ProfileQuickAccessTabs.educationWithSuraasa,
  },
  {
    content: () => <div />,
    name: ProfileQuickAccessTabs.skillPortfolio,
  },
  {
    content: () => <div />,
    name: ProfileQuickAccessTabs.otherDetails,
  },
]
const scrollTo = (id: string, offset: number) => {
  const element = document.getElementById(id)
  if (!element) return

  const elementPosition = element.getBoundingClientRect().top
  const offsetPosition = elementPosition + window.pageYOffset - offset

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  })
}
const Overview = ({
  videoPortfolioURL,
  openVideoPortfolioDialog,
  openEditProfile,
  toggleChangeCoverStyle,
  onResumeAddClick,
}: {
  videoPortfolioURL: string | null
  openVideoPortfolioDialog: () => void
  openEditProfile: () => void
  toggleChangeCoverStyle: () => void
  onResumeAddClick: () => void
}) => {
  const isOnboardingCompleted =
    UserPreferenceManager.get(BROWSER_STORAGE_KEYS.profileTourCompleted) ===
    "true"

  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const isLoggedIn = getAuthInfo()
  const [openShare, setOpenShare] = useState(false)
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [openCoverLetter, setOpenCoverLetter] = useState(false)
  const [copied, setCopied] = useState(false)
  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const featureToggle = useFeatureToggle()

  const isAIToolsEnabled = featureToggle.aiTools.isEnabled

  const [searchParams, setSearchParams] = useSearchParams()
  const isEmbedded = searchParams.get("embed") === "true"

  const { previewMode, setPreviewMode, isViewingAsOtherPlatform } =
    usePreviewMode()

  const { hasVisited, ref: divRef } = useHasVisited({
    disabled: !isOnboardingCompleted,
  })

  const [activeTab, setActiveTab] = useTabs({
    tabs: initialTabs,
    initialTab: initialTabs[0].name,
    useSearchParams: false,
  })

  const {
    workExperiences,
    profile: {
      coverStyle,
      totalWorkingExperience: experienceInYears,
      user,
      resume,
      isVerified,
      picture,
      phoneNumber,
      state,
      country,
    },
    isPublic,
    subjects,
    updateProfile,
  } = useContext(ProfileContext)

  const { isPGCTLUser } = useEnrollments()

  const { hasProfileStrengthBadge } = useProfileStrength()

  const { email } = user

  const url = `${window.location.origin}/profile/${user.username}`
  const fullName = buildUserName(user)
  const teaches = subjects.data.slice(0, 2).map(item => item.subject.name)

  const curriculumTags = [
    ...new Set(
      workExperiences.data
        .map(item => item.curriculum?.name)
        .filter(name => name)
    ),
  ]

  const toggleAddDialog = () => {
    setOpenAddDialog(!openAddDialog)
  }

  const toggleCoverLetter = () => {
    setOpenCoverLetter(!openCoverLetter)
  }

  const action = searchParams.get("action")

  useEffect(() => {
    if (action) {
      if (action === "uploadResume") {
        onResumeAddClick()
      }
      if (action === "generateCoverLetter") {
        toggleCoverLetter()
      }
      searchParams.delete("action")
      setSearchParams(searchParams, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action])

  const [kit, setKit] = useState<"jobApplication" | "interview" | null>(null)

  const openKit = (type: "jobApplication" | "resume" | "interview") => {
    switch (type) {
      case "jobApplication":
        return setKit("jobApplication")
      case "resume":
        return window.open(
          "https://docs.google.com/presentation/d/1OVQ6MCvb8xU3QC17T9W3yLUdTsXHVFLSaq6csSDJWdA/template/preview",
          "_blank"
        )
      case "interview":
        return setKit("interview")
    }
  }

  function copyToClipboard() {
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }

  const validateImageUpload = (file: File) => {
    if (!file.type.includes("image")) {
      toast.error("Only images are allowed")
      return
    }
    if (file.size / 1024 / 1024 > 5) {
      toast.error("Only images under 5 MB are allowed")
      return
    }

    const acceptedImageTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "image/webp",
    ]

    if (!acceptedImageTypes.includes(file.type)) {
      toast.error(
        "Invalid Image Type. Only jpg, jpeg, png & webp format are allowed"
      )
      return
    }

    return file
  }

  const uploadPicture = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = (e.target.files as FileList)[0]

    if (fileUploaded && validateImageUpload(fileUploaded)) {
      const files = new FormData()
      files.append("picture", fileUploaded)

      const res = await api.profile.update({
        data: files,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      if (res.isSuccessful) {
        updateProfile()
      } else {
        if (res.errors.message) toast.error(res.errors.message)
      }
    }
  }

  const handlePictureRemove = async () => {
    const res = await api.profile.update({
      data: { picture: null },
    })
    if (res.isSuccessful) {
      updateProfile()
    } else {
      if (res.errors.message) toast.error(res.errors.message)
    }
  }

  const formattedExperience = !experienceInYears
    ? undefined
    : formatWorkDuration(experienceInYears * 365)

  return (
    <>
      <CoverLetter
        onClose={toggleCoverLetter}
        open={openCoverLetter}
        handleOpenPersonalDetailsSheet={openEditProfile}
      />

      <AddProfileSectionDialog open={openAddDialog} onClose={toggleAddDialog} />
      <ShareSheet
        data={{
          url,
          text: isPublic
            ? `Check out ${fullName}'s profile on Suraasa, the career app for teachers: ${url}`
            : `Check out my profile on Suraasa, the career app for teachers: ${url}`,
        }}
        open={openShare}
        handleClose={() => setOpenShare(false)}
      />
      {previewMode && (
        <div className="bg-interactive-500 text-white">
          <Container
          // this is for 10 column layout
          // className={clsx({
          //   "!grid grid-cols-12 gap-2 sm:[&>div]:col-span-10 sm:[&>div]:col-start-2 [&>div]:col-span-12 [&>div]:!min-w-[auto] xl:[&>div]:!min-w-[1280px]":
          //     isPublic,
          // })}
          >
            <div className="flex flex-col items-center justify-between gap-1.5 py-1 sm:flex-row">
              <Typography variant="subtitle2">
                This is a preview of how your profile will look to a school
              </Typography>
              <Button
                rounded
                color="white"
                size="sm"
                onClick={() => setPreviewMode(false)}
                className="!w-full !text-primary-500 sm:!w-auto"
              >
                Exit
              </Button>
            </div>
          </Container>
        </div>
      )}
      {!isEmbedded && !isLoggedIn && isPublic && !isViewingAsOtherPlatform && (
        <div className="bg-interactive-500 text-white">
          <Container
          // this is for 10 column layout
          // className={clsx({
          //   "!grid grid-cols-12 gap-2 sm:[&>div]:col-span-10 sm:[&>div]:col-start-2 [&>div]:col-span-12 [&>div]:!min-w-[auto] xl:[&>div]:!min-w-[1280px]":
          //     isPublic,
          // })}
          >
            <div className="flex flex-col items-center justify-between gap-1.5 py-1 sm:flex-row">
              <Typography variant="subtitle2">
                Sign in to view full profile
              </Typography>
              <Button
                rounded
                color="white"
                size="sm"
                className="!w-full !text-primary-500 sm:!w-auto"
                onClick={redirectToLogin}
              >
                Sign In
              </Button>
            </div>
          </Container>
        </div>
      )}
      <div
        ref={divRef}
        className="mb-3 border-b-2 border-solid border-b-onSurface-200 bg-white shadow-[0px_6px_15px_0px_#0000000D]"
      >
        <div
          style={{
            backgroundImage: `url(${getImageUrl(coverStyle, isXsDown)})`,
          }}
          className={clsx("group relative h-[107px] bg-cover text-black", {
            "hover:bg-black/50": !isPublic,
          })}
        >
          {!coverStyle && (
            <div className="absolute bottom-0 left-0 flex size-full items-center justify-center bg-onSurface-200" />
          )}
          {!isPublic && (
            <div className="absolute bottom-0 left-0 flex size-full items-center justify-center overflow-hidden bg-black/35 opacity-0 transition-opacity duration-200 group-hover:opacity-100">
              <Button
                rounded
                color="black"
                className="translate-y-[calc(100%+36px)] !bg-white/25 opacity-0 transition-all duration-200 group-hover:translate-y-0 group-hover:opacity-100"
                startAdornment={<Copy />}
                size="sm"
                onClick={toggleChangeCoverStyle}
              >
                Choose Cover Style
              </Button>
            </div>
          )}
        </div>
        <Container
          className={clsx({
            "py-2.5": !isPublic,
            "pt-2.5": isPublic,
            // this is for 10 column layout
            // "!grid grid-cols-12 gap-2 sm:[&>div]:col-span-11 sm:[&>div]:col-start-2 [&>div]:col-span-12 [&>div]:!min-w-[auto] xl:[&>div]:!min-w-[1280px]":
            //   isPublic,
          })}
        >
          <input
            accept=".jpg, .jpeg, .png ,.webp"
            ref={hiddenFileInput}
            style={{ display: "none" }}
            type="file"
            onChange={uploadPicture}
          />
          <div className="relative">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div className="absolute -top-9 cursor-pointer">
                  <div className="group relative inline-block overflow-hidden rounded-full">
                    <Avatar
                      name={fullName}
                      className="size-15 cursor-pointer"
                      src={picture}
                    />
                    {!isPublic && (
                      <div className="absolute inset-0 flex items-center justify-center bg-black/35 opacity-0 transition-opacity duration-200 group-hover:opacity-100">
                        <Button
                          size="sm"
                          variant="text"
                          className="translate-y-[calc(100%+36px)] !bg-white/25 !text-white transition-all duration-200 group-hover:translate-y-0 group-hover:opacity-100"
                          // onClick={toggleOpen}
                          startAdornment={<Edit className="!size-2" />}
                        >
                          Edit
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </DropdownMenuTrigger>
              {!isPublic && (
                <DropdownMenuContent>
                  <DropdownMenuGroup>
                    <DropdownMenuItem
                      onClick={() => hiddenFileInput.current?.click()}
                    >
                      Upload New Image
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      color="critical"
                      disabled={!picture}
                      onClick={handlePictureRemove}
                    >
                      Remove
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              )}
            </DropdownMenu>

            {isPublic ? (
              <div className="mb-1 flex items-center justify-end space-x-1">
                <Tooltip
                  disabled={!(isPublic && !videoPortfolioURL)}
                  title={`${user.firstName} has not added a video portfolio yet.`}
                >
                  <span>
                    <Button
                      size="sm"
                      rounded
                      disabled={isPublic && !videoPortfolioURL}
                      onClick={() => {
                        if (videoPortfolioURL || !isPublic)
                          openVideoPortfolioDialog()
                      }}
                    >
                      Video Portfolio
                    </Button>
                  </span>
                </Tooltip>

                <IconButton
                  onClick={() => {
                    setOpenShare(true)
                  }}
                  variant="filled"
                  className="!hidden !rounded-full sm:!grid"
                  size="sm"
                >
                  <ShareAndroid fontSize={13} className="me-0.25" />
                </IconButton>
              </div>
            ) : (
              <div className="flex items-center justify-end space-x-1">
                <Button
                  rounded
                  size="sm"
                  className="!hidden sm:!inline-block"
                  onClick={toggleAddDialog}
                >
                  Add Profile Section
                </Button>

                {!isPublic && (
                  <Button
                    rounded
                    size="sm"
                    variant="outlined"
                    color="secondary"
                    className="!border-onSurface-400"
                    onClick={openEditProfile}
                  >
                    Edit Profile
                  </Button>
                )}
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <IconButton
                      color="secondary"
                      size="sm"
                      className="!rounded-full border-2 border-solid border-onSurface-400"
                    >
                      <MoreVert />
                    </IconButton>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    {!resume && (
                      <DropdownMenuItem onClick={onResumeAddClick}>
                        Upload Resume
                      </DropdownMenuItem>
                    )}
                    <DropdownMenuItem onClick={() => setPreviewMode(true)}>
                      View Profile As School
                    </DropdownMenuItem>

                    {isPGCTLUser && (
                      <DropdownMenuSub>
                        <DropdownMenuSubTrigger>
                          Open Kits
                        </DropdownMenuSubTrigger>
                        <DropdownMenuSubContent>
                          <DropdownMenuItem onClick={() => openKit("resume")}>
                            Resume Kit
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onClick={() => openKit("jobApplication")}
                          >
                            Job Application Kit
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onClick={() => openKit("interview")}
                          >
                            Interview Kit
                          </DropdownMenuItem>
                        </DropdownMenuSubContent>
                      </DropdownMenuSub>
                    )}
                    <DropdownMenuItem onClick={toggleChangeCoverStyle}>
                      Choose Cover Style
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => {
                        setOpenShare(true)
                      }}
                      className="block sm:hidden"
                    >
                      Share Profile
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            )}
            <div className="mt-3 flex flex-wrap items-center">
              <Typography className="inline" variant="title3">
                {fullName}
              </Typography>
              {hasProfileStrengthBadge && (
                <Badge isColored className="size-3.5" />
              )}
              {isVerified && (
                <BadgeCheck
                  color="white"
                  height="26"
                  width="26"
                  className="ms-[1px] fill-primary-500"
                />
              )}
              <div className="ms-1 flex items-center gap-0.5">
                {curriculumTags.map(name => (
                  <Chip
                    key={name}
                    color="secondary"
                    label={name}
                    className="!border-onSurface-400 !text-onSurface-800"
                    size="sm"
                    variant="outlined"
                  />
                ))}
              </div>
            </div>
            {((teaches && teaches.length > 0) || formattedExperience) && (
              <div className="mt-1 flex flex-col gap-0.5">
                {teaches && teaches.length > 0 && (
                  <Typography>
                    Teaches{" "}
                    <Typography className="inline" variant="subtitle2">
                      {teaches.join(", ")}
                    </Typography>
                  </Typography>
                )}

                {formattedExperience && (
                  <Typography>
                    Experience of{" "}
                    <Typography className="inline" variant="subtitle2">
                      {formattedExperience}
                    </Typography>
                  </Typography>
                )}
              </div>
            )}
            <div className="mt-1.5 flex flex-wrap items-center justify-start gap-1.25 text-onSurface-500 sm:gap-0 sm:divide-x">
              {email && (
                <a
                  href={`mailto:${email}`}
                  className="flex items-center space-x-0.5 sm:pr-1"
                >
                  <Mail className="size-2.5" />
                  <Typography variant="smallBody">{email}</Typography>
                </a>
              )}
              {phoneNumber && (
                <>
                  {isXsDown ? (
                    <a
                      href={`tel:+${phoneNumber.code} ${phoneNumber.number}`}
                      className="flex items-center space-x-0.5 sm:px-1"
                    >
                      <Phone className="size-2.5" />
                      <Typography variant="smallBody">
                        +{phoneNumber.code} {phoneNumber.number}
                      </Typography>
                    </a>
                  ) : (
                    // @ts-expect-error react version issues
                    <CopyToClipboard
                      text={`+${phoneNumber.code} ${phoneNumber.number}`}
                      onCopy={() => copyToClipboard()}
                    >
                      <div className="group relative flex cursor-pointer items-center space-x-0.5 sm:px-1">
                        <Phone className="size-2.5" />
                        <Typography variant="smallBody">
                          +{phoneNumber.code} {phoneNumber.number}
                        </Typography>
                        <Typography
                          variant="smallBody"
                          className="absolute inset-x-0 !mx-auto w-fit translate-y-0 rounded-lg bg-black px-1 py-0.5 !text-white opacity-0 transition-all duration-200 group-hover:-translate-y-3.5 group-hover:opacity-100"
                        >
                          {copied ? "Copied!" : "Click to Copy"}
                        </Typography>
                      </div>
                    </CopyToClipboard>
                  )}
                </>
              )}
              {state && country && (
                <div className="flex items-center space-x-0.5 sm:pl-1">
                  <MapPin className="size-2.5" />
                  <Typography variant="smallBody">
                    {[state.name, country.name].join(", ")}
                  </Typography>
                </div>
              )}
            </div>
            {!isPublic && (
              <div
                // className="mt-4.5 flex max-w-[500px] flex-wrap items-center gap-1"
                className={clsx(
                  "mt-4.5 grid grid-cols-2 items-center gap-1 sm:flex  sm:max-w-[auto]",
                  {
                    "sm:grid-cols-4": !resume,
                    "sm:grid-cols-3": resume,
                  }
                )}
              >
                <Button
                  rounded
                  fullWidth={isXsDown}
                  className={PROFILE_ONBOARDING_TARGETS.videoProfile}
                  size="sm"
                  disabled={isPublic && !videoPortfolioURL}
                  onClick={() => {
                    if (videoPortfolioURL || !isPublic)
                      openVideoPortfolioDialog()
                  }}
                >
                  Video Portfolio
                </Button>
                {isAIToolsEnabled && (
                  <Button
                    fullWidth={isXsDown}
                    rounded
                    size="sm"
                    onClick={toggleCoverLetter}
                  >
                    Create Cover Letter
                  </Button>
                )}
                <Button
                  rounded
                  fullWidth={isXsDown}
                  size="sm"
                  className={clsx("sm:!hidden", {
                    "col-span-2": resume && isPGCTLUser,
                  })}
                  onClick={toggleAddDialog}
                >
                  Add Profile Section
                </Button>
                {!isXsDown && (
                  <IconButton
                    onClick={() => {
                      setOpenShare(true)
                    }}
                    variant="filled"
                    className="!hidden !rounded-full sm:!grid"
                    size="sm"
                  >
                    <ShareAndroid fontSize={13} className="me-0.25" />
                  </IconButton>
                )}
              </div>
            )}
            {isPublic && (
              <TabBar
                className="-mb-0.25 mt-4.5"
                activeTab={activeTab}
                tabs={initialTabs}
                onChange={tab => {
                  scrollTo(tab, 60)
                  setActiveTab(tab)
                }}
              />
            )}
          </div>
        </Container>
      </div>
      {/* <div
        className={clsx(
          "duration-[0.5s] sticky top-7 z-navbar grid grid-rows-[0fr] transition-all",
          {
            "grid-rows-[1fr] border-y border-solid border-onSurface-200 bg-white shadow-[0px_6px_15px_0px_rgba(0,0,0,0.05)] ":
              hasVisited,
          }
        )}
      >
        <div className="overflow-hidden">
          <Container
            className={clsx("py-1.25", {
              // this is for 10 column layout
              //   "!grid grid-cols-12 gap-2 sm:[&>div]:col-span-10 sm:[&>div]:col-start-2 [&>div]:col-span-12 [&>div]:!min-w-[auto] xl:[&>div]:!min-w-[1280px]":
              //     isPublic,
            })}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-1">
                <Avatar
                  name={fullName}
                  className="hidden size-6 md:flex"
                  src={picture}
                />

                <Typography className="inline" variant="title3">
                  {fullName}
                </Typography>
              </div>
              {isPublic ? (
                <div className="flex items-center justify-end space-x-1">
                  <Tooltip
                    disabled={!(isPublic && !videoPortfolioURL)}
                    title={`${user.firstName} has not added a video portfolio yet.`}
                  >
                    <span>
                      <Button
                        size="sm"
                        rounded
                        disabled={isPublic && !videoPortfolioURL}
                        onClick={() => {
                          if (videoPortfolioURL || !isPublic)
                            openVideoPortfolioDialog()
                        }}
                      >
                        Video Portfolio
                      </Button>
                    </span>
                  </Tooltip>

                  <IconButton
                    onClick={() => {
                      setOpenShare(true)
                    }}
                    variant="filled"
                    className="!hidden !rounded-full sm:!grid"
                    size="sm"
                  >
                    <ShareAndroid fontSize={13} className="me-0.25" />
                  </IconButton>
                </div>
              ) : (
                <div className="flex items-center justify-end space-x-1">
                  <Button
                    rounded
                    size="sm"
                    className="!hidden sm:!inline-block"
                    onClick={toggleAddDialog}
                  >
                    Add Profile Section
                  </Button>

                  {!isPublic && (
                    <Button
                      rounded
                      size="sm"
                      variant="outlined"
                      color="secondary"
                      className="!border-onSurface-400"
                      onClick={openEditProfile}
                    >
                      Edit Profile
                    </Button>
                  )}
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <IconButton
                        color="secondary"
                        size="sm"
                        className="!rounded-full border-2 border-solid border-onSurface-400"
                      >
                        <MoreVert />
                      </IconButton>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuItem onClick={() => setPreviewMode(true)}>
                        View Profile As School
                      </DropdownMenuItem>
                      {isPGCTLUser && (
                        <DropdownMenuSub>
                          <DropdownMenuSubTrigger>
                            Open Kits
                          </DropdownMenuSubTrigger>
                          <DropdownMenuSubContent>
                            <DropdownMenuItem onClick={() => openKit("resume")}>
                              Resume Kit
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              onClick={() => openKit("jobApplication")}
                            >
                              Job Application Kit
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              onClick={() => openKit("interview")}
                            >
                              Interview Kit
                            </DropdownMenuItem>
                          </DropdownMenuSubContent>
                        </DropdownMenuSub>
                      )}
                      <DropdownMenuItem onClick={toggleChangeCoverStyle}>
                        Choose Cover Style
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => {
                          setOpenShare(true)
                        }}
                        className="block sm:hidden"
                      >
                        Share Profile
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              )}
            </div>
          </Container>
        </div>
      </div> */}

      <JobApplicationKit
        handleClose={() => setKit(null)}
        open={kit === "jobApplication"}
      />
      <InterviewQuestionsKit
        handleClose={() => setKit(null)}
        open={kit === "interview"}
      />
    </>
  )
}

export default Overview
