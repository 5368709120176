import { Button, Tooltip, Typography } from "@suraasa/placebo-ui"
import AboutDialog from "features/Profile/components/About/EditDialog"
import QualificationsDialog from "features/Profile/components/Academics/Qualifications/EditDialog"
import LanguageDialog from "features/Profile/components/Languages/LanguageDialog"
import ResumeDialog from "features/Profile/components/Overview/AddResume"
import ProfileContext from "features/Profile/context"
import { Check, InfoCircle, Xmark } from "iconoir-react"
import { useContext, useState } from "react"

export type CheckListType = { name: string; isCompleted: boolean; key?: string }
type Props = {
  handleOpenPersonalDetailsSheet: () => void
  checkList: CheckListType[]
}

const CheckList = ({ handleOpenPersonalDetailsSheet, checkList }: Props) => {
  const { updateProfile } = useContext(ProfileContext)

  const [qualificationsDialogOpen, toggleQualificationsDialog] = useState(false)
  const [aboutDialogOpen, setAboutDialogOpen] = useState(false)
  const [resumeDialogOpen, setResumeDialogOpen] = useState(false)
  const [languageDialogOpen, setLanguageDialogOpen] = useState(false)
  const toggleLanguageDialog = () => setLanguageDialogOpen(!languageDialogOpen)

  const handleAction = (item: CheckListType) => {
    if ("key" in item) {
      switch (item.key) {
        case "qualifications":
          toggleQualificationsDialog(true)
          return
        case "about":
          setAboutDialogOpen(true)
          return
        case "language":
          toggleLanguageDialog()
          return
        case "resumeUpload":
          setResumeDialogOpen(true)
          return
        default: {
          handleOpenPersonalDetailsSheet()
        }
      }
    } else {
      handleOpenPersonalDetailsSheet()
    }
  }

  return (
    <>
      <AboutDialog
        data={{ careerAspiration: "" }}
        setOpen={setAboutDialogOpen}
        open={aboutDialogOpen}
        onUpdate={() => {
          updateProfile()
        }}
      />
      <QualificationsDialog
        id={null}
        open={qualificationsDialogOpen}
        setOpen={() => toggleQualificationsDialog(false)}
      />
      <LanguageDialog
        open={languageDialogOpen}
        onRequestClose={toggleLanguageDialog}
      />
      <ResumeDialog
        open={resumeDialogOpen}
        onClose={() => setResumeDialogOpen(false)}
      />
      <div className="px-2">
        <Typography className="mb-2" variant="largeBody">
          Looks like your profile is incomplete. Please complete your profile to
          create a cover letter
        </Typography>
        <div className="flex flex-col gap-1">
          {checkList.map((item, index) => {
            if (item.isCompleted) {
              return <Item key={index} label={item.name} isCompleted />
            }
            return (
              <Item
                key={index}
                label={item.name}
                onClick={() => handleAction(item)}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}

const Item = ({
  isCompleted,
  label,
  onClick,
}: {
  label: string
  isCompleted?: boolean
  onClick?: () => void
}) => {
  if (isCompleted) {
    return (
      <div className="flex w-fit items-center">
        <Check width={18} height={18} className="me-1 text-success-500" />
        <Typography variant="largeBody">{label}</Typography>
      </div>
    )
  }
  return (
    <div className="flex flex-row justify-between">
      <button className="flex items-center" onClick={onClick}>
        <Xmark width={18} height={18} className="me-1 text-critical-500" />
        <Typography variant="largeBody" className="me-1">
          {label}
        </Typography>

        <Tooltip content={`${label} not added`}>
          <InfoCircle
            width={16}
            height={16}
            className="me-1 text-onSurface-800"
          />
        </Tooltip>
      </button>
      <Button variant="text" label="Add" onClick={onClick}></Button>
    </div>
  )
}

export default CheckList
