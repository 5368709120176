import { Typography } from "@suraasa/placebo-ui"
import SuraasaLogo from "assets/suraasaLogo.jpg"

type Props = {
  data?: {
    error: Error
    componentStack: string
    eventId: string
    resetError(): void
  }
  errorStatus?: 404
  title?: string
  description?: string
  render?: () => React.ReactNode
}
export default function ErrorPage({
  data,
  errorStatus,
  title = "Page not found!",
  description = "We couldn't find the page you're looking for.",
  render,
}: Props) {
  if (data) console.error(`> Error Captured: ${data.error}`)
  if (errorStatus && errorStatus === 404) {
    return (
      <div className="flex min-h-[calc(100vh_-_65px)] flex-col items-center justify-center bg-gray-50 px-4 text-center">
        <div>
          {/* Logo */}
          <div className="mb-8">
            <img
              src="https://assets.suraasa.com/logos/v2/mini.webp"
              alt="Suraasa"
              width={80}
              height={80}
              className="mx-auto"
            />
          </div>

          {/* Error Message */}
          {render ? (
            <div>{render()}</div>
          ) : (
            <div className="space-y-1">
              <Typography variant="title1">{title}</Typography>
              <Typography className="mx-auto max-w-md text-muted">
                {description}
              </Typography>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="flex h-screen flex-col items-center justify-center p-2">
        <img
          src={SuraasaLogo}
          alt="logo"
          width={70}
          height={70}
          className="mb-2"
        />

        <p>Look like an unexpected error has occurred.</p>
        <p>
          Please try refreshing the page. If the issue persists, write to{" "}
          <a
            className="text-highlight-500 hover:underline"
            href="mailto:care@suraasa.com"
          >
            care@suraasa.com
          </a>
          .
        </p>
        {import.meta.env.MODE === "development" && data && (
          <pre
            className="mt-4 p-2 text-critical-600"
            style={{
              whiteSpace: "pre-wrap",
            }}
          >
            {`${data.error}`}
          </pre>
        )}
      </div>
    </>
  )
}
