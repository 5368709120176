import { toast } from "@suraasa/placebo-ui"
import { useMutation, UseMutationResult, useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import {
  ResumeFillerType,
  ResumeProfileFillerResponse,
} from "api/resources/aiTools/types"
import { context } from "global/Context/context"
import { useFeatureToggle } from "global/FeatureToggleProvider"
import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { isLocalhost } from "utils/constants"

type ResumeProfileFillerContextType = {
  data: ResumeProfileFillerResponse | null
  create: UseMutationResult<never, unknown, void, unknown>
  isQueued: boolean | null
  refetch: () => Promise<any>
  saveChoicesAndReset: UseMutationResult<
    never,
    unknown,
    ResumeFillerType,
    unknown
  >
  isFeatureEnabled: boolean
}
const ResumeProfileFillerContext =
  createContext<ResumeProfileFillerContextType>({
    data: null,
    // @ts-expect-error expected, but safe to do
    create: {},
    // @ts-expect-error expected, but safe to do
    saveChoicesAndReset: {},
    isQueued: null,
    refetch: () => Promise.resolve(),
    poll: () => Promise.resolve(),
  })

export const useResumeProfileFiller = () =>
  useContext(ResumeProfileFillerContext)

export const ResumeProfileFillerProvider = (props: {
  children: React.ReactNode
}) => {
  const { isAuthenticated } = useContext(context)

  const featureToggle = useFeatureToggle()
  const isFeatureEnabled = !(
    featureToggle.aiTools.inactiveTools || []
  ).includes("resume_profile_filler")

  const [startPolling, setStartPolling] = useState(false)

  const retrieve = useQuery({
    enabled: isAuthenticated && isFeatureEnabled,
    queryKey: queries.aiTools.resumeProfileFillerData().queryKey,
    queryFn: () => api.aiTools.resumeFiller.retrieve(),
  })

  const create = useMutation({
    mutationFn: () => api.aiTools.resumeFiller.create(),
    onSuccess: () => {
      retrieve.refetch()
    },
  })

  useEffect(() => {
    if (
      retrieve.data?.status === "Queued" ||
      retrieve.data?.status === "In Progress"
    ) {
      console.log(
        `> Starting resume filler polling. Current status: ${retrieve.data?.status}`
      )
      setStartPolling(true)
    }
  }, [retrieve.data?.status])

  const pollUpdates = useQuery({
    enabled: startPolling && isFeatureEnabled,
    queryKey: queries.aiTools.resumeProfileFillerData().queryKey,
    queryFn: () => api.aiTools.resumeFiller.retrieve(),
    // Refetch every 10 seconds
    refetchInterval: 10 * 1000,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  })

  useEffect(() => {
    if (retrieve.data?.output || retrieve.isError) {
      setStartPolling(false)
    }
  }, [retrieve.isLoading, retrieve.isError, retrieve.data])

  const saveChoicesAndReset = useMutation({
    mutationFn: (responses: ResumeFillerType) =>
      api.aiTools.resumeFiller.update({
        data: {
          userResponse: responses,
        },
        urlParams: {
          id: retrieve?.data?.id || "",
        },
      }),
    onSuccess: () => {
      toast.success("Your profile has been updated!")

      if (isLocalhost || sessionStorage.getItem("debug") === "1") return

      window.location.reload()
    },
  })

  const isQueued = (() => {
    if (retrieve.data?.output || !isFeatureEnabled) return false

    return (
      create.isLoading ||
      ["Queued", "In Progress"].includes(retrieve.data?.status as string)
    )
  })()

  const value: ResumeProfileFillerContextType = useMemo(() => {
    const defaultValue = {
      data: null,
      create,
      isQueued,
      refetch: retrieve.refetch,
      poll: pollUpdates.refetch,
      saveChoicesAndReset,
      isFeatureEnabled,
    }
    if (retrieve.isLoading || retrieve.isError) {
      return defaultValue
    }

    if (retrieve.isSuccess) {
      return {
        ...defaultValue,
        data: retrieve.data,
      }
    }

    return defaultValue
  }, [retrieve, isQueued])

  if (!value) return props.children

  return (
    <ResumeProfileFillerContext.Provider value={value}>
      {props.children}
    </ResumeProfileFillerContext.Provider>
  )
}
