import { IconButton, Typography } from "@suraasa/placebo-ui"
import Section from "features/Profile/components/Section"
import SectionContent from "features/Profile/components/Section/SectionContent"
import SectionHeading from "features/Profile/components/Section/SectionHeading"
import SectionTitle from "features/Profile/components/Section/SectionTitle"
import { Edit, Plus } from "iconoir-react"
import { useContext, useState } from "react"
import { createUseStyles } from "react-jss"
import { pluralize } from "utils/helpers"

import ProfileContext from "../../context"
import LanguageDialog from "./LanguageDialog"
import { getProficiencyLabel } from "./utils"

const useStyles = createUseStyles(theme => ({
  sectionContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
}))

const Languages = () => {
  const classes = useStyles()
  const [openDialog, setOpenDialog] = useState(false)
  const toggle = () => setOpenDialog(!openDialog)

  const {
    languages: { data },
    isPublic,
  } = useContext(ProfileContext)

  return (
    <>
      <LanguageDialog open={openDialog} onRequestClose={toggle} />

      <Section>
        <SectionHeading
          heading={
            <SectionTitle
              actionIcon={
                isPublic ? undefined : (
                  <IconButton onClick={toggle} className="[&>svg]:!size-3">
                    {data.length > 0 ? <Edit /> : <Plus />}
                  </IconButton>
                )
              }
              itemCount={data.length}
              title={pluralize("Language", data.length, { skipCount: true })}
            />
          }
        />

        <SectionContent className={classes.sectionContent} xPadding={2}>
          {data.length > 0 ? (
            data.map(item => (
              <div className="flex flex-col" key={item.id}>
                <Typography className="text-onSurface-800" variant="strong">
                  {item.language.name}
                </Typography>

                <Typography className="text-onSurface-500" variant="smallBody">
                  {getProficiencyLabel(item.proficiency)}
                </Typography>
              </div>
            ))
          ) : (
            <Typography className="text-onSurface-400" variant="smallBody">
              {isPublic
                ? "Looks like there's nothing here."
                : "You haven’t added any languages. Click on + icon to add one."}
            </Typography>
          )}
        </SectionContent>
      </Section>
    </>
  )
}

export default Languages
