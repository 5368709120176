import { cn, toast, Typography } from "@suraasa/placebo-ui"
import Stars from "assets/AITools/stars-gradient.json"
import { useEffect } from "react"
import Lottie from "react-lottie"

import MarkdownRenderer from "../MarkdownRenderer"
type Props = {
  children?: string
  showTitle?: boolean
  isLoading?: boolean
  onClick?: () => void
  className?: string
}

const ToolOutput = ({
  children = "",
  isLoading,
  onClick,
  className,
  showTitle,
}: Props) => {
  // const regexForError = /\[\[__ERROR__\]\]|\{'status':\s*'failed'\}/g
  const regexForError = /\[\[__ERROR__\]\]/
  const errorTokenMatch = children.match(regexForError)

  useEffect(() => {
    if (errorTokenMatch && onClick) {
      onClick()
      toast.error("Failed to regenerate the output. Please try again.")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorTokenMatch])

  return (
    <div className={cn("p-2", className)}>
      {!children && isLoading && (
        <div className="flex flex-row items-center">
          <Typography variant="subtitle2">Generating.....</Typography>
          {/* @ts-expect-error react version issues */}
          <Lottie
            isClickToPauseDisabled
            style={{
              cursor: "default",
              marginLeft: 8,
              width: 24,
              height: 24,
              transform: "scale(1.4)",
            }}
            speed={1.75}
            options={{
              loop: true,
              autoplay: true,
              animationData: Stars,
            }}
          />
        </div>
      )}

      <div>
        <MarkdownRenderer showTitle={showTitle}>{children}</MarkdownRenderer>
      </div>
    </div>
  )
}

export default ToolOutput
