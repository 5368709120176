import { toast } from "@suraasa/placebo-ui"
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import {
  AssignmentForm,
  AssignmentGenerateResponse,
  AssignmentProForm,
  AssignmentProGenerateResponse,
  AssignmentPromptData,
  PreferredPromptData,
  ToolType,
} from "api/resources/aiTools/types"
import { APIResponse } from "api/types"
import { APIError } from "api/utils"
import { getToolName } from "features/AItools/helper"
import {
  StreamFetchProps,
  StreamQuery,
  StreamResponseEnum,
  useStreamQuery,
} from "features/AItools/hooks/useStreamQuery"
import { useEffect, useState } from "react"
import { flushSync } from "react-dom"
import { UseFormSetError } from "react-hook-form"
import { useNavigate, useSearchParams } from "react-router-dom"
import routes from "routes"
import { mapErrors } from "utils/helpers"
import { trackingService } from "utils/tracking"

export type AssignmentReturnType = {
  currentResponseId: number | null
  setCurrentResponseId: (id: number | null) => void
  promptDataId: string | number | null
  setPromptDataId: React.Dispatch<React.SetStateAction<string | number | null>>
  responseIds: number[] | null
  setResponseIds: React.Dispatch<React.SetStateAction<number[] | null>>
  overviewData: AssignmentPromptData | undefined

  userVote: UseMutationResult<
    never,
    unknown,
    {
      reaction: boolean | undefined | null
      isPro: boolean
    },
    unknown
  >
  output: string | undefined
  isLoading: boolean
  stream: Omit<StreamQuery, "refetchAsync"> & {
    refetchAsync: <T>(
      props: Omit<StreamFetchProps<T>, "toolType">
    ) => Promise<void>
  }
  streamPro: Omit<StreamQuery, "refetchAsync"> & {
    refetchAsync: <T>(
      props: Omit<StreamFetchProps<T>, "toolType">
    ) => Promise<void>
  }

  regenerateStream: StreamQuery
  generateTool: UseMutationResult<
    APIResponse<AssignmentGenerateResponse>,
    any,
    {
      data: AssignmentForm
      setError: UseFormSetError<AssignmentForm>
    },
    unknown
  >
  generateProTool: UseMutationResult<
    APIResponse<AssignmentProGenerateResponse>,
    any,
    {
      data: AssignmentProForm
      setError: UseFormSetError<AssignmentProForm>
    },
    unknown
  >
  regenerateResponse: UseMutationResult<
    APIResponse<{
      id: number
    }>,
    unknown,
    {
      reason: string
      isPro: boolean
    },
    unknown
  >
  isPositiveResponse: boolean | null | undefined
  resetState: () => void
  pageLoading: boolean
  onBack: () => void
  handleCancelApi: () => void
  formData: {
    basicForm?: AssignmentForm
    proForm?: AssignmentProForm
  } | null
  preferredPromptData: PreferredPromptData | null
  isError: boolean
}

export const useAssignment = (): AssignmentReturnType => {
  const queryClient = useQueryClient()
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")
  const navigate = useNavigate()

  const [overviewData, setOverviewData] = useState<
    AssignmentPromptData | undefined
  >()
  const [isLoading, setIsLoading] = useState(false)
  const [isPositiveResponse, setIsPositiveResponse] = useState<
    boolean | null | undefined
  >()
  const [currentResponseId, setCurrentResponseId] = useState<number | null>(
    null
  )
  const [promptDataId, setPromptDataId] = useState<string | number | null>(id)
  const [responseIds, setResponseIds] = useState<number[] | null>(null)
  const [formData, setFormData] = useState<{
    basicForm?: AssignmentForm
    proForm?: AssignmentProForm
  } | null>(null)
  const [output, setOutput] = useState<string>("")
  const [pageLoading, setPageLoading] = useState(false)
  const [currentMode, setCurrentMode] = useState<"basic" | "pro" | null>(null)

  const resetState = () => {
    setOutput("")
    setPromptDataId(null)
    setCurrentResponseId(null)
    setIsPositiveResponse(undefined)
    setOverviewData(undefined)
    setResponseIds(null)
  }

  const handleCancelApi = () => {
    if (streamTitle.isLoading) streamTitle.handleCancel()
    if (streamOutput.isLoading) streamOutput.handleCancel()
    if (regenerateStream.isLoading) regenerateStream.handleCancel()

    if (streamTitlePro.isLoading) streamTitlePro.handleCancel()
    if (streamOutputPro.isLoading) streamOutputPro.handleCancel()
    if (regenerateStreamPro.isLoading) regenerateStreamPro.handleCancel()
  }
  const onBack = () => {
    navigate(routes.aiTools.assignment, { replace: true })
    resetState()
    handleCancelApi()
  }
  // This flow is for when user clicks on history item
  // If that is the case then we just fetch the overview and the latest generated output
  useEffect(() => {
    if (id) {
      setPageLoading(true)
      api.aiTools.assignment
        .getPromptData({
          urlParams: {
            id: id!,
          },
        })
        .then(async data => {
          setPromptDataId(id)
          const { responseIds } = data

          if (data.isPro) {
            setCurrentMode("pro")
          } else {
            setCurrentMode("basic")
          }
          if (responseIds) {
            const [latestResponseId] = responseIds
            setCurrentResponseId(latestResponseId)
            setResponseIds(responseIds)
            await getContent(latestResponseId)
          }
          setOverviewData(data)
          setPageLoading(false)
        })
        .catch(error => {
          console.error("Error fetching prompt data:", error)
          setPageLoading(false)
        })
    }
  }, [id])

  const overviewAPI = async (id: string | number | null) => {
    const res = await api.aiTools.assignment.getPromptData({
      urlParams: {
        id: id!,
      },
    })
    if (res instanceof APIError) {
      toast.error("Prompt failed to generate")
      return
    }

    const { responseIds } = res
    if (res.isPro) {
      setCurrentMode("pro")
    } else {
      setCurrentMode("basic")
    }

    if (responseIds) {
      const [latestResponseId] = responseIds
      setCurrentResponseId(latestResponseId)
      setResponseIds(responseIds)
    }

    setOverviewData(res)
  }

  const generateTool = useMutation({
    mutationFn: async ({
      data,
    }: {
      data: AssignmentForm
      setError: UseFormSetError<AssignmentForm>
    }) => {
      setFormData(prevState => {
        if (!prevState) {
          return { basicForm: data }
        }

        return { ...prevState, basicForm: data }
      })
      setCurrentMode("basic")
      return api.aiTools.assignment.generate({ data })
    },
    onSuccess: async res => {
      const { responseId, id } = res

      flushSync(() => {
        setCurrentResponseId(responseId)
        setPromptDataId(id)
        setOverviewData({
          id: res.id,
          rubricScale: res.rubricScale,
          curriculum: res.curriculum,
          grade: res.grade,
          subject: res.subject,
          title: `New ${getToolName(ToolType.assignment)}`,
          isPro: false,
        })
      })

      await streamOutput.refetchAsync({
        currentResponseId: responseId,
      })
      await streamTitle.refetchAsync({
        currentResponseId: responseId,
      })
      await overviewAPI(id)
      queryClient.refetchQueries({
        queryKey: queries.aiTools.listHistory(ToolType.assignment).queryKey,
        type: "active",
        exact: true,
      })
    },
    onError: (err: any, { setError }) => {
      if (err instanceof APIError) {
        mapErrors(setError, err, [
          ["grade"],
          ["rubricScale"],
          ["subject"],
          ["curriculum"],
          ["assignmentObjectives"],
        ])
      }
    },
  })
  const generateProTool = useMutation({
    mutationFn: async ({
      data,
    }: {
      data: AssignmentProForm
      setError: UseFormSetError<AssignmentProForm>
    }) => {
      setFormData(prevState => {
        if (!prevState) {
          return { proForm: data }
        }

        return { ...prevState, proForm: data }
      })
      setCurrentMode("pro")
      return api.aiTools.assignment.generatePro({ data })
    },
    onSuccess: async res => {
      const { responseId, id } = res

      flushSync(() => {
        setCurrentResponseId(responseId)
        setPromptDataId(id)
        setOverviewData({
          id: res.id,
          subject: res.subject,
          curriculum: res.curriculum,
          grade: res.grade,
          rubricScale: res.rubricScale,
          book: res.book,
          topic: res.topic,

          title: `New ${getToolName(ToolType.assignmentPro)}`,
          isPro: true,
        })
      })

      await streamOutputPro.refetchAsync({
        currentResponseId: responseId,
      })
      await streamTitlePro.refetchAsync({
        currentResponseId: responseId,
      })
      await overviewAPI(id)
      queryClient.refetchQueries({
        queryKey: queries.aiTools.listHistory(ToolType.assignment).queryKey,
        type: "active",
        exact: true,
      })
    },
    onError: (err: any, { setError }) => {
      if (err instanceof APIError) {
        mapErrors(setError, err, [
          ["grade"],
          ["rubricScale"],
          ["subject"],
          ["curriculum"],
          ["assignmentObjectives"],
          ["topic"],
          ["book"],
        ])
      }
    },
  })

  const streamTitle = useStreamQuery({
    responseType: StreamResponseEnum.title,
    toolType: ToolType.assignment,
  })

  const streamOutput = useStreamQuery({
    responseType: StreamResponseEnum.generate,
    toolType: ToolType.assignment,
    onError: () => {
      toast.error("Generation Failed")
    },
    onStreaming(chunks) {
      setOutput(prev => (prev += chunks))
    },
  })

  const regenerateStream = useStreamQuery({
    responseType: StreamResponseEnum.regenerate,
    toolType: ToolType.assignment,
    onSuccess: () => {
      toast.success("Re-generated Successfully")
    },
    onError: () => {
      toast.error("Re-generation Failed")
    },
    onStreaming(chunks) {
      setOutput(prev => (prev += chunks))
    },
  })

  const streamTitlePro = useStreamQuery({
    responseType: StreamResponseEnum.title,
    toolType: ToolType.assignmentPro,
  })

  const streamOutputPro = useStreamQuery({
    responseType: StreamResponseEnum.generate,
    toolType: ToolType.assignmentPro,
    onError: () => {
      toast.error("Generation Failed")
    },
    onStreaming(chunks) {
      setOutput(prev => (prev += chunks))
    },
  })

  const regenerateStreamPro = useStreamQuery({
    responseType: StreamResponseEnum.regenerate,
    toolType: ToolType.assignmentPro,
    onSuccess: () => {
      toast.success("Re-generated Successfully")
    },
    onError: () => {
      toast.error("Re-generation Failed")
    },
    onStreaming(chunks) {
      setOutput(prev => (prev += chunks))
    },
  })

  const userVote = useMutation({
    mutationFn: async ({
      reaction,
      isPro,
    }: {
      reaction: boolean | undefined | null
      isPro: boolean
    }) => {
      const eventName = reaction
        ? "ai_tool_output_upvoted"
        : "ai_tool_output_downvoted"
      const toolType = isPro ? ToolType.rubricsPro : ToolType.rubrics

      trackingService.trackEvent(eventName, {
        tool_type: toolType,
      })

      return api.aiTools.assignment.updateData({
        urlParams: { id: currentResponseId!, type: toolType },
        data: {
          isPositiveResponse: reaction,
        },
      })
    },
  })

  const getContent = async (id: number) => {
    // resetStream()
    setOutput("")
    setIsLoading(true)
    const res = await api.aiTools.assignment.retrieveContent({
      urlParams: {
        id: id,
      },
    })
    if (res instanceof APIError) {
      toast.error(res.message || "Something went wrong")
      setIsLoading(false)
      return
    }

    setIsPositiveResponse(res.isPositiveResponse)
    setOutput(res.output)
    setIsLoading(false)
    // resetStream()
  }

  const regenerateResponse = useMutation({
    mutationFn: ({ reason }: { reason: string; isPro: boolean }) =>
      api.aiTools.assignment.regenerateResponse({
        urlParams: {
          id: currentResponseId!,
        },
        data: {
          regenerateInstruction: reason,
        },
      }),
    onSuccess: async (res, { isPro, reason }) => {
      flushSync(() => {
        setCurrentResponseId(res.id)
        setOutput("")
      })

      if (isPro) {
        await regenerateStreamPro.refetchAsync({
          currentResponseId: res.id,
          regenReason: reason,
        })
      } else {
        await regenerateStream.refetchAsync({
          currentResponseId: res.id,
          regenReason: reason,
        })
      }

      await overviewAPI(id || promptDataId)
    },
    onError: err => {
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) >= 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
      } else {
        toast.error("We're unable to process your request", {
          description: "Please try again later",
        })
      }
    },
  })

  const preferredPromptData = useQuery({
    queryFn: () => api.aiTools.retrievePreferredPromptData(),
    queryKey: ["preferredPromptData"],
  })

  return {
    promptDataId,
    generateTool,
    setPromptDataId,
    currentResponseId,
    setCurrentResponseId: id => {
      setCurrentResponseId(id)
      if (id) {
        getContent(id)
      }
    },
    responseIds,
    setResponseIds,
    stream: {
      ...streamOutput,
      refetchAsync: ({ currentResponseId: id }) =>
        streamOutput.refetchAsync({
          currentResponseId: id,
        }),
    },
    streamPro: {
      ...streamOutputPro,
      refetchAsync: ({ currentResponseId: id }) =>
        streamOutputPro.refetchAsync({
          currentResponseId: id,
        }),
    },

    regenerateResponse,
    overviewData,
    userVote,
    regenerateStream,
    generateProTool,
    output,
    isLoading:
      isLoading ||
      regenerateStream.isLoading ||
      streamOutput.isLoading ||
      regenerateStreamPro.isLoading ||
      streamOutputPro.isLoading,
    isPositiveResponse,
    resetState,
    pageLoading,
    onBack,
    handleCancelApi,
    formData,
    preferredPromptData: preferredPromptData.data ?? null,
    isError:
      currentMode === "pro" ? streamOutputPro.isError : streamOutput.isError,
  }
}
