import { Sheet, SheetContent } from "@suraasa/placebo-ui"
import React, { useContext, useEffect, useState } from "react"
import { trackingService } from "utils/tracking"

import ProfileContext from "../context"
import { Steps } from "./config"
import Introduction from "./Introduction"
import Upload from "./Upload"
import View from "./View"

type Props = {
  toggleOpenSheet: (open: boolean) => void
  openSheet: boolean
  source?: string
}

const VideoPortfolio = ({ toggleOpenSheet, openSheet, source }: Props) => {
  const [step, setStep] = useState<Steps>(Steps.INTRO)
  const { isPublic, videoPortfolioURL } = useContext(ProfileContext)
  const [localURL, setLocalURL] = useState<string | null>(null)

  useEffect(() => {
    if (videoPortfolioURL.data) {
      setStep(Steps.VIEW)
    } else {
      setStep(Steps.INTRO)
    }
  }, [videoPortfolioURL.data])

  return (
    <>
      <Sheet open={openSheet} onOpenChange={toggleOpenSheet}>
        <SheetContent side="bottom" height={90}>
          {step === Steps.INTRO && !isPublic && (
            <Introduction
              onContinue={() => {
                setStep(Steps.UPLOAD)
              }}
            />
          )}
          {step === Steps.UPLOAD && !isPublic && (
            <Upload
              onUpload={(localURL: string) => {
                trackingService.trackEvent("profile_video_portfolio_uploaded", {
                  source,
                })
                setLocalURL(localURL)
                videoPortfolioURL.refetch()
              }}
            />
          )}
          {step === Steps.VIEW && (
            <View
              videoURL={localURL ?? videoPortfolioURL.data}
              handleUpdate={() => setStep(Steps.UPLOAD)}
              onDelete={() => {
                videoPortfolioURL.refetch()
                toggleOpenSheet(false)
              }}
            />
          )}
        </SheetContent>
      </Sheet>
    </>
  )
}

export default VideoPortfolio
