import { createQueryKeys } from "@lukemorales/query-key-factory"

export default createQueryKeys("profile", {
  listMasterclassCertificates: (userName?: string) => ({
    queryKey: ["listMasterclassCertificates", userName],
  }),
  skills: (userName?: string) => ({
    queryKey: ["skills", userName],
  }),
  references: () => ({
    queryKey: ["references"],
  }),
  skillEvidences: (userName?: string) => ({
    queryKey: ["skillEvidences", userName],
  }),
  profileStrengthCriteria: () => ({
    queryKey: ["profileStrengthCriteria"],
  }),
  profileStrength: (userId?: string) => ({
    queryKey: ["profileStrength", userId],
  }),
  videoPortfolio: (userId?: string) => ({
    queryKey: ["videoPortfolio", userId],
  }),
  listInterests: (userName?: string) => ({
    queryKey: ["listInterests", userName],
  }),
  listLanguage: (userName?: string) => ({
    queryKey: ["listLanguage", userName],
  }),
  listAchievements: (userName?: string) => ({
    queryKey: ["listAchievements", userName],
  }),
  listWorkExperiences: (userName?: string) => ({
    queryKey: ["listWorkExperiences", userName],
  }),
  listAcademics: (userName?: string) => ({
    queryKey: ["listAcademics", userName],
  }),
  profileData: (userName?: string) => ({
    queryKey: ["profileData", userName],
  }),
  retrieve: () => ({
    queryKey: ["retrieve"],
  }),
  learningCertificatesList: () => ({
    queryKey: ["learningCertificatesList"],
  }),
  itoCertificatesList: () => ({
    queryKey: ["itoCertificatesList"],
  }),
  masterclassCertificatesList: () => ({
    queryKey: ["masterclassCertificatesList"],
  }),
  certificateExistence: (username, itemType, itemSlug) => ({
    queryKey: ["certificateExistence", username, itemType, itemSlug],
  }),
  getItemCertificate: courseId => ({
    queryKey: ["getItemCertificate", courseId],
  }),
  skillEvidenceList: () => ({
    queryKey: ["skillEvidenceList"],
  }),
  skillsSuggestions: () => ({
    queryKey: ["skillSuggestions"],
  }),
  referenceAnalytics: userId => ({
    queryKey: ["referenceAnalytics", userId],
  }),
})
