import { cn, IconButton, Tooltip, Typography } from "@suraasa/placebo-ui"
import AddCertification from "features/Profile/components/Academics/Certifications/EditDialog"
import AddQualification from "features/Profile/components/Academics/Qualifications/EditDialog"
import AddWorkExperience from "features/Profile/components/WorkExperience/EditDialog"
import ProfileContext from "features/Profile/context"
import PersonalDetailsSheet from "features/Profile/Edit/PersonalDetailsSheet"
import { PROFILE_ONBOARDING_TARGETS } from "features/Profile/ProfileOnboarding/constants"
import VideoPortfolio from "features/Profile/VideoPortfolio"
import { useFeatureToggle } from "global/FeatureToggleProvider"
import { InfoCircle, NavArrowLeft, NavArrowRight } from "iconoir-react"
import { useContext } from "react"
import { isLocalhost } from "utils/constants"
import { getPlatformURL } from "utils/helpers"

import {
  Carousel,
  CarouselContent,
  CarouselNext,
  CarouselPrevious,
} from "@/components/Carousel"

import EditDialog from "../About/EditDialog"
import LanguageDialog from "../Languages/LanguageDialog"
import AddResume from "../Overview/AddResume"
import SubjectsDialog from "../Subjects/SubjectsDialog"
import WorkLocationDialog from "../WorkLocationInterests/WorkLocationDialog"
import ActionCard from "./ActionCard"
import { Badge, DashedLine } from "./assets"
import ProfileStrengthMeter from "./ProfileStrengthMeter"
import {
  ActionDialogNameEnum,
  getLevelName,
  useProfileStrength,
} from "./useProfileStrength"

const landingPageURL = getPlatformURL("suraasa", "/profile-strength")
const cardCss = "p-2 sm:p-3"

const ProfileStrength = () => {
  const {
    stepsToAdvance,
    progress,
    currentLevel,
    dialog,
    hasIncompleteRequiredActions,
    isProfileStrengthLoading,
    hasProfileStrengthBadge,
  } = useProfileStrength()

  const profile = useContext(ProfileContext)

  const featureToggle = useFeatureToggle()

  if (!featureToggle.profileStrength.isEnabled) {
    return null
  }

  return (
    <>
      <section
        className={cn(
          PROFILE_ONBOARDING_TARGETS.profileStrength,
          "flex flex-col border border-onSurface-200 rounded-lg overflow-hidden"
        )}
      >
        {!hasProfileStrengthBadge && (
          <>
            <div
              className={cn(
                cardCss,
                "bg-onSurface-100 flex flex-col sm:flex-row gap-2 items-center"
              )}
            >
              <Badge className="size-[52px]" isColored />
              <div>
                <Typography variant="title3">
                  Claim your Strength Badge
                </Typography>
                <Typography className="text-onSurface-500">
                  Increase your profile strength to to claim your Strength Badge
                </Typography>
              </div>
            </div>
            <DashedLine />
          </>
        )}
        <div className={cn(cardCss, "bg-onSurface-100")}>
          <div className="flex items-center gap-0.5 text-onSurface-500">
            <Typography variant="preTitle">Profile Strength</Typography>
            <Tooltip
              content={
                <a href={landingPageURL} target="_blank" rel="noreferrer">
                  Click here to see how Profile Strength works
                </a>
              }
            >
              <a href={landingPageURL} target="_blank" rel="noreferrer">
                <InfoCircle className="size-2.5" />
              </a>
            </Tooltip>
          </div>
          <Typography variant="strong" className="my-1">
            {getLevelName(currentLevel)} {isLocalhost ? `(${progress})%` : ""}
          </Typography>

          <ProfileStrengthMeter
            isLoading={isProfileStrengthLoading}
            level={currentLevel}
            progress={progress}
            hasIncompleteRequiredActions={hasIncompleteRequiredActions}
          />
        </div>
        {stepsToAdvance.length > 0 && (
          <Carousel>
            <div
              className={cn(
                "mb-1.5 flex items-center justify-between !pb-0",
                cardCss
              )}
            >
              <Typography variant="subtitle2" className="text-onSurface-500">
                {hasProfileStrengthBadge
                  ? "Recommended ways to maintain your profile strength"
                  : "Recommended ways to boost your strength"}
              </Typography>
              {stepsToAdvance.length > 1 && (
                <div className="flex items-center gap-0.5">
                  <CarouselPrevious
                    render={props => (
                      <IconButton
                        variant="plain"
                        disabled={!props.canScrollPrev}
                        onClick={props.scrollPrev}
                        color="black"
                      >
                        <NavArrowLeft />
                      </IconButton>
                    )}
                  />

                  <CarouselPrevious
                    render={props => {
                      const index = props.api?.selectedScrollSnap()
                      if (typeof index !== "number") return null
                      return (
                        <Typography
                          variant="preTitle"
                          className="shrink-0 grow text-end text-onSurface-400"
                        >
                          {index + 1} of {stepsToAdvance.length}
                        </Typography>
                      )
                    }}
                  />

                  <CarouselNext
                    render={props => (
                      <IconButton
                        variant="plain"
                        disabled={!props.canScrollNext}
                        onClick={props.scrollNext}
                        color="black"
                      >
                        <NavArrowRight />
                      </IconButton>
                    )}
                  />
                </div>
              )}
            </div>

            <CarouselContent className="m-0">
              {stepsToAdvance.map(item => (
                <ActionCard
                  containerClassName="p-2 sm:p-3 sm:pt-1"
                  key={`${item.title}-${String(item.isDone)}`}
                  title={item.title}
                  isRequired={item.isRequired}
                  action={item.action}
                />
              ))}
            </CarouselContent>
          </Carousel>
        )}
      </section>

      {/* Action Dialogs */}
      <WorkLocationDialog
        open={dialog.action === ActionDialogNameEnum.WORK_INTEREST}
        source="profile-strength-action-card"
        onRequestClose={dialog.close}
      />
      <SubjectsDialog
        open={dialog.action === ActionDialogNameEnum.SUBJECTS}
        source="profile-strength-action-card"
        onRequestClose={dialog.close}
      />
      <VideoPortfolio
        openSheet={dialog.action === ActionDialogNameEnum.VIDEO_PORTFOLIO}
        source="profile-strength-action-card"
        toggleOpenSheet={dialog.close}
      />
      <AddResume
        open={dialog.action === ActionDialogNameEnum.RESUME}
        source="profile-strength-action-card"
        onClose={dialog.close}
      />
      <PersonalDetailsSheet
        openSheet={dialog.action === ActionDialogNameEnum.PROFILE}
        source="profile-strength-action-card"
        setOpenSheet={dialog.close}
      />
      <EditDialog
        data={{ careerAspiration: profile.profile.careerAspiration || "" }}
        open={dialog.action === ActionDialogNameEnum.CAREER_ASPIRATION}
        source="profile-strength-action-card"
        setOpen={dialog.close}
        onUpdate={() => {
          profile.updateProfile()
        }}
      />
      <LanguageDialog
        open={dialog.action === ActionDialogNameEnum.LANGUAGE}
        source="profile-strength-action-card"
        onRequestClose={dialog.close}
      />
      <AddWorkExperience
        onUpdate={() => {}}
        id={null}
        open={dialog.action === ActionDialogNameEnum.WORK_EXPERIENCE}
        source="profile-strength-action-card"
        handleClose={dialog.close}
      />
      <AddQualification
        id={null}
        open={dialog.action === ActionDialogNameEnum.QUALIFICATION}
        source="profile-strength-action-card"
        setOpen={dialog.close}
      />
      <AddCertification
        id={null}
        open={dialog.action === ActionDialogNameEnum.CERTIFICATION}
        source="profile-strength-action-card"
        setOpen={dialog.close}
      />
      <AddCertification
        id={
          profile.academics.certifications?.data
            .filter(x => !x.isVerified)
            .find(item => item.evidences?.length === 0)?.id || null
        }
        open={dialog.action === ActionDialogNameEnum.CERTIFICATION_EVIDENCE}
        source="profile-strength-action-card"
        setOpen={dialog.close}
      />
      <AddQualification
        id={
          profile.academics.qualifications?.data
            .filter(x => !x.isVerified)
            .find(item => item.evidences?.length === 0)?.id || null
        }
        open={dialog.action === ActionDialogNameEnum.QUALIFICATION_EVIDENCE}
        source="profile-strength-action-card"
        setOpen={dialog.close}
      />
    </>
  )
}

export default ProfileStrength
