import { toast } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import api from "api"
import { Email, OTPResponse } from "api/resources/settings/types"
import { APIError } from "api/utils"
import AsyncBuilder from "components/AsyncBuilder"
import { context } from "global/Context/context"
import { useContext, useState } from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-hook"
import { CaptchaType } from "utils/hooks/useGRecaptcha"

import VerifyEmail from "../VerifyEmail"

const VerifyEmailButton = () => {
  const { emails, updateEmails } = useContext(context)
  const [openVerifyEmailDialog, setOpenVerifyEmailDialog] = useState(false)
  const [otpData, setOtpData] = useState<OTPResponse>()

  const primaryEmail = emails.find(email => email.isPrimary)

  const { executeGoogleReCaptcha } = useGoogleReCaptcha(
    import.meta.env.VITE_INVISIBLE_RECAPTCHA_KEY,
    {
      enterprise: true,
    }
  )

  const sendOTP = async () => {
    try {
      if (primaryEmail) {
        const captcha = {
          value: await executeGoogleReCaptcha("auth"),
          type: CaptchaType.invisible,
        }
        const res = await api.settings.emails.create({
          data: { email: primaryEmail.email, captcha },
        })
        setOtpData(res)
        setOpenVerifyEmailDialog(true)
      }
    } catch (err) {
      console.error(err)
      if (err instanceof APIError) {
        if (err.errors.fieldErrors?.invalidCaptcha) {
          toast.error("Verification failed, please try again later")
        }
      }
    }
  }

  const onVerification = (newEmailData: Email) => {
    const tempEmails = emails.filter(
      email => email.email !== newEmailData.email
    )
    updateEmails([...tempEmails, newEmailData])
  }

  if (primaryEmail)
    return (
      <div>
        {otpData && (
          <VerifyEmail
            dialogTitle="Verify Primary Email"
            close={() => {
              setOpenVerifyEmailDialog(false)
            }}
            email={primaryEmail.email}
            otpData={otpData}
            open={openVerifyEmailDialog && Boolean(otpData)}
            onVerify={updatedEmail => {
              if (otpData) onVerification(updatedEmail)
              toast.success("Email Verified successfully")
              setOpenVerifyEmailDialog(false)
            }}
          />
        )}
        <AsyncBuilder
          handler={sendOTP}
          render={({ loading, onClick }) => (
            <Button variant="text" onClick={onClick} loading={loading}>
              Verify Email
            </Button>
          )}
        />
      </div>
    )
  return null
}

export default VerifyEmailButton
