import CurrentLocation from "features/SignupOnboarding/Screens/CurrentLocation"
import CurrentWorkExperience from "features/SignupOnboarding/Screens/CurrentWorkExperience"
import DateOfBirth from "features/SignupOnboarding/Screens/DateOfBirth"
import DirectingToMoreQuestions from "features/SignupOnboarding/Screens/DirectingToMoreQuestions"
import ExperienceInTeaching from "features/SignupOnboarding/Screens/ExperienceInTeaching"
import Location from "features/SignupOnboarding/Screens/Location"
import ProfilePicture from "features/SignupOnboarding/Screens/ProfilePicture"
import SeekingJob from "features/SignupOnboarding/Screens/SeekingJob"
import { AfterSignUpData } from "features/SignupOnboarding/types"
import { signupSources } from "utils/constants"
import { trackingService } from "utils/tracking"

import CurrentlyTeachingInSchool from "../../Screens/CurrentlyTeachingInSchool/index"
import CurriculumInformation from "../../Screens/CurriculumInformation"
import DesiredOutcome, {
  desiredOutcomeOption,
} from "../../Screens/DesiredOutcome/index"
import GradesTheyTeach from "../../Screens/GradesTheyTeach"
import SubjectsTheyTeach from "../../Screens/SubjectsTheyTeach/index"
import YearsOfExperience from "../../Screens/YearsOfExperience"

enum OnboardingStepId {
  CURRENTLY_TEACHING_IN_SCHOOL = "currentlyTeachingInSchool",
  YEARS_OF_EXPERIENCE = "yearsOfExperience",
  WORK_EXPERIENCE = "workExperience",
  SUBJECTS_THEY_TEACH = "subjectsTheyTeach",
  GRADES_THEY_TEACH = "gradesTheyTeach",
  CURRICULUM_INFORMATION = "curriculumInformation",
  EXPERIENCE_IN_TEACHING = "experienceInTeaching",
  DESIRED_OUTCOME = "desiredOutcome",
  DIRECTING_TO_MORE_QUESTIONS = "directingToMoreQuestions",
  SEEKING_JOB = "seekingJob",
  LOCATION = "location",
  DATE_OF_BIRTH = "dateOfBirth",
  CURRENT_LOCATION = "currentLocation",
  PROFILE_PICTURE = "profilePicture",
}

export interface OnboardingStep {
  id: OnboardingStepId
  component: React.ComponentType<any>
}

interface OnboardingFlowManagerProps {
  data: AfterSignUpData
  source?: string | null
}

function OnboardingStep(
  id: OnboardingStepId,
  Component: React.ComponentType<any>
): OnboardingStep {
  return { id, component: Component }
}

export class OnboardingFlowManager {
  data: AfterSignUpData
  source?: string | null

  constructor(props: OnboardingFlowManagerProps) {
    this.data = props.data
    this.source = props.source || trackingService.getSignupSource()
  }

  getFlow(): OnboardingStep[] {
    const flow: OnboardingStep[] = []
    const cameFromTalkToAMentor = this.source === signupSources.talkToAMentor

    if (cameFromTalkToAMentor) return this.getTTMFlow()

    flow.push(
      OnboardingStep(
        OnboardingStepId.CURRENTLY_TEACHING_IN_SCHOOL,
        CurrentlyTeachingInSchool
      )
    )

    if (this.data.isTeachingInSchool) {
      flow.push(
        OnboardingStep(OnboardingStepId.YEARS_OF_EXPERIENCE, YearsOfExperience),
        OnboardingStep(OnboardingStepId.WORK_EXPERIENCE, CurrentWorkExperience),
        OnboardingStep(OnboardingStepId.SUBJECTS_THEY_TEACH, SubjectsTheyTeach),
        OnboardingStep(
          OnboardingStepId.CURRICULUM_INFORMATION,
          CurriculumInformation
        ),
        OnboardingStep(OnboardingStepId.GRADES_THEY_TEACH, GradesTheyTeach)
      )
    } else {
      flow.push(
        OnboardingStep(
          OnboardingStepId.EXPERIENCE_IN_TEACHING,
          ExperienceInTeaching
        )
      )

      if (this.data.hasExperienceInTeaching) {
        flow.push(
          OnboardingStep(
            OnboardingStepId.YEARS_OF_EXPERIENCE,
            YearsOfExperience
          ),
          OnboardingStep(
            OnboardingStepId.SUBJECTS_THEY_TEACH,
            SubjectsTheyTeach
          ),
          OnboardingStep(
            OnboardingStepId.CURRICULUM_INFORMATION,
            CurriculumInformation
          ),
          OnboardingStep(OnboardingStepId.GRADES_THEY_TEACH, GradesTheyTeach)
        )
      } else {
        flow.push(
          OnboardingStep(
            OnboardingStepId.SUBJECTS_THEY_TEACH,
            SubjectsTheyTeach
          ),
          OnboardingStep(OnboardingStepId.GRADES_THEY_TEACH, GradesTheyTeach)
        )
      }
    }

    flow.push(OnboardingStep(OnboardingStepId.DESIRED_OUTCOME, DesiredOutcome))

    if (this.data.desiredOutcome.includes(desiredOutcomeOption[2])) {
      flow.push(
        OnboardingStep(
          OnboardingStepId.DIRECTING_TO_MORE_QUESTIONS,
          DirectingToMoreQuestions
        ),
        OnboardingStep(OnboardingStepId.SEEKING_JOB, SeekingJob)
      )

      if (this.data.seekingJob) {
        flow.push(OnboardingStep(OnboardingStepId.LOCATION, Location))
      }
    }

    flow.push(
      OnboardingStep(OnboardingStepId.DATE_OF_BIRTH, DateOfBirth),
      OnboardingStep(OnboardingStepId.CURRENT_LOCATION, CurrentLocation),
      OnboardingStep(OnboardingStepId.PROFILE_PICTURE, ProfilePicture)
    )

    return flow
  }

  getTTMFlow(): OnboardingStep[] {
    const flow: OnboardingStep[] = []

    flow.push(
      OnboardingStep(
        OnboardingStepId.CURRENTLY_TEACHING_IN_SCHOOL,
        CurrentlyTeachingInSchool
      )
    )

    if (this.data.isTeachingInSchool) {
      flow.push(
        OnboardingStep(OnboardingStepId.YEARS_OF_EXPERIENCE, YearsOfExperience),
        OnboardingStep(OnboardingStepId.WORK_EXPERIENCE, CurrentWorkExperience),
        OnboardingStep(OnboardingStepId.SUBJECTS_THEY_TEACH, SubjectsTheyTeach),
        OnboardingStep(OnboardingStepId.GRADES_THEY_TEACH, GradesTheyTeach)
      )
    } else {
      if (this.data.hasExperienceInTeaching) {
        flow.push(
          OnboardingStep(
            OnboardingStepId.YEARS_OF_EXPERIENCE,
            YearsOfExperience
          ),
          OnboardingStep(
            OnboardingStepId.SUBJECTS_THEY_TEACH,
            SubjectsTheyTeach
          ),
          OnboardingStep(OnboardingStepId.GRADES_THEY_TEACH, GradesTheyTeach)
        )
      } else {
        flow.push(
          OnboardingStep(OnboardingStepId.GRADES_THEY_TEACH, GradesTheyTeach)
        )
      }
    }

    flow.push(OnboardingStep(OnboardingStepId.DESIRED_OUTCOME, DesiredOutcome))

    if (this.data.desiredOutcome.includes(desiredOutcomeOption[2])) {
      flow.push(
        OnboardingStep(
          OnboardingStepId.DIRECTING_TO_MORE_QUESTIONS,
          DirectingToMoreQuestions
        ),
        OnboardingStep(OnboardingStepId.SEEKING_JOB, SeekingJob)
      )

      if (this.data.seekingJob) {
        flow.push(OnboardingStep(OnboardingStepId.LOCATION, Location))
      }
    }

    flow.push(
      OnboardingStep(OnboardingStepId.DATE_OF_BIRTH, DateOfBirth),
      OnboardingStep(OnboardingStepId.PROFILE_PICTURE, ProfilePicture)
    )

    return flow
  }

  getFirstIncompleteStep(flow: OnboardingStep[]): number {
    for (let i = 0; i < flow.length; i++) {
      switch (flow[i].id) {
        case OnboardingStepId.CURRENTLY_TEACHING_IN_SCHOOL:
          if (this.data.isTeachingInSchool === null) return i
          break
        case OnboardingStepId.YEARS_OF_EXPERIENCE:
          if (this.data.yearsOfExperience === null) return i
          break
        case OnboardingStepId.SUBJECTS_THEY_TEACH:
          if (this.data.subjects.length === 0) return i
          break
        case OnboardingStepId.GRADES_THEY_TEACH:
          if (
            this.data.gradesTaught.length === 0 &&
            this.data.gradeInterests.length === 0
          )
            return i
          break
        case OnboardingStepId.DIRECTING_TO_MORE_QUESTIONS:
          continue
        case OnboardingStepId.CURRICULUM_INFORMATION:
          if (this.data.curricula.length === 0) return i
          break
        case OnboardingStepId.EXPERIENCE_IN_TEACHING:
          if (this.data.hasExperienceInTeaching === null) return i
          break
        case OnboardingStepId.DESIRED_OUTCOME:
          if (this.data.desiredOutcome.length === 0) return i
          break
        case OnboardingStepId.SEEKING_JOB:
          if (this.data.seekingJob === null) return i
          break
        case OnboardingStepId.WORK_EXPERIENCE:
          if (this.data.workExperienceData === null) return i
          break
        case OnboardingStepId.LOCATION:
          if (
            this.data.jobLocationPreference.city === null &&
            this.data.jobLocationPreference.state === null &&
            this.data.jobLocationPreference.country === null
          )
            return i
          break
        case OnboardingStepId.DATE_OF_BIRTH:
          if (this.data.dateOfBirth === null) return i
          break
        case OnboardingStepId.CURRENT_LOCATION:
          if (
            this.data.currentLocation.city === null &&
            this.data.currentLocation.state === null &&
            this.data.currentLocation.country === null
          )
            return i
          break
        case OnboardingStepId.PROFILE_PICTURE:
          if (this.data.profilePicture === null) return i
          break
      }
    }

    return flow.length - 1
  }
}
