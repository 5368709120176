import { Typography } from "@suraasa/placebo-ui"
import api from "api"
import CheckBoxTextContainer from "features/SignupOnboarding/CheckBoxTextContainer"
import { getNavigationPortal } from "features/SignupOnboarding/Navigation/utils"
import { FormPageProps } from "features/SignupOnboarding/types"
import { useState } from "react"

export const desiredOutcomeOption = [
  "Explore Masterclasses",
  "Use Al Teaching Tools",
  /// This option is used in the onboarding flow manager as well,
  /// in order to calculate the flow further
  "Get a Job",
  "Upskill Myself",
]

const DesiredOutcome = ({
  data,
  updateData,
  onNext,
  ...props
}: FormPageProps) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    data.desiredOutcome
  )

  const updateBackend = async (options: string[]) => {
    try {
      await api.profile.updateMiscData({
        data: {
          desired_milestones: options,
        },
      })
    } catch (e) {
      console.error("Failed to update backend:", e)
    }
  }

  const toggleSelection = (selectedOption: string) => {
    const newOptions = selectedOptions.includes(selectedOption)
      ? selectedOptions.filter(option => option !== selectedOption)
      : [...selectedOptions, selectedOption]

    setSelectedOptions(newOptions)
    updateData({
      desiredOutcome: newOptions,
    })
  }

  const handleSubmit = async () => {
    await updateBackend(selectedOptions)
    await onNext()
  }

  const isDisabled = selectedOptions.length === 0

  return (
    <div>
      <Typography variant="title2" color="onSurface.900">
        What would you like Suraasa to help you with?
      </Typography>
      <div className="mt-4 flex w-full max-w-[480px] flex-col gap-1">
        {desiredOutcomeOption.map((option, index) => (
          <CheckBoxTextContainer
            key={index}
            text={option}
            minSize={false}
            isSelected={selectedOptions.includes(option)}
            onClick={() => toggleSelection(option)}
          />
        ))}
      </div>

      {getNavigationPortal({
        disableNext: isDisabled,
        onNext: handleSubmit,
        ...props,
      })}
    </div>
  )
}

export default DesiredOutcome
