export enum ProfileStrengthLevel {
  WEAK = "weak",
  BASIC = "basic",
  STRONG = "strong",
  VERY_STRONG = "very_strong",
  // EXCELLENT = "excellent",
}

export type ProfileStrengthType = {
  userId: string
  levelName: ProfileStrengthLevel
  progress?: number
}

export type ProfileStrengthLevelWiseCriteria = Record<
  ProfileStrengthLevel,
  ProfileStrengthCriteria
>

export type ProfileStrengthCriteria = {
  additionalRequirements: string[]
  preRequisites: string[]
}
