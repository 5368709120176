import { UseQueryResult } from "@tanstack/react-query"
import {
  ProfileStrengthLevelWiseCriteria,
  ProfileStrengthType,
} from "api/resources/profile/profileStrength/types"
import {
  Award,
  Certification,
  Interest,
  LanguageResponse,
  MasterclassCertificate,
  Profile,
  Project,
  Publication,
  Qualification,
  Skill,
  SkillEvidenceOverview,
  SubjectResponse,
  TestScore,
  WorkExperienceType,
  WorkLocationInterest,
} from "api/resources/profile/types"
import { Reference, ReferenceAnalytics } from "api/resources/references/types"
import { APIResponse } from "api/types"
import React from "react"

import { DEFAULT_PROFILE_COVER } from "./utils"

type ProfileItem<T> = {
  data: T[]
  refetch: (updateProfileStrength?: boolean) => void
}

export type ProfileContextType = {
  isPublic: boolean
  profile: Profile

  updateProfile: () => void
  languages: ProfileItem<LanguageResponse>
  workExperiences: ProfileItem<WorkExperienceType>
  subjects: ProfileItem<SubjectResponse>
  achievements: {
    awards: ProfileItem<Award>
    projects: ProfileItem<Project>
    publications: ProfileItem<Publication>
    testScores: ProfileItem<TestScore>
  }
  academics: {
    certifications: ProfileItem<Certification>
    qualifications: ProfileItem<Qualification>
  }
  workLocationInterest: ProfileItem<WorkLocationInterest>
  interests: ProfileItem<Interest>
  skillEvidences: SkillEvidenceOverview
  skills: ProfileItem<Skill>
  masterclasses: MasterclassCertificate[]
  videoPortfolioURL: {
    data: string | null
    refetch: () => void
  }
  loading?: boolean

  // Profile Strength
  profileStrengthCriteria?: ProfileStrengthLevelWiseCriteria
  profileStrength: UseQueryResult<APIResponse<ProfileStrengthType>, unknown>
  isPollingProfileStrength: boolean
  references: ProfileItem<Reference>
  referenceAnalytics: {
    data: ReferenceAnalytics | null
    refetch: () => void
  }
}

const defaultProfileItem = {
  data: [],
  refetch: () => {},
}

export const defaultContextValue: ProfileContextType = {
  isPublic: true,
  updateProfile: () => {},
  profile: {
    id: "",
    totalWorkingExperience: 0,
    coverStyle: DEFAULT_PROFILE_COVER,
    careerAspiration: null,
    countriesWorkedIn: [],
    country: null,
    city: null,
    yearsOfExperience: null,
    currentWorkingExperience: null,
    dateOfBirth: null,
    gender: null,
    isVerified: false,
    lookingForJobs: false,
    nationality: null,
    phoneNumber: null,
    picture: null,
    state: null,
    user: {
      uuid: "",
      email: "",
      firstName: "",
      lastName: null,
      middleName: "",
      username: "",
    },
  },

  languages: defaultProfileItem,
  workExperiences: defaultProfileItem,
  subjects: defaultProfileItem,
  workLocationInterest: defaultProfileItem,
  achievements: {
    awards: defaultProfileItem,
    projects: defaultProfileItem,
    publications: defaultProfileItem,
    testScores: defaultProfileItem,
  },
  academics: {
    certifications: defaultProfileItem,
    qualifications: defaultProfileItem,
  },
  interests: defaultProfileItem,
  skillEvidences: {
    advancedSkillCount: 0,
    beginnerSkillCount: 0,
    intermediateSkillCount: 0,
    professional: 0,
  },
  skills: defaultProfileItem,
  masterclasses: [],
  videoPortfolioURL: {
    data: null,
    refetch: () => {},
  },
  loading: undefined,

  // Profile Strength
  // @ts-expect-error this is safe to write
  profileStrength: {},
  profileStrengthCriteria: undefined,
  isPollingProfileStrength: false,
  references: defaultProfileItem,
  referenceAnalytics: {
    data: null,
    refetch: () => {},
  },
}

const ProfileContext =
  React.createContext<ProfileContextType>(defaultContextValue)

export default ProfileContext
