import { Typography } from "@suraasa/placebo-ui"
import { ProfileStrengthLevel } from "api/resources/profile/profileStrength/types"
import clsx from "clsx"
import React, { useEffect } from "react"

import { Badge } from "../assets"
import LineSpinner from "../LineSpinner"
import styles from "./ProfileStrengthMeter.module.css"

type Props = {
  isLoading: boolean
  level: ProfileStrengthLevel
  progress: number
  hasIncompleteRequiredActions: boolean
}

const Levels = [
  ProfileStrengthLevel.WEAK,
  ProfileStrengthLevel.BASIC,
  ProfileStrengthLevel.STRONG,
  ProfileStrengthLevel.VERY_STRONG,
]

const calculateWidth = (
  ref: React.RefObject<HTMLDivElement>,
  progress: number,
  level: ProfileStrengthLevel,
  hasIncompleteRequiredActions: boolean
) => {
  const currentLevelIndex = Levels.indexOf(level)
  const width = ref.current?.getBoundingClientRect().width || 0

  // console.log("Ref Width", width)

  const cellWidth = width / Levels.length
  const extraWidth =
    (cellWidth / 100) * progress -
    (hasIncompleteRequiredActions && progress === 100 ? 10 : 0)

  const totalFilledWidth = cellWidth * currentLevelIndex + extraWidth

  return {
    width,
    completedCellWidth: cellWidth,
    extraWidth,
    totalFilledWidth,
    padding: (Levels.length - 1) * 1,
  }
}

const ProfileStrengthMeter = ({
  hasIncompleteRequiredActions,
  progress,
  isLoading,
  level,
}: Props) => {
  // Just to show some initial progress to the user
  if (level === ProfileStrengthLevel.WEAK && progress === 0) {
    progress = 5
  }

  const ref = React.useRef<HTMLDivElement>(null)

  const [config, setConfig] = React.useState(
    calculateWidth(ref, progress, level, hasIncompleteRequiredActions)
  )

  const hasBadge =
    level === ProfileStrengthLevel.VERY_STRONG &&
    progress === 100 &&
    !hasIncompleteRequiredActions

  const currentLevelIndex = Levels.indexOf(level)

  useEffect(() => {
    setConfig(
      calculateWidth(ref, progress, level, hasIncompleteRequiredActions)
    )

    window.addEventListener("resize", () => {
      setConfig(
        calculateWidth(ref, progress, level, hasIncompleteRequiredActions)
      )
    })

    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function () {
        setConfig(
          calculateWidth(ref, progress, level, hasIncompleteRequiredActions)
        )
      })
    })

    // This is so that when dialogs are opened, the scrollbar in the DOM is temporarily hidden, which causes width shift in the DOM.
    // So, by recalculating the width, we can adjust the width of the meter accordingly.
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ["style"],
    })

    return () => {
      observer.disconnect()

      window.removeEventListener("resize", () => {
        setConfig(
          calculateWidth(ref, progress, level, hasIncompleteRequiredActions)
        )
      })
    }
  }, [progress, level, hasIncompleteRequiredActions])

  return (
    <div ref={ref}>
      {/* <pre>{JSON.stringify(config, null, 2)}</pre> */}
      <div className="relative h-2.5">
        {/* Shimmer Effect */}
        <div className="absolute left-0 top-0 z-2 h-2.5 w-full">
          <div
            className={clsx(
              "relative block size-full h-2.5 w-full overflow-hidden rounded-md",
              styles.shimmer,
              { hidden: !isLoading }
            )}
            style={
              {
                width: config.totalFilledWidth,
                "--width": `${config.totalFilledWidth}px`,
              } as React.CSSProperties
            }
          />
        </div>

        {/* Unfilled Meter (background) */}
        <div className="space-x-0.5">
          {Levels.map(level => {
            return (
              <span
                className={clsx(
                  "inline-block h-2.5 rounded-md bg-onSurface-300"
                )}
                style={{
                  width: config.completedCellWidth - config.padding,
                }}
                key={level}
              />
            )
          })}
        </div>

        {/* Filled Meter */}
        <div className="absolute left-0 top-0 space-x-0.5">
          {Levels.map((_, index) => {
            const isComplete = index < currentLevelIndex
            const isCurrent = index === currentLevelIndex

            const width = Math.max(
              0,
              isComplete
                ? config.completedCellWidth - config.padding
                : isCurrent
                ? config.extraWidth - config.padding
                : 0
            )

            return (
              <span
                className={clsx(
                  "inline-block h-2.5 rounded-md  transition-all",
                  {
                    border: width > 0,
                  },
                  {
                    "!bg-onSurface-400 !border-onSurface-600": isLoading,
                    "bg-[#41BBE1] border-[#318CA9]":
                      level === ProfileStrengthLevel.WEAK,
                    "bg-[#1B80DC] border-[#1460A5]":
                      level === ProfileStrengthLevel.BASIC,
                    "bg-[#3C25CB] border-[#2D1C98]":
                      level === ProfileStrengthLevel.STRONG,
                    "bg-[#7725CB] border-[#591C98]":
                      level === ProfileStrengthLevel.VERY_STRONG,
                  }
                )}
                style={{
                  width,
                }}
                key={_}
              />
            )
          })}
        </div>

        <div>
          <div className="absolute -right-2 top-1/2 z-2 size-4 -translate-y-1/2">
            <Badge className="size-4" isColored={hasBadge} />
          </div>
        </div>
      </div>

      {isLoading && (
        <div className="mt-1 flex items-center gap-1">
          <LineSpinner size="24px" color="#64748B" stroke="2px" />
          <Typography variant="strongSmallBody" className="text-onSurface-500">
            Updating your profile strength
          </Typography>
        </div>
      )}
    </div>
  )
}

export default ProfileStrengthMeter
