import {
  cn,
  Dialog,
  DialogBody,
  DialogContent,
  DialogHeader,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui"
import AddQualification from "features/Profile/components/Academics/Qualifications/EditDialog"
import AddResume from "features/Profile/components/Overview/AddResume"
import PersonalDetailsSheet from "features/Profile/Edit/PersonalDetailsSheet"
import { Plus, Xmark } from "iconoir-react"
import { useState } from "react"
import { trackElementOnPH } from "utils/tracking"

const source = "profile-tour-suggested-action"

export enum BoostProfileDialogEnum {
  QUALIFICATION = "qualification",
  RESUME = "resume",
  PICTURE = "picture",
}
const BoostProfileDialog = ({
  open,
  onClose,
  dialogBtns,
}: {
  open: boolean
  onClose: () => void
  dialogBtns: {
    title: string
    isDone?: boolean
    dialogKey: BoostProfileDialogEnum
  }[]
}) => {
  const [openDialog, setOpenDialog] = useState<BoostProfileDialogEnum | null>(
    null
  )
  const onOpenDialog = (item: BoostProfileDialogEnum) => {
    setOpenDialog(item)
    onClose()
  }
  const onCloseDialog = () => {
    setOpenDialog(null)
  }

  return (
    <>
      <Dialog open={open}>
        <DialogContent className="max-w-[431px]">
          <DialogHeader hideClose className="hidden"></DialogHeader>
          <DialogBody className="p-3">
            <div className="mb-1.5 flex items-start justify-between gap-2">
              <Typography className="max-w-[345px]" variant="title2">
                Let&apos;s Boost Your Profile Strength
              </Typography>
              <IconButton variant="plain" color="black" onClick={onClose}>
                <Xmark />
              </IconButton>
            </div>
            <Typography variant="largeBody" className="mb-3 text-onSurface-500">
              Add a few more details to increase your visibility and
              credibility. Pick a section to get started.
            </Typography>
            <div className="flex flex-col gap-1.5">
              {dialogBtns.map(item => (
                <button
                  key={item.dialogKey}
                  onClick={() => {
                    onOpenDialog(item.dialogKey)
                  }}
                  className={cn(
                    "flex items-center gap-0.5 rounded-xl bg-onSurface-50 px-3 py-[26px] text-onSurface-600 border-2 border-onSurface-300 border-dashed"
                  )}
                  {...trackElementOnPH({
                    name: item.title,
                    id: source,
                  })}
                >
                  <Plus />
                  <Typography>{item.title}</Typography>
                </button>
              ))}
            </div>
          </DialogBody>
        </DialogContent>
      </Dialog>
      <AddQualification
        source={source}
        id={null}
        open={openDialog === BoostProfileDialogEnum.QUALIFICATION}
        setOpen={onCloseDialog}
      />
      <AddResume
        source={source}
        open={openDialog === BoostProfileDialogEnum.RESUME}
        onClose={onCloseDialog}
      />
      <PersonalDetailsSheet
        source={source}
        openSheet={openDialog === BoostProfileDialogEnum.PICTURE}
        setOpenSheet={onCloseDialog}
      />
    </>
  )
}

export default BoostProfileDialog
