import metadata from "metadata"
import React, { PropsWithChildren } from "react"
import { Helmet, HelmetProps } from "react-helmet"

export type MetadataConfig = {
  title: string
  description?: string
  url?: string
  coverImage?: string
  type?: string
  favicon?: string
}

interface Props extends HelmetProps {
  data: MetadataConfig
}

const Metadata = ({ data, children, ...props }: PropsWithChildren<Props>) => {
  const title =
    data.title ||
    "Suraasa | Teacher Training Courses, Teaching Qualifications | UAE, UK, India"

  const description = data.description || "Redesigning Teacher Education"

  const coverImage =
    data.coverImage ||
    "https://assets.suraasa.com/og-images/suraasa-meta-img.jpg"

  const type = data.type || "website"
  const favicon = data.favicon || "/favicon.ico"
  const url = data.url || window.location.href

  return (
    // @ts-expect-error react version issues
    <Helmet
      title={title}
      defaultTitle={title}
      titleTemplate="%s | Suraasa Learn"
      {...props}
    >
      <title>{title}</title>
      <meta content={title} name="title" />
      <meta content={description} name="description" />
      <link href={url} rel="canonical" />

      {favicon && <link href={favicon} rel="icon" />}

      {children && children}

      {/* Open Graph / Facebook */}
      <meta content={type} property="og:type" />
      <meta content={url} property="og:url" />
      <meta content={title} property="og:title" />
      <meta content={description} property="og:description" />
      <meta content={coverImage} property="og:image" />

      {/* Twitter */}
      <meta content="summary_large_image" property="twitter:card" />
      <meta content={url} property="twitter:url" />
      <meta content={title} property="twitter:title" />
      <meta content={description} property="twitter:description" />
      <meta content={coverImage} property="twitter:image" />
    </Helmet>
  )
}

type PageWithMetaProps = PropsWithChildren<{
  name: keyof typeof metadata
}>

export const PageWithMeta = ({ name, children }: PageWithMetaProps) => {
  return (
    <>
      <Metadata data={metadata[name] as MetadataConfig} />
      {children}
    </>
  )
}

export default Metadata
