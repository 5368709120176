import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
  InputLabel,
  Typography,
} from "@suraasa/placebo-ui"
import { Evidence, EvidenceType } from "api/resources/profile/types"
import FileUpload from "components/FileUpload"
// import FileInput from "components/shared/FileInput"
import PreviewFile from "features/Profile/components/PreviewFile"
import { Plus } from "iconoir-react"
import React, { useState } from "react"
import { UseArray } from "utils/hooks/useArray"

import UploadEvidenceUrlDialog from "./UploadEvidenceUrlDialog"

type Props = {
  limit?: number
  inputLabel?: string
  buttonLabel: string
  newEvidences: UseArray<File | string>
  evidenceFiles: UseArray<Evidence>
  handleEvidenceFilesToBeDeleted: UseArray<Evidence["id"]>["push"]
  maxSize?: number
}

const UploadEvidenceSection = ({
  inputLabel,
  buttonLabel,
  limit,
  newEvidences,
  evidenceFiles,
  handleEvidenceFilesToBeDeleted,
  maxSize = 5,
}: Props) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null)

  const [openEvidenceUrlDialog, toggleEvidenceUrlDialog] = useState(false)

  return (
    <div>
      <UploadEvidenceUrlDialog
        open={openEvidenceUrlDialog}
        toggle={() => toggleEvidenceUrlDialog(false)}
        onAdd={url => newEvidences.push(url)}
      />
      {inputLabel && <InputLabel label={inputLabel} />}
      {/* <FileInput
        accept="image/jpg,image/jpeg,image/png,application/pdf,application/doc,application/docx"
        name="evidence-files"
        ref={fileInputRef}
        onChange={files => newEvidences.push(files[0])}
      /> */}
      {/* <FileUpload inputComponent={() => {}} /> */}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <FileUpload
            maxSize={maxSize}
            onFileUpload={file => newEvidences.push(file)}
            allowedExtensions={[
              ".jpg",
              ".png",
              ".jpeg",
              ".doc",
              ".pdf",
              ".docx",
            ]}
            inputComponent={({ onClick, errors }) => {
              return (
                <div>
                  <Button
                    disabled={Boolean(
                      limit &&
                        [...evidenceFiles.array, ...newEvidences.array]
                          .length >= limit
                    )}
                    onClick={onClick}
                    size="sm"
                    startAdornment={<Plus />}
                    type="button"
                    variant="outlined"
                  >
                    {buttonLabel}
                  </Button>
                  {errors.map((error, i) => (
                    <div className="mb-2 mt-1.5 pl-2" key={i}>
                      <Typography
                        className="text-critical-500"
                        variant="smallBody"
                      >
                        {error}
                      </Typography>
                    </div>
                  ))}
                </div>
              )
            }}
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuGroup>
            <DropdownMenuItem onClick={() => toggleEvidenceUrlDialog(true)}>
              Link URL
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => fileInputRef.current?.click()}>
              Upload Document
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
      {[...evidenceFiles.array, ...newEvidences.array].length === 0 && (
        // TODO: placebo-issue support `component="li"` in Typography
        <ul className="mb-2 mt-1.5 pl-2">
          <Typography className="text-onSurface-500" variant="smallBody">
            <li>Should not exceed 5MB</li>
          </Typography>
          <Typography className="text-onSurface-500" variant="smallBody">
            <li>Accepted Formats - PDF, DOC, DOCX, JPEG, PNG</li>
          </Typography>
        </ul>
      )}
      <div className="mb-2 mt-4 flex flex-wrap items-center gap-3">
        {newEvidences.array.map((item, index) => (
          <PreviewFile
            data={item}
            key={typeof item === "string" ? item : item?.name}
            onCancel={() => newEvidences.remove(index)}
          />
        ))}
        {evidenceFiles.array.map((item, index) => (
          <PreviewFile
            data={
              item.evidenceType === EvidenceType.File ? item.file : item.url
            }
            key={item.id}
            name={
              item.evidenceType === EvidenceType.File
                ? item.fileName
                : undefined
            }
            onCancel={() => {
              evidenceFiles.remove(index)
              handleEvidenceFilesToBeDeleted(item.id)
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default UploadEvidenceSection
