import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import clsx from "clsx"
import { Plus, Trash } from "iconoir-react"
import { useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"

import RemoveDialogShad from "@/common/RemoveDialogShad"

import ProfileContext from "../../context"
import AddDialog from "./AddDialog"

const useStyles = createUseStyles(theme => ({
  dialogContent: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
  },
  addInterests: {
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
  },
  interestContainer: {
    display: "flex",
    padding: theme.spacing(1.5, 3, 1.5, 0),
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.colors.critical[500],
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
    "&:last-child": {
      border: "none",
    },
  },
}))

const InterestsDialog = ({
  open,
  handleClose,
}: { handleClose: () => void } & Pick<DialogProps, "open">) => {
  const classes = useStyles()

  const {
    interests: { data, refetch },
  } = useContext(ProfileContext)

  const hasNoItems = data.length === 0

  const [openAddInterestDialog, setOpenAddInterestDialog] = useState(hasNoItems)
  const [interestToDelete, setInterestToDelete] = useState<string | null>(null)
  const [removeDialog, setRemoveDialog] = useState(false)

  const handleRemove = async () => {
    if (!interestToDelete) return

    const res = await api.profile.interests.delete({
      urlParams: { id: interestToDelete },
    })
    if (res.isSuccessful) {
      refetch()
      setInterestToDelete(null)
    }
    setRemoveDialog(false)
  }

  useEffect(() => {
    if (open) setOpenAddInterestDialog(hasNoItems)
    if (!open) {
      setInterestToDelete(null)
      setOpenAddInterestDialog(false)
    }
  }, [open, hasNoItems])

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      {openAddInterestDialog ? (
        <AddDialog
          handleBack={
            hasNoItems ? undefined : () => setOpenAddInterestDialog(false)
          }
          open={openAddInterestDialog}
          onAdd={() => refetch()}
          onRequestClose={() => handleClose()}
        />
      ) : (
        <>
          <RemoveDialogShad
            title="Remove Interest & Hobby"
            onRemove={handleRemove}
            isOpen={removeDialog}
            setIsOpen={setRemoveDialog}
          >
            <Typography variant="smallBody">
              Are you sure you want to remove{" "}
              <b>{data.find(item => item.id === interestToDelete)?.interest}</b>{" "}
              from your profile?
            </Typography>
          </RemoveDialogShad>
          <DialogContent className={classes.dialogContent}>
            <DialogHeader>
              <DialogTitle className="text-strong">
                Interests & Hobbies
              </DialogTitle>
            </DialogHeader>
            <DialogBody className="p-0">
              <div className={clsx(classes.addInterests, "px-1.5 py-2")}>
                <Button
                  startAdornment={<Plus className="!size-2.5" />}
                  variant="text"
                  onClick={() => setOpenAddInterestDialog(true)}
                >
                  Add New Interest & Hobby
                </Button>
              </div>
              <div className="flex flex-col pl-3">
                {data.map(item => (
                  <div className={classes.interestContainer} key={item.id}>
                    <div>
                      <Typography
                        className="pb-0.5 text-onSurface-800"
                        variant="strong"
                      >
                        {item.interest}
                      </Typography>
                    </div>
                    <IconButton
                      color="critical"
                      onClick={() => {
                        setInterestToDelete(item.id)
                        setRemoveDialog(true)
                      }}
                    >
                      <Trash className="!size-3" />
                    </IconButton>
                  </div>
                ))}
              </div>
            </DialogBody>
            <DialogFooter>
              <Button
                onClick={handleClose}
                className="ml-auto !block border-onSurface-500 text-onSurface-500"
                size="sm"
                variant="outlined"
              >
                Close
              </Button>
            </DialogFooter>
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}

export default InterestsDialog
