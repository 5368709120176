import { TextField, Typography } from "@suraasa/placebo-ui"
import api from "api"
import { getNavigationPortal } from "features/SignupOnboarding/Navigation/utils"
import { FormPageProps } from "features/SignupOnboarding/types"
import debounce from "lodash/debounce"
import { useState } from "react"

const updateBackendForYearsOfExperience = debounce(
  async (value: number | null) => {
    try {
      await api.profile.update({
        data: {
          years_of_experience: value,
        },
      })
    } catch (e) {
      console.error("Failed to update backend:", e)
    }
  },
  250
)

const YearsOfExperience = ({
  data,
  updateData,
  onNext,
  ...props
}: FormPageProps) => {
  const [yearsOfExperience, setYearsOfExperience] = useState<number | null>(
    data.yearsOfExperience || null
  )

  const handleYearsOfExperienceSelection = (val: string) => {
    const parsedValue = parseInt(val)
    let valueToSave: number | null = parsedValue

    if (!isNaN(parsedValue) && parsedValue >= 0) {
      setYearsOfExperience(valueToSave)
    } else {
      valueToSave = null
      setYearsOfExperience(null)
    }

    if (valueToSave !== null) {
      updateData({ yearsOfExperience: valueToSave })
      updateBackendForYearsOfExperience(valueToSave)
    }
  }

  const isDisabled = yearsOfExperience === null

  return (
    <div className="flex flex-col items-start">
      <Typography variant="title2" color="onSurface.900">
        How many years of experience do you have as a school teacher?
      </Typography>
      <div className="mt-6" />
      <TextField
        autoFocus
        className="w-full max-w-[480px]"
        label="Years of Work Experience"
        value={yearsOfExperience?.toString() || ""}
        onChange={e => handleYearsOfExperienceSelection(e.target.value)}
        placeholder="Ex: 4"
        endIcon="years"
        type="number"
      />

      {getNavigationPortal({
        disableNext: isDisabled,
        onNext: onNext,
        ...props,
      })}
    </div>
  )
}

export default YearsOfExperience
