import { Avatar, Typography } from "@suraasa/placebo-ui"
import {
  Button,
  Container,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { CoverStyle } from "api/resources/profile/types"
import { APIError } from "api/utils"
import ProfileContext from "features/Profile/context"
import { getCoverImageURL } from "features/Profile/utils"
import { useContext } from "react"
import { buildUserName, handleErrors } from "utils/helpers"

const CoverOverview = ({
  coverStyleType,
  toggleChangeCoverStyle,
}: {
  coverStyleType: CoverStyle
  toggleChangeCoverStyle: () => void
}) => {
  const { profile, updateProfile } = useContext(ProfileContext)
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const fullName = buildUserName(profile.user)

  const { mutate, isLoading } = useMutation({
    mutationFn: () =>
      api.profile.update({
        data: { coverStyle: coverStyleType },
      }),
    onSuccess: () => {
      updateProfile()
      toggleChangeCoverStyle()
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  return (
    <div className="mb-3 border-b-2 border-solid border-b-onSurface-200 bg-white shadow-[0px_6px_15px_0px_#0000000D]">
      <div
        style={{
          backgroundImage: `url(${getCoverImageURL(coverStyleType, isXsDown)})`,
        }}
        className="relative h-[107px] bg-cover text-black"
      />
      <Container className="py-2.5">
        <div className="relative">
          <div className="absolute -top-9">
            <Avatar className="size-15" src={profile.picture} name={fullName} />
          </div>
          <div className="flex items-center justify-end">
            <Button
              loading={isLoading}
              size="sm"
              className="mr-1"
              rounded
              onClick={() => {
                mutate()
              }}
            >
              Save Changes
            </Button>
            <Button
              size="sm"
              variant="outlined"
              onClick={toggleChangeCoverStyle}
              rounded
            >
              Cancel
            </Button>
          </div>
          <div className="mt-2 flex flex-wrap items-center pt-1">
            <Typography className="inline" variant="title3">
              {fullName}
            </Typography>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default CoverOverview
