"use client"

import { Button, cn, toast } from "@suraasa/placebo-ui"
import { useRef, useState } from "react"

interface UploadZoneProps {
  onFileSelect?: (file: File | null) => void
  className?: string
  initialValue: string | null
}

export default function UploadZone({
  onFileSelect,
  className,
  initialValue,
}: UploadZoneProps) {
  const [isDragging, setIsDragging] = useState(false)
  const [uploadedImage, setUploadedImage] = useState<string | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault()
    e.dataTransfer.effectAllowed = "copy"
    setIsDragging(true)
  }

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault()
    setIsDragging(false)
  }

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault()
    setIsDragging(false)

    const files = e.dataTransfer.files
    if (files.length > 0) {
      handleFile(files[0])
    }
  }

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    fileInputRef.current?.click()
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault()
      handleClick(e as unknown as React.MouseEvent)
    }
  }

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files?.length) {
      handleFile(files[0])
    }
  }

  const validateImageUpload = (file: File) => {
    if (!file.type.includes("image")) {
      toast.error("Only images are allowed")
      return false
    }
    if (file.size / 1024 / 1024 > 5) {
      toast.error("Only images under 5 MB are allowed")
      return false
    }

    const acceptedImageTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "image/webp",
    ]

    if (!acceptedImageTypes.includes(file.type)) {
      toast.error(
        "Invalid Image Type. Only jpg, jpeg, png & webp format are allowed"
      )
      return false
    }

    return true
  }

  const handleFile = (file: File) => {
    if (validateImageUpload(file)) {
      if (onFileSelect) {
        onFileSelect(file)
      }
      const reader = new FileReader()
      reader.onload = e => {
        setUploadedImage(e.target?.result as string)
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <div className={cn("relative", className)}>
      {uploadedImage || initialValue != null ? (
        <div className="absolute size-[200px]">
          <div className="size-full overflow-hidden rounded-full">
            <img
              src={uploadedImage ?? initialValue ?? ""}
              alt="profile"
              className="size-full object-cover"
            />
          </div>
          <Button
            className="relative bottom-4 left-1/2 z-1 -translate-x-1/2 border border-onSurface-300 bg-white text-black"
            onClick={handleClick}
            variant="filled"
          >
            Change Image
          </Button>
        </div>
      ) : (
        <div
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onKeyDown={handleKeyDown}
          className={cn(
            "bg-onSurface-50 size-[250px] rounded-full border-2 border-dashed border-slate-300",
            "flex flex-col items-center justify-center gap-2",
            "transition-colors duration-200",
            isDragging && "border-primary bg-primary/5",
            "cursor-pointer"
          )}
          onClick={handleClick}
          role="button"
          tabIndex={0}
          aria-label="Upload image zone. Click or drag and drop an image here"
        >
          <p className="text-lg text-slate-600">Drop here</p>
          <p className="text-sm text-slate-500">Or</p>
          <Button
            color={null}
            className="border border-onSurface-300 bg-white text-black"
            onClick={e => {
              e.stopPropagation()
              handleClick(e)
            }}
          >
            Upload Image
          </Button>
        </div>
      )}
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        className="sr-only"
        onChange={handleFileInput}
        aria-hidden="true"
      />
    </div>
  )
}
