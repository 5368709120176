import { Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import React, { PropsWithChildren } from "react"
import { createUseStyles, useTheme } from "react-jss"

const useStyles = createUseStyles(({ spacing }) => ({
  root: ({
    xPadding,
    yPadding,
  }: Required<Pick<Props, "xPadding" | "yPadding">>) => ({
    padding: spacing(yPadding, xPadding),
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
}))

type Props = {
  className?: string
  xPadding?: 1.5 | 2 | 3
  yPadding?: number
}

const SectionContent = ({
  children,
  className,
  xPadding = 2,
  yPadding = 2,
}: PropsWithChildren<Props>) => {
  const theme = useTheme<Theme>()
  if (useMediaQuery(theme.breakpoints.only("xs"))) {
    xPadding = 2
  }

  const classes = useStyles({ xPadding, yPadding })

  return <div className={clsx(classes.root, className)}>{children}</div>
}

export default SectionContent
