import { toast, Typography } from "@suraasa/placebo-ui"
import api from "api"
import UploadZone from "features/SignupOnboarding/Components/UploadZone"
import { getNavigationPortal } from "features/SignupOnboarding/Navigation/utils"
import { FormPageProps } from "features/SignupOnboarding/types"
import { useState } from "react"

const ProfilePicture = ({
  data,
  updateData,
  onNext,
  ...props
}: FormPageProps) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)

  const updateBackend = async (file: File) => {
    setUploadedFile(file)
    const files = new FormData()
    files.append("picture", file)

    const res = await api.profile.update({
      data: files,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    if (res.isSuccessful) {
      updateData({ profilePicture: res.data.picture })
      toast.success("Profile picture updated successfully")
    } else {
      if (res.errors?.message) {
        toast.error(res.errors.message)
      }
    }
  }

  const handleFileSelect = (file: File | null) => {
    setUploadedFile(file)
  }

  const handleSubmit = async () => {
    if (isDisabled) {
      toast.error("Please upload a profile picture")
      return
    }

    if (uploadedFile) await updateBackend(uploadedFile)
    await onNext()
  }

  const isDisabled = !uploadedFile && !data.profilePicture

  return (
    <div className="flex flex-col items-start">
      <Typography variant="title2" className="text-onSurface-900">
        Lastly, add a profile picture
      </Typography>
      <div className="h-0.5"></div>
      <Typography variant="body" className="text-onSurface-500">
        Upload a clear, professional photo to help others recognize you. Avoid
        casual or inappropriate images
      </Typography>
      <div className="mt-6"></div>
      <div className="flex flex-col items-center justify-center gap-4">
        <UploadZone
          onFileSelect={handleFileSelect}
          initialValue={data.profilePicture}
        />
      </div>

      {getNavigationPortal({
        onSkip: () => onNext(true),
        disableNext: isDisabled,
        onNext: handleSubmit,
        ...props,
      })}
    </div>
  )
}

export default ProfilePicture
