import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
  TextArea,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Evidence, EvidenceType, TestScore } from "api/resources/profile/types"
import clsx from "clsx"
import LoadingOverlay from "components/LoadingOverlay"
import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"
import useArray from "utils/hooks/useArray"

import RemoveDialogShad from "@/common/RemoveDialogShad"

import ProfileContext from "../../context"
import UploadEvidenceSection from "../Academics/UploadEvidenceSection"

type Props = {
  handleClose: () => void
  id?: string
} & Omit<DialogProps, "data" | "id">

const TestScoreDialog = ({ id, open, handleClose }: Props) => {
  const [data, setData] = useState<TestScore>()

  /**
   * These evidences are uploaded by the user while they are using the application
   * They get converted into `evidenceFiles` when user hits submit.
   */
  const newEvidences = useArray<File | string>([])
  /**
   * These evidences come from the API
   */
  const evidencesFiles = useArray<Evidence>([])

  const evidencesFilesToBeDeleted = useArray<Evidence["id"]>([])

  const {
    achievements: { testScores },
  } = useContext(ProfileContext)

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<TestScore>()

  const resetForm = () => {
    newEvidences.clear()
    evidencesFiles.clear()
    evidencesFilesToBeDeleted.clear()
    reset({})
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!id) return

      const res = await api.profile.testScores.retrieve({ urlParams: { id } })
      if (res.isSuccessful) {
        setData(res.data)
        evidencesFiles.set(
          res.data.evidenceDocument
            ? [
                {
                  id: "1",
                  evidenceType: EvidenceType.File,
                  file: res.data.evidenceDocument,
                  fileName: res.data.evidenceDocumentName,
                },
              ]
            : res.data.evidenceUrl
            ? [
                {
                  id: "1",
                  evidenceType: EvidenceType.URL,
                  url: res.data.evidenceUrl,
                },
              ]
            : []
        )

        reset(res.data)
      }
    }
    if (id) fetchData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reset])

  const onSubmit = handleSubmit(
    async ({ name, score, testDate, description }) => {
      const apiData = new FormData()
      apiData.append("name", name)
      apiData.append("score", score)
      apiData.append("testDate", testDate ?? "")
      apiData.append("description", description ?? "")
      if (
        newEvidences.array.length === 0 &&
        evidencesFilesToBeDeleted.array.length !== 0
      ) {
        apiData.append("evidenceUrl", "")
        apiData.append("evidenceDocument", "")
      }

      newEvidences.array.forEach(item => {
        if (typeof item === "string") {
          apiData.append("evidenceUrl", item)
          apiData.append("evidenceDocument", "")
        } else {
          apiData.append("evidenceUrl", "")
          apiData.append("evidenceDocument", item)
        }
      })

      if (id) {
        const res = await api.profile.testScores.update({
          urlParams: { id },
          data: apiData,
        })
        if (res.isSuccessful) {
          testScores.refetch()
          handleClose()
        } else {
          handleErrors(res, { setter: setError })
        }
      } else {
        const res = await api.profile.testScores.create({ data: apiData })
        if (res.isSuccessful) {
          testScores.refetch()
          handleClose()
        } else {
          handleErrors(res, { setter: setError })
        }
      }
    }
  )

  const handleRemove = async () => {
    if (id) {
      const res = await api.profile.testScores.delete({ urlParams: { id } })
      if (res.isSuccessful) {
        testScores.refetch()
        handleClose()
      }
    }
  }

  return (
    <>
      <Dialog open={open} onOpenChange={handleClose}>
        <DialogContent>
          {id && !data && <LoadingOverlay />}
          <DialogHeader>
            <DialogTitle className="text-strong">
              {id ? "Edit" : "Add New"} Test Score
            </DialogTitle>
          </DialogHeader>
          <DialogBody>
            <form onSubmit={onSubmit} className="flex flex-col gap-3">
              <TextField
                errors={errors.name?.message}
                required
                label="Name"
                placeholder="Ex: CTET Paper 1"
                {...register("name", {
                  required: { value: true, message: "Required" },
                })}
              />

              <TextField
                errors={errors.score?.message}
                required
                label="Score"
                placeholder="Ex: 140/150"
                {...register("score", {
                  required: { value: true, message: "Required" },
                })}
              />

              <TextField
                errors={errors.testDate?.message}
                label="Test Date"
                placeholder="Ex: 21 Jan 2021"
                type="date"
                {...register("testDate")}
              />
              <TextArea
                errors={errors.description?.message}
                label="Description"
                placeholder="Talk about your role and experience etc..."
                rows={5}
                {...register("description", {
                  maxLength: {
                    value: 300,
                    message: "Try to keep it short",
                  },
                })}
              />

              <UploadEvidenceSection
                buttonLabel="Add Score Result"
                evidenceFiles={evidencesFiles}
                handleEvidenceFilesToBeDeleted={evidencesFilesToBeDeleted.push}
                inputLabel="Evidence"
                limit={1}
                newEvidences={newEvidences}
              />
            </form>
          </DialogBody>
          <DialogFooter
            className={clsx("flex", {
              "!justify-between": data,
            })}
          >
            {data && (
              <RemoveDialogShad
                trigger={
                  <Button
                    variant="text"
                    color="critical"
                    loading={isSubmitting}
                  >
                    Remove
                  </Button>
                }
                title="Remove Test Score"
                onRemove={handleRemove}
              >
                <Typography variant="smallBody">
                  Are you sure you want to remove&nbsp;
                  <Typography className="inline" variant="strongSmallBody">
                    <strong>{data.name}</strong>
                  </Typography>
                  &nbsp;from your profile?
                </Typography>
              </RemoveDialogShad>
            )}

            <div>
              <Button
                variant="outlined"
                onClick={handleClose}
                className="mr-1.25 border-muted text-muted"
                size="sm"
                color="black"
              >
                Cancel
              </Button>
              <Button
                onClick={onSubmit}
                size="sm"
                loading={isSubmitting}
                className="py-1.25"
              >
                Save
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default TestScoreDialog
