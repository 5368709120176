import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  TextArea,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { APIError } from "api/utils"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"
import { trackingService } from "utils/tracking"

type FormData = {
  careerAspiration: string
}

type Props = {
  onUpdate: (newData: string) => void
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  data: FormData
  open: boolean
  source?: string
}

const EditDialog = ({
  data: { careerAspiration },
  open,
  setOpen,
  onUpdate,
  source,
}: Props) => {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      careerAspiration,
    },
  })

  useEffect(() => {
    reset({ careerAspiration })
  }, [careerAspiration, reset])

  const onSubmit = handleSubmit(async formData => {
    const apiData = {
      careerAspiration: formData.careerAspiration || null,
    }

    return api.profile
      .update({
        data: apiData,
      })
      .then(() => {
        trackingService.trackEvent("profile_career_aspiration_updated", {
          source,
        })
        onUpdate(formData.careerAspiration)
        setOpen(false)
      })
      .catch(e => {
        if (e instanceof APIError) handleErrors(e, { setter: setError })
      })
  })

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <Typography variant="strong">About</Typography>
          </DialogTitle>
        </DialogHeader>
        <DialogBody>
          <form onSubmit={onSubmit}>
            {/* ts-expect-error random-types-issue */}
            <TextArea
              rows={9}
              errors={errors.careerAspiration?.message}
              autoFocus
              placeholder="Write about yourself, your hobbies, your career aspiration etc."
              {...register("careerAspiration", {
                maxLength: {
                  value: 400,
                  message:
                    "Please keep it concise – a maximum of 400 characters.",
                },
              })}
            />
          </form>
        </DialogBody>

        <DialogFooter>
          <Button onClick={onSubmit} loading={isSubmitting} size="sm">
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default EditDialog
