import { PhoneNumber } from "../users/types"

export enum UserReferenceRelationship {
  YOU_MANAGED_THEM_DIRECTLY = "you_managed_them_directly",
  YOU_REPORTED_DIRECTLY_TO_THEM = "you_reported_directly_to_them",
  YOU_WERE_SENIOR_TO_THEM_BUT_DID_NOT_MANAGE_THEM_DIRECTLY = "you_were_senior_to_them_but_did_not_manage_them_directly",
  THEY_WERE_SENIOR_TO_YOU_BUT_DID_NOT_MANAGE_YOU_DIRECTLY = "they_were_senior_to_you_but_did_not_manage_you_directly",
  YOU_WORKED_WITH_THEM_IN_THE_SAME_GROUP = "you_worked_with_them_in_the_same_group",
  THEY_WORKED_WITH_YOU_IN_A_DIFFERENT_GROUP = "they_worked_with_you_in_a_different_group",
  THEY_WORKED_WITH_YOU_BUT_AT_A_DIFFERENT_COMPANY = "they_worked_with_you_but_at_a_different_company",
  THEY_WERE_A_CLIENT_OF_YOURS = "they_were_a_client_of_yours",
  YOU_WERE_THEIR_CLIENT = "you_were_their_client",
  YOU_TAUGHT_THEM = "you_taught_them",
  YOU_MENTORED_THEM = "you_mentored_them",
  THEY_STUDIED_TOGETHER_WITH_YOU = "they_studied_together_with_you",
}

export const userReferenceRelationshipChoices = [
  {
    label: "You managed them directly",
    value: UserReferenceRelationship.YOU_MANAGED_THEM_DIRECTLY,
  },
  {
    label: "You reported directly to them",
    value: UserReferenceRelationship.YOU_REPORTED_DIRECTLY_TO_THEM,
  },
  {
    label: "You were senior to them but did not manage them directly",
    value:
      UserReferenceRelationship.YOU_WERE_SENIOR_TO_THEM_BUT_DID_NOT_MANAGE_THEM_DIRECTLY,
  },
  {
    label: "They were senior to you but did not manage you directly",
    value:
      UserReferenceRelationship.THEY_WERE_SENIOR_TO_YOU_BUT_DID_NOT_MANAGE_YOU_DIRECTLY,
  },
  {
    label: "You worked with them in the same group",
    value: UserReferenceRelationship.YOU_WORKED_WITH_THEM_IN_THE_SAME_GROUP,
  },
  {
    label: "They worked with you in a different group",
    value: UserReferenceRelationship.THEY_WORKED_WITH_YOU_IN_A_DIFFERENT_GROUP,
  },
  {
    label: "They worked with you but at a different company",
    value:
      UserReferenceRelationship.THEY_WORKED_WITH_YOU_BUT_AT_A_DIFFERENT_COMPANY,
  },
  {
    label: "They were a client of yours",
    value: UserReferenceRelationship.THEY_WERE_A_CLIENT_OF_YOURS,
  },
  {
    label: "You were their client",
    value: UserReferenceRelationship.YOU_WERE_THEIR_CLIENT,
  },
  {
    label: "You taught them",
    value: UserReferenceRelationship.YOU_TAUGHT_THEM,
  },
  {
    label: "You mentored them",
    value: UserReferenceRelationship.YOU_MENTORED_THEM,
  },
  {
    label: "They studied together with you",
    value: UserReferenceRelationship.THEY_STUDIED_TOGETHER_WITH_YOU,
  },
]

export type Reference = {
  id: string
  referrer: Referrer
  source: Source
  relationship: UserReferenceRelationship
  userId: string
  userPosition: string
  organisationName: string
  startDate: string
  endDate: string
  sentAt: string
  userQuestionnaire: UserQuestionnaire
  message: string | null
}

export type Referrer = {
  name: string
  email: string
  phoneNumber: PhoneNumber
  position: string
}

export type UserQuestionnaire = {
  submittedAt: string | null
}

export enum SourceType {
  UserQualification = "UserQualification",
  UserWorkExperience = "UserWorkExperience",
}

export type Source = {
  id: number
  type: SourceType
}

export type ReferenceAnalytics = {
  workExperience: {
    key?: number
  }
  qualification: {
    key?: number
  }
  other: number
}
