import { Checkbox, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"

interface CheckBoxTextContainerProps {
  text: string
  isSelected: boolean
  onClick: () => void
  minSize?: boolean
}

const CheckBoxTextContainer: React.FC<CheckBoxTextContainerProps> = ({
  text,
  isSelected,
  onClick,
}) => {
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={e => {
        if (e.key === "Enter" || e.key === " ") {
          onClick()
        }
      }}
      className={clsx(
        "flex cursor-pointer items-center rounded-xl border-2 px-1.75 py-2",
        "ring-interactive-500 hover:bg-onSurface-50 focus:outline-none focus:ring-2 active:bg-onSurface-100",
        {
          "border-primary-500 font-bold": isSelected,
          "border-onSurface-300": !isSelected,
        }
      )}
    >
      <Checkbox
        readOnly
        tabIndex={-1}
        checked={isSelected}
        containerClass="flex items-center gap-1"
        className="rounded-md"
        label={<Typography variant="subtitle2">{text}</Typography>}
      />
    </div>
  )
}

export default CheckBoxTextContainer
