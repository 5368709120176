import { Divider } from "@suraasa/placebo-ui"
import { Certification } from "api/resources/profile/types"
import { useContext } from "react"

import ProfileContext from "../../../context"
import CertificationItem from "./CertificationItem"

const Certifications = ({
  onEdit,
}: {
  onEdit: (id: Certification["id"]) => void
}) => {
  const {
    academics: {
      certifications: { data },
    },
    isPublic,
  } = useContext(ProfileContext)

  return (
    <>
      {data.map((certification, index) => (
        <div className="ml-2 pt-2" key={certification.id}>
          {/* <div className={clsx(classes.details, "pb-2")}>
            {!isPublic && !certification.isVerified && (
              <IconButton
                className={clsx("edit-button")}
                color="black"
                onClick={() => onEdit(certification.id)}
              >
                <Edit />
              </IconButton>
            )}

            <div className="flex items-center gap-0.5">
              <Typography variant="strongSmallBody">
                {certification.name}
              </Typography>

              {certification.isVerified && (
                <img
                  alt="✅"
                  height="24px"
                  src={twitterVerifiedBadge}
                  width="24px"
                />
              )}
            </div>

            <Typography
              className="mb-0.25 text-onSurface-600"
              variant="smallBody"
            >
              {certification.organisationName}
            </Typography>

            {certification.completionDate && (
              <Typography
                className="mb-0.5 text-onSurface-600"
                variant="smallBody"
              >
                {format(new Date(certification.completionDate), "MMMM yyyy")}
              </Typography>
            )}

            {certification.evidences && certification.evidences.length > 0 && (
              <PreviewEvidenceList data={certification.evidences} />
            )}
          </div> */}
          <CertificationItem
            certification={certification}
            onEdit={() => onEdit(certification.id)}
            isPublic={isPublic}
          />
          {index < data.length - 1 && (
            <Divider color="onSurface.200" weight="light" />
          )}
        </div>
      ))}
    </>
  )
}

export default Certifications
