import { IconButton, Tag } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { Menu, Trash } from "iconoir-react"
import React, { PropsWithChildren } from "react"

export type DraggableItemType = {
  isDraggable?: boolean
  showTags: boolean
  //isDragging?: boolean
  index: number
  className?: string
  onDragStart?: (e: React.DragEvent<HTMLDivElement>, index: number) => void
  onDrop?: (e: React.DragEvent<HTMLDivElement>, index: number) => void
  onDragOver?: (e: React.DragEvent<HTMLDivElement>) => void
  onDelete: () => void
}

const DraggableItem = ({
  children,
  isDraggable,
  index,
  onDragStart,
  onDrop,
  onDragOver,
  showTags,
  className,
  onDelete,
}: PropsWithChildren<DraggableItemType>) => {
  return (
    <div
      draggable={isDraggable}
      onDragStart={e => {
        if (onDragStart) onDragStart(e, index)
      }}
      onDrop={e => {
        if (onDrop) onDrop(e, index)
      }}
      onDragOver={e => {
        if (onDragOver) onDragOver(e)
      }}
      className={clsx("relative", className)}
    >
      <div className="flex justify-between">
        <div
          className={clsx("flex items-center ", {
            "cursor-move": isDraggable,
          })}
        >
          {isDraggable && (
            <Menu
              width={20}
              height={20}
              className="mr-2 hidden shrink-0 sm:block"
            />
          )}
          <div className="flex flex-wrap items-center">
            {children}
            {showTags && index <= 1 && (
              <Tag color={index === 0 ? "primary" : "secondary"}>
                {index === 0 ? "Primary" : "Secondary"}
              </Tag>
            )}
          </div>
        </div>

        {!isDraggable && (
          <IconButton onClick={onDelete} size="xs" color="critical">
            <Trash className="!size-3" />
          </IconButton>
        )}
        {isDraggable && (
          <Menu
            width={20}
            height={20}
            className="mr-2 block shrink-0 sm:hidden"
          />
        )}
      </div>
    </div>
  )
}

export default DraggableItem
