import clsx from "clsx"
import { motion } from "framer-motion"
import { PropsWithChildren } from "react"

const offset = 1000

const variants = {
  enter: (direction: number) => ({
    x: direction > 0 ? offset : -offset,
    opacity: 0,
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => ({
    zIndex: 0,
    x: direction < 0 ? offset : -offset,
    opacity: 0,
  }),
}

type Props = PropsWithChildren<{
  id: string
  className?: string
  direction: number
}>

const Animation = ({ id, children, className, direction }: Props) => {
  return (
    <motion.div
      animate="center"
      className={clsx("h-full pb-5", className)}
      custom={direction}
      exit="exit"
      initial="enter"
      key={id}
      transition={{
        x: { type: "spring", stiffness: 300, damping: 30 },
        opacity: { duration: 0.2 },
      }}
      variants={variants}
    >
      {children}
    </motion.div>
  )
}

export default Animation
