import { IconButton, Tag, Typography } from "@suraasa/placebo-ui"
import { Edit, Plus } from "iconoir-react"
import { useContext, useState } from "react"
import { createUseStyles } from "react-jss"
import { pluralize } from "utils/helpers"

import ProfileContext from "../../context"
import Section from "../Section"
import SectionContent from "../Section/SectionContent"
import SectionHeading from "../Section/SectionHeading"
import SectionTitle from "../Section/SectionTitle"
import SubjectsDialog from "./SubjectsDialog"

const useStyles = createUseStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1.5),
  },
}))

const Subjects = () => {
  const classes = useStyles()

  const {
    subjects: { data },
    isPublic,
  } = useContext(ProfileContext)

  const [openDialog, setOpenDialog] = useState(false)

  const toggle = () => setOpenDialog(!openDialog)

  const [primary, secondary, ...others] = data

  return (
    <>
      <SubjectsDialog open={openDialog} onRequestClose={toggle} />
      <Section>
        <SectionHeading
          heading={
            <>
              <SectionTitle
                actionIcon={
                  isPublic ? undefined : (
                    <IconButton onClick={toggle} className="[&>svg]:!size-3">
                      {data.length > 0 ? <Edit /> : <Plus />}
                    </IconButton>
                  )
                }
                itemCount={data.length}
                title={pluralize("Subject", data.length, { skipCount: true })}
              />
            </>
          }
        />
        <SectionContent className={classes.root} xPadding={2}>
          {data.length > 0 ? (
            <div className="flex flex-col gap-1.75">
              {primary && (
                <div>
                  <Typography
                    variant="smallBody"
                    className="mb-0.5 !text-onSurface-500"
                  >
                    Primary Subject
                  </Typography>
                  <Tag size="sm" key={primary.id}>
                    {primary.subject.name}
                  </Tag>
                </div>
              )}
              {secondary && (
                <div>
                  <Typography
                    variant="smallBody"
                    className="mb-0.5 !text-onSurface-500"
                  >
                    Secondary Subject
                  </Typography>
                  <Tag size="sm" key={secondary.id}>
                    {secondary.subject.name}
                  </Tag>
                </div>
              )}
              {others.length > 0 && (
                <div>
                  <Typography
                    variant="smallBody"
                    className="mb-0.5 !text-onSurface-500"
                  >
                    {pluralize("Other Subject", data.slice(2).length, {
                      skipCount: true,
                    })}
                  </Typography>
                  <div className="flex flex-wrap gap-1">
                    {others.map(x => (
                      <Tag size="sm" key={x.id}>
                        {x.subject.name}
                      </Tag>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <Typography className="text-onSurface-400" variant="smallBody">
              {isPublic
                ? "Looks like there's nothing here."
                : "You haven’t added any subjects. Click on + icon to add one."}
            </Typography>
          )}
        </SectionContent>
      </Section>
    </>
  )
}
export default Subjects
