import { Radio, Theme } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import React, { useState } from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles((theme: Theme) => ({
  "@keyframes flicker": {
    "0%": {
      backgroundColor: "#fff",
    },
    "25%": {
      // backgroundColor: convertHexToRgba(theme.colors.primary[50], 0.75),
      backgroundColor: "#edf0fe",
    },
    "50%": {
      backgroundColor: "#fff",
    },
    "75%": {
      // backgroundColor: convertHexToRgba(theme.colors.primary[50], 0.75),
      backgroundColor: "#edf0fe",
    },
    "100%": {
      backgroundColor: "#fff",
    },
  },

  button: {
    color: "#000",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    border: `1px solid ${theme.colors.onSurface[300]}`,
    width: "100%",
    height: "100%",

    backgroundColor: "#fff",
    "&:hover": {
      border: `1px solid ${theme.colors.onSurface[300]}`,
      boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.1)",
      cursor: "pointer",
      backgroundColor: `${theme.colors.onSurface[50]}`,
    },
    "&:focus": {
      outline: `1px solid ${theme.colors.interactive[500]}`,
    },
    "& *": {
      textAlign: "left",
    },
    [theme.breakpoints.down("sm")]: {
      "& *": {
        fontSize: "14px",
      },
    },
  },

  selected: {
    border: `1px solid ${theme.colors.primary[500]} !important`,

    "&.animate": {
      animation: "$flicker 0.5s linear",
    },
  },
  rounded: {
    borderRadius: theme.spacing(100),
  },
}))

const SelectableCard = ({
  type = "default",
  selected,
  className,
  children,
  onClick,
  disabled = false,
  rounded = false,
}: {
  /**
   * Use this className to change card appearance.
   */
  className?: string
  selected: boolean | undefined
  disabled?: boolean
  children: React.ReactNode
  /**
   * this card is used in two ways one with radio
   * and another in which an svg is rendered (ref:<YearsOfExperience/>)
   * Passing `type=radio` renders the radio input in place of children
   */
  type?: "radio" | "default"
  rounded?: boolean

  onClick: () => void
}) => {
  const classes = useStyles()

  const [animate, setAnimate] = useState(!selected)

  return (
    <button
      className={clsx(classes.button, className, {
        [classes.selected]: selected,
        [classes.rounded]: rounded,
        animate,
      })}
      disabled={disabled}
      type="button"
      onClick={() => {
        setAnimate(true)
        onClick()
      }}
    >
      {type === "default" && children}
      {type === "radio" && (
        <div className="flex gap-1">
          <Radio
            checked={selected}
            tabIndex={-1}
            readOnly
            className={clsx("[&_input+div]:border", {
              "[&_input+div]:!border-onSurface-300": !selected,
            })}
          />
          {children}
        </div>
      )}
    </button>
  )
}

export default SelectableCard
