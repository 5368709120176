import {
  Button,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
  Select,
} from "@suraasa/placebo-ui"
import api from "api"
import { Subject as GlobalSubject } from "api/resources/global/types"
import { SubjectResponse } from "api/resources/profile/types"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useResource } from "utils/hooks/useResource"
import { trackingService } from "utils/tracking"

type Props = {
  onAdd: (data: SubjectResponse) => void
  onBack?: () => void
  source?: string
}

const AddDialog = ({
  onBack,
  onAdd,
  onRequestClose,
  source,
  open,
}: Props & { onRequestClose: () => void } & Pick<DialogProps, "open"> & {
    onBack?: () => void
  }) => {
  const { subjects } = useResource(["subjects"])

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setError,
  } = useForm<Pick<GlobalSubject, "uuid">>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!open) reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const onSubmit = handleSubmit(async data => {
    setLoading(true)

    const res = await api.profile.subjects.create({
      data: {
        subjectId: data.uuid,
      },
    })

    if (res.isSuccessful) {
      trackingService.trackEvent("profile_subject_added", {
        source,
      })
      setLoading(false)
      onAdd(res.data)
      reset()
      if (onBack) onBack()
    } else {
      if (res.errors.fieldErrors?.subjectId) {
        // @ts-expect-error this is safe
        setError("uuid", { message: res.errors.fieldErrors.subjectId })
      }
      setLoading(false)
    }
  })

  return (
    <>
      <DialogContent>
        <DialogHeader>
          <DialogTitle onBack={onBack} className="text-strong">
            Add New Subject
          </DialogTitle>
        </DialogHeader>
        <DialogBody>
          <form onSubmit={onSubmit}>
            <div className="p-1.25 pb-4">
              <Controller
                control={control}
                name="uuid"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    errors={errors.uuid?.message}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.uuid}
                    required
                    label="Subject"
                    options={subjects}
                    placeholder="Select Subject"
                    value={
                      value
                        ? subjects.find(subject => subject.uuid === value)
                        : null
                    }
                    mountOnBody
                    onBlur={onBlur}
                    onChange={val => onChange(val?.uuid)}
                  />
                )}
                rules={{
                  required: { value: true, message: "Required" },
                }}
              />
            </div>
          </form>
        </DialogBody>
        <DialogFooter>
          <Button
            variant={"outlined"}
            size="sm"
            className="border-onSurface-500 text-onSurface-500"
            onClick={onRequestClose}
          >
            Close
          </Button>
          <Button
            onClick={onSubmit}
            size="sm"
            disabled={loading}
            loading={loading}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </>
  )
}

export default AddDialog
