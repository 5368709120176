import { toast, Typography } from "@suraasa/placebo-ui"
import api from "api"
import SelectableCard from "features/SignupOnboarding/Components/SelectableCard"
import { getNavigationPortal } from "features/SignupOnboarding/Navigation/utils"
import { FormPageProps } from "features/SignupOnboarding/types"
import { getAuthInfo } from "utils/helpers"

const options = [
  { label: "Yes", value: true },
  { label: "No", value: false },
]

const CurrentlyTeachingInSchoolStatus = ({
  data,
  updateData,
  onNext,
  ...props
}: FormPageProps) => {
  const auth = getAuthInfo()
  const name = auth ? auth.user.firstName : ""

  const updateBackend = async (value: boolean) => {
    try {
      await api.profile.updateMiscData({
        data: {
          currently_teaching_in_school: value,
        },
      })
    } catch (e) {
      console.error("Failed to update backend:", e)
    }
  }

  const handleSelection = async (value: boolean) => {
    updateData({ isTeachingInSchool: value })
  }

  const handleSubmit = async () => {
    if (data.isTeachingInSchool === null) {
      toast.error("Please select an option")
      return
    }
    await updateBackend(data.isTeachingInSchool)
    await onNext()
  }

  const isDisabled = data.isTeachingInSchool === null

  return (
    <div className="flex flex-col items-start">
      <Typography variant="title2">
        {name ? `Hi ${name},` : "Hi,"} are you currently teaching in a school?
      </Typography>
      <div className="mt-4 flex w-full max-w-[480px] flex-col gap-1">
        {options.map(option => (
          <SelectableCard
            key={option.label}
            selected={data.isTeachingInSchool === option.value}
            type="radio"
            onClick={() => handleSelection(option.value)}
          >
            <Typography variant="subtitle2">{option.label}</Typography>
          </SelectableCard>
        ))}
      </div>

      {getNavigationPortal({
        disableNext: isDisabled,
        onNext: handleSubmit,
        ...props,
      })}
    </div>
  )
}

export default CurrentlyTeachingInSchoolStatus
