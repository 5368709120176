import {
  Button,
  Divider,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui-legacy"
import Section from "features/Profile/components/Section"
import SectionHeading from "features/Profile/components/Section/SectionHeading"
import SectionTitle from "features/Profile/components/Section/SectionTitle"
import ProfileContext from "features/Profile/context"
import { Plus } from "iconoir-react"
import { Info } from "lucide-react"
import { useContext, useState } from "react"
import { createUseStyles } from "react-jss"
import { getPlatformURL, pluralize } from "utils/helpers"

import SectionContent from "../Section/SectionContent"
import ReferenceEditDialog from "./EditDialog"
import ReferenceItem from "./ReferenceItem"
import { PublicReferenceCard } from "./Utils"

const useStyles = createUseStyles({
  viewButton: {
    borderRadius: "0 0 8px 8px",
    fontSize: 14,
    "& > *": {
      justifyContent: "flex-start !important",
    },
  },
})

const ReferenceSection = () => {
  const classes = useStyles()
  const [openDialog, setOpenDialog] = useState(false)

  const { references, isPublic, referenceAnalytics } =
    useContext(ProfileContext)

  const directReferences = references.data
    .filter(ref => ref.source === null)
    .filter(ref =>
      isPublic ? ref?.userQuestionnaire?.submittedAt !== null : true
    )

  const referenceCount = referenceAnalytics.data?.other || 0

  const getContent = () => {
    if (isPublic) {
      if (referenceCount === 0) {
        return (
          <Typography color="onSurface.400" variant="smallBody">
            Looks like there&apos;s nothing to see here.
          </Typography>
        )
      }
      return <PublicReferenceCard count={referenceCount} />
    }

    if (directReferences.length === 0) {
      return (
        <Typography color="onSurface.400" variant="smallBody">
          Looks like there&apos;s nothing to see here.
        </Typography>
      )
    }

    return (
      <>
        {directReferences.map((reference, index) => (
          <>
            <ReferenceItem data={reference} isPublic={isPublic} />
            {index < directReferences.length - 1 && (
              <Divider
                className="my-2.25"
                color="onSurface.200"
                weight="light"
              />
            )}
          </>
        ))}
      </>
    )
  }

  return (
    <>
      {openDialog && (
        <ReferenceEditDialog
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
        />
      )}

      <Section>
        <SectionHeading
          heading={
            <SectionTitle
              itemCount={directReferences?.length}
              actionIcon={
                isPublic ? undefined : (
                  <IconButton onClick={() => setOpenDialog(true)}>
                    <Plus />
                  </IconButton>
                )
              }
              title={pluralize(
                "Additional Reference",
                directReferences?.length ?? 0,
                {
                  skipCount: true,
                }
              )}
            />
          }
          xPadding={2}
        />
        <SectionContent className="flex flex-col" xPadding={2}>
          {getContent()}
        </SectionContent>
        <span>
          <Button
            color="interactive"
            size="sm"
            className={classes.viewButton}
            startAdornment={<Info />}
            component="a"
            target="_blank"
            href={getPlatformURL("suraasa", "/professional-reference")}
            fullWidth
          >
            Learn how references work
          </Button>
        </span>
      </Section>
    </>
  )
}

export default ReferenceSection
