"use client"

import { cn } from "@suraasa/placebo-ui"
import * as React from "react"
import { DayPicker } from "react-day-picker"

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function Calendar({ className, classNames, ...props }: CalendarProps) {
  return (
    <DayPicker
      hideNavigation
      captionLayout="dropdown"
      className={cn(className)}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-2 sm:space-x-2 sm:space-y-0",
        month: "space-y-2",
        caption: "flex justify-center pt-0.5 relative items-center",
        caption_label: "hidden text-sm font-medium",
        nav: "space-x-0.5 flex items-center",
        nav_button: cn(
          "border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground",
          "h-3.5 w-3.5 bg-transparent p-0 opacity-50 hover:opacity-100"
        ),
        nav_button_previous: "absolute left-0.5",
        nav_button_next: "absolute right-0.5",
        table: "w-full border-collapse space-y-0.5",
        head_row: "flex",
        head_cell:
          "text-muted-foreground rounded-md w-4 font-normal text-[0.8rem]",
        row: "flex w-full mt-1",
        cell: cn(
          "relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-accent [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected].day-range-end)]:rounded-r-md",
          props.mode === "range"
            ? "[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md"
            : "[&:has([aria-selected])]:rounded-md"
        ),
        day: cn(
          "hover:bg-interactive-50",
          "font-normal text-center rounded-full",
          "h-0 w-0 rdp-day-button"
        ),

        selected: "!bg-interactive-100",
        day_button: "px-1.25 py-1 rounded-xl",

        day_today: "bg-green-500 opacity-100",
        day_outside:
          "day-outside text-muted-foreground aria-selected:bg-accent/50 aria-selected:text-muted-foreground",
        day_disabled: "text-muted-foreground opacity-50",
        day_range_middle:
          "aria-selected:bg-accent aria-selected:text-accent-foreground",
        day_hidden: "invisible",
        months_dropdown: "me-1 border border-onSurface-500 rounded-md",
        years_dropdown: "border border-onSurface-500 rounded-md",

        ...classNames,
      }}
      {...props}
    />
  )
}
Calendar.displayName = "Calendar"

export { Calendar }
