import { Typography } from "@suraasa/placebo-ui"
import Stars from "assets/AITools/stars-gradient.json"
import clsx from "clsx"
import Lottie from "react-lottie"

const GenerateButton = ({
  loading,
  onClick,
  className,
  label,
}: {
  label: string
  className?: string
  loading: boolean
  onClick: () => void
}) => {
  return (
    <button
      disabled={loading}
      onClick={onClick}
      className={clsx(
        className,
        "flex h-5 items-center rounded-full bg-black px-2 py-1 text-white"
      )}
    >
      <Typography variant="button">
        {loading ? "Generating..." : label}
      </Typography>
      <div className="shrink-0">
        {loading ? (
          // @ts-expect-error react version issues
          <Lottie
            isClickToPauseDisabled
            style={{
              cursor: "default",
              marginLeft: 8,
              width: 24,
              height: 24,
              transform: "scale(1.4)",
            }}
            speed={1.75}
            options={{
              loop: true,
              autoplay: true,
              animationData: Stars,
            }}
          />
        ) : undefined}
      </div>
    </button>
  )
}

export default GenerateButton
