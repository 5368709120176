import clsx from "clsx"
import { Plus } from "iconoir-react"
import React, { useState } from "react"
import { createUseStyles } from "react-jss"

import CheckIcon from "../../assets/subjectIcons/white-check.svg"

const useStyles = createUseStyles(theme => ({
  button: {
    display: "flex",
    alignItems: "center",
    color: theme.colors.onSurface[600],
    padding: theme.spacing(1.5, 2),
    borderRadius: theme.spacing(1.5),
    backgroundColor: "#fff",
    border: `2px solid ${theme.colors.onSurface[300]}`,
    transition: "all 0.2s linear",

    "& *": {
      wordBreak: "break-word",
    },

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      justifyContent: "flex-start",
      textAlign: "left",
      padding: theme.spacing(1, 1.25),
      "& *": {
        fontSize: "14px",
      },
    },

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1.25),
    },

    "& svg": {
      flexShrink: 0,
      display: "block",
      margin: "auto",
      marginRight: theme.spacing(1),
    },

    "& input": {
      border: 0,
      outline: "none",
      // ...theme.typography.body1,
      ...theme.typography.body,
      fontWeight: 500,
      fontFamily: "Inter",
      maxHeight: "22px",
      "&::placeholder": {
        color: theme.colors.onSurface[400],
      },
    },
  },

  baseInteraction: {
    // "&:hover": {
    //   cursor: "pointer",
    //   transform: "scale(1.02)",
    //   border: `2px solid ${theme.colors.onSurface[300]}`,
    // },

    "&:active": {
      transform: "scale(1.02)",
      backgroundColor: theme.colors.onSurface[100],
    },
  },

  selected: {
    color: "#fff",
    backgroundColor: theme.colors.primary[500],
    border: `2px solid ${theme.colors.primary[500]}`,
  },

  selectedInteraction: {
    "&:active": {
      backgroundColor: theme.colors.primary[600],
      border: `2px solid ${theme.colors.primary[600]}`,
    },
    // "&:hover": {
    //   border: `2px solid ${theme.colors.primary[500]}`,
    // },
  },
  disabled: {
    fill: theme.colors.onSurface[100],
    backgroundColor: theme.colors.onSurface[100],
    color: theme.colors.onSurface[400],
    cursor: "not-allowed !important",
    "&:focus": {
      outline: "none",
    },
  },
}))

interface BaseChip {
  className?: string
  selected: boolean | null
  disabled?: boolean
  children?: React.ReactNode
  icon?: any
  onClick?: () => void
}

type DefaultChip = {
  editable: boolean
  onBlur: (text: string) => void
  onFocus?: () => void
}

type EditableChip = {
  editable?: never
  onBlur?: never
  onFocus?: never
}

type Props = BaseChip & (DefaultChip | EditableChip)

const CreateableChip = ({
  selected,
  className,
  children,
  icon: Icon,
  onClick,
  editable,
  disabled = false,
  onBlur,
  onFocus,
}: Props) => {
  const classes = useStyles()
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState("")

  const handleBlurEvent = () => {
    if (onBlur) onBlur(value)
    setIsEditing(false)
    setValue("")
  }

  const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Esc and Enter
    if (e.key === "Escape" || e.key === "Enter") {
      handleBlurEvent()
    }
  }

  /**
   * if this chip is selected then user should be able to deselect it
   * but at the same time should not be able to select unselected chip
   */
  const isDisabled = !selected && disabled

  const handleOnClick = () => {
    if (isDisabled) return

    if (editable) {
      setIsEditing(true)
    } else if (onClick) onClick()
  }

  return (
    <div
      aria-pressed={Boolean(selected)}
      className={clsx(classes.button, className, {
        [classes.baseInteraction]: !isDisabled,
        [classes.selected]: selected,
        [classes.selectedInteraction]: selected && !isDisabled,
        [classes.disabled]: isDisabled,
      })}
      role="button"
      tabIndex={isDisabled ? -1 : 0}
      onClick={handleOnClick}
      // space key to interact
      onKeyPress={e => e.key === " " && handleOnClick()}
    >
      <span className="mr-1.25">
        {}
        {editable ? (
          <Plus />
        ) : selected ? (
          <img alt="check icon" src={CheckIcon} />
        ) : (
          Icon && <img alt="" src={Icon} />
        )}
      </span>
      {isEditing ? (
        <input
          maxLength={100}
          placeholder="Start typing..."
          value={value}
          autoFocus
          onBlur={handleBlurEvent}
          onChange={e => setValue(e.target.value)}
          onFocus={onFocus}
          onKeyDown={handleKeydown}
        />
      ) : (
        children
      )}
    </div>
  )
}

export default CreateableChip
