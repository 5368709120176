import { Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import ConfirmDialog, {
  ConfirmDialogProvider,
} from "components/AsyncDialog/ConfirmDialog"
import BottomNavigation from "components/Navigation/BottomNavigation"
import TopNavigation from "components/Navigation/TopNavigation"
// import AIToolsOnboarding from "features/AItools/Onboarding"
import { ProAccessProvider } from "features/AItools/PRO/context/SuraasaPlusContext"
import ChatBot from "features/ChatBot"
import GlobalBanners from "features/GlobalBanners"
import GlobalCards from "features/GlobalCards"
// import UploadResumeNudgeDialog from "features/Profile/UploadResumeNudgeDialog"
// import ExpiredCourseCard from "features/LearningItems/ExpiredCourseCard"
import RyanSignUpOnboarding from "features/RyanSignupOnboarding"
import { PlatformSettings, useGlobalContext } from "global/Context/context"
import { usePostHog } from "posthog-js/react"
import { useEffect } from "react"
import { useTheme } from "react-jss"
import { Outlet, useSearchParams } from "react-router-dom"
import { PLATFORM_SETTINGS_SLUGS } from "utils/constants"

const isSettingEnabled = (key: string, settings: PlatformSettings) => {
  if (!settings || !settings[key]) {
    return false
  }
  return settings[key].value === "1"
}

const AppLayout = () => {
  const theme = useTheme<Theme>()
  const mdUp = useMediaQuery(theme.breakpoints.up("md"))
  const [searchParams] = useSearchParams()
  const { platformSettings } = useGlobalContext()
  const isApp = searchParams.get("suraasa-learn-app") === "true"

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _ = isSettingEnabled(
    PLATFORM_SETTINGS_SLUGS.showChatbotV1,
    platformSettings
  )
  const showChatBot = false

  const posthog = usePostHog()

  useEffect(() => {
    const displayScale = window.outerWidth / window.innerWidth
    posthog.capture("display_scale", {
      scale: displayScale,
    })
  }, [])

  return (
    <ProAccessProvider>
      <ConfirmDialogProvider>
        <ConfirmDialog />
        {!isApp && (
          <>
            <GlobalBanners />
            {/* <UploadResumeNudgeDialog /> */}
            {/* <AIToolsOnboarding /> */}
            <RyanSignUpOnboarding />
            <TopNavigation />
            {!mdUp && <BottomNavigation />}
            <GlobalCards />
          </>
        )}
        {showChatBot && <ChatBot />}
        {/* Do not put <BottomNavigation/> below <Outlet/> */}
        <div id="mainContent">
          <Outlet />
        </div>
      </ConfirmDialogProvider>
    </ProAccessProvider>
  )
}

export default AppLayout
