import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { format, sub } from "date-fns"
import { getNavigationPortal } from "features/SignupOnboarding/Navigation/utils"
import { FormPageProps } from "features/SignupOnboarding/types"
import { useState } from "react"

import { Calendar } from "@/components/Calendar"

const maxDate = sub(new Date(), {
  years: 14,
})

const minDate = sub(new Date(), {
  years: 100,
})

const DateOfBirth = ({ data, updateData, onNext, ...props }: FormPageProps) => {
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(data.dateOfBirth)

  const updateBackend = async (value: Date) => {
    try {
      await api.profile.update({
        data: { date_of_birth: format(value, "yyyy-MM-dd") },
      })
    } catch (e) {
      console.error("Failed to update backend:", e)
    }
  }

  const handleDateOfBirthSelection = (value: Date) => {
    setDateOfBirth(value)
    updateData({ dateOfBirth: value })
  }

  const handleSubmit = async () => {
    if (!dateOfBirth) return

    await updateBackend(dateOfBirth)
    await onNext()
  }

  const isDisabled = dateOfBirth === null

  return (
    <div className="flex flex-col items-start">
      <Typography variant="title2" color="onSurface.900">
        What is your date of birth?
      </Typography>
      <div className="mt-6"></div>

      <Popover>
        <PopoverTrigger className="w-full max-w-[380px] text-start">
          <TextField
            autoFocus
            readOnly
            label="Date of Birth"
            placeholder="Click here to select"
            value={dateOfBirth ? format(dateOfBirth, "PPP") : undefined}
          />
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            mode="single"
            selected={dateOfBirth ?? undefined}
            required
            onSelect={handleDateOfBirthSelection}
            endMonth={maxDate}
            startMonth={minDate}
          />
        </PopoverContent>
      </Popover>

      {getNavigationPortal({
        disableNext: isDisabled,
        onNext: handleSubmit,
        ...props,
      })}
    </div>
  )
}

export default DateOfBirth
