import axios from "api/axios"
import { APIResponse, Config, ConfigWithURLParams } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { Reference, ReferenceAnalytics } from "./types"
import { urls } from "./urls"

export default {
  listReferences: async (): Promise<APIResponse<Reference[]>> => {
    try {
      const res = await axios.get(urls.list())
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  createReference: async ({
    data,
  }: Config): Promise<APIResponse<Reference>> => {
    try {
      const res = await axios.post(urls.create(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  updateReference: async ({
    data,
    urlParams: { id },
  }: ConfigWithURLParams): Promise<APIResponse<Reference>> => {
    try {
      const res = await axios.put(urls.update(id), data)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },

  deleteReference: async ({
    urlParams: { id },
  }: ConfigWithURLParams): Promise<APIResponse<Reference>> => {
    try {
      const res = await axios.delete(urls.delete(id))
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },

  sendReminder: async ({
    data,
    urlParams: { id },
  }: ConfigWithURLParams): Promise<void> => {
    try {
      await axios.post(urls.sendReminder(id), data)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },

  referenceAnalytics: async ({
    urlParams: { userId },
  }: ConfigWithURLParams): Promise<APIResponse<ReferenceAnalytics>> => {
    try {
      const res = await axios.get(urls.referenceAnalytics(userId))
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
}
