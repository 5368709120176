import { ToolType } from "api/resources/aiTools/types"
import { getToolName } from "features/AItools/helper"

import Heading from "../../Heading"
import ActivityPlannerOutput from "../GenerationOutput/ActivityPlannerOutput"
import ContentActions from "../GenerationOutput/Export/ContentActions"
import ProForm from "./ProForm"
import { useActivityPlanner } from "./useActivityPlanner"

const ActivityPlannerGenerator = ({
  toggleHistorySideBar,
}: {
  toggleHistorySideBar: (state: boolean) => void
}) => {
  const {
    currentResponseId,
    setCurrentResponseId,
    responseIds,
    overviewData,
    generateTool,
    regenerateResponse,
    userVote,
    isPositiveResponse,
    output,
    isLoading,
    onBack,
    formData,
    stream,
    setFormData,
    ...activityContext
  } = useActivityPlanner()

  if (currentResponseId) {
    return (
      <>
        <ContentActions
          onBack={onBack}
          currentResponseId={currentResponseId}
          toolType={ToolType.activityPlanner}
          title={overviewData?.title}
          toggleHistorySideBar={toggleHistorySideBar}
          hideExportOutputButton={
            stream.isError || isLoading || currentResponseId === undefined
          }
        />

        <ActivityPlannerOutput
          regenerateResponse={regenerateResponse}
          isPositiveResponse={isPositiveResponse}
          userVote={userVote}
          overviewData={overviewData}
          responseIds={responseIds}
          currentResponseId={currentResponseId}
          isLoading={isLoading}
          output={output}
          setCurrentResponseId={setCurrentResponseId}
          onBack={onBack}
          stream={stream}
        />
      </>
    )
  }
  return (
    <>
      <Heading
        title={`${getToolName(ToolType.activityPlanner)} Generator`}
        subtitle="Elevate your teaching approach with the Activity Plan Generator, fostering interactive and impactful learning experiences."
        isPro
        toggleHistorySideBar={toggleHistorySideBar}
      />
      <ProForm
        generateTool={generateTool}
        formData={formData}
        setFormData={setFormData}
        preferredPromptData={activityContext.preferredPromptData}
      />
    </>
  )
}

export default ActivityPlannerGenerator
