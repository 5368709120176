import { Button, Dialog, DialogContent, Typography } from "@suraasa/placebo-ui"
import { ArrowRight, Clock } from "iconoir-react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import routes from "routes"
import { BROWSER_STORAGE_KEYS } from "utils/constants"
import { UserPreferenceManager } from "utils/userPreferenceManager"

import MainImage from "./assets/get_started.webp"

export const Popup = () => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const handleClose = () => {
    setOpen(false)
    UserPreferenceManager.set(
      BROWSER_STORAGE_KEYS.hasDismissedOptionalSignupOnboardingPopup,
      "true"
    )
  }

  useEffect(() => {
    const {
      signupOnboardingCompleted,
      signupOnboardingPromptMode,
      hasDismissedOptionalSignupOnboardingPopup,
      dashboardTourCompleted,
    } = BROWSER_STORAGE_KEYS

    const isDashboardTourCompleted =
      UserPreferenceManager.get(dashboardTourCompleted) === "true"

    if (!isDashboardTourCompleted) return

    const isCompleted =
      UserPreferenceManager.get(signupOnboardingCompleted) === "true"

    if (isCompleted) return

    const hasDismissed =
      UserPreferenceManager.get(hasDismissedOptionalSignupOnboardingPopup) ===
      "true"

    const mode = UserPreferenceManager.get(signupOnboardingPromptMode)

    if (!hasDismissed) {
      setOpen(mode === "optional")
    }
  }, [])

  const link = `${routes.signUpOnboarding}?utm_source=dashboard-popup&next=${window.location.pathname}`

  return (
    <Dialog open={open}>
      <DialogContent className="max-w-lg rounded-2xl">
        <div className="flex flex-col">
          <div className="h-[213px] w-full sm:h-[274px]">
            <img
              src={MainImage}
              alt="main-img"
              className="size-full rounded-t-2xl object-cover"
            />
          </div>
          <div className="p-3">
            <Typography
              variant="strongSmallBody"
              className="mb-1.5 flex items-center gap-0.5 text-onSurface-500"
            >
              <Clock />
              Takes 3 minutes
            </Typography>

            <Typography variant="title2" className="mb-1.5">
              Make the Most of Suraasa – Update Your Profile!
            </Typography>
            <Typography variant="largeBody" className="mb-5 text-onSurface-500">
              It helps us empower your growth with the right opportunities.
            </Typography>

            <div className="flex items-center justify-end gap-2">
              <Button variant="text" color="black" onClick={handleClose}>
                Maybe Later
              </Button>
              <Button
                autoFocus
                onClick={() => {
                  handleClose()
                  navigate(link)
                }}
                endAdornment={<ArrowRight className="!size-2" />}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
