import shuffle from "lodash/shuffle"

export type Testimonial = {
  quote: string
  name: string
  image: string
  designation: string
}

const careerAdvancementAndPersonalGrowth: Testimonial[] = [
  {
    quote:
      "Suraasa satisfied my thirst for teaching and helped me get a job at a reputed school with an almost 50% salary hike.",
    name: "Tulsi Dutta",
    image:
      "https://cdn.prod.website-files.com/6156c92cb3f1a26201745fa2/62e10f315a47c7a7a267e6d2_Tulsi%20Dutta.avif ",
    designation: "Pre-primary Teacher",
  },
  {
    quote:
      "With the help of wonderful mentors, I upgraded and implemented my knowledge of learning and teaching as a 21st-century teacher. And I achieved my goal of getting a job in the USA.",
    name: "Neha Choudhry",
    image:
      "https://cdn.prod.website-files.com/6156c92cb3f1a26201745fa2/62e117ac8c1c3a030313c0a7_Neha%20Chaudhry.avif",
    designation: "Primary Teacher",
  },
  {
    quote:
      "Suraasa helped me believe in myself as a teacher. I even got placed in a Public School in the USA after completing my program with Suraasa.",
    name: "Saratha",
    image:
      "https://cdn.prod.website-files.com/6156c92cb3f1a26201745fa2/62ed27ed8ef10892ff160e88_Saratha%201.avif",
    designation: "Elementary STEM Teacher",
  },
  {
    quote:
      "I felt this was one program by Suraasa that fits all approaches. I also got 4 job interviews and with Suraasa's help, I aced 2 interviews. I finally fetched my dream job in the USA.",
    name: "Reena Ramesh",
    image:
      "https://cdn.prod.website-files.com/6156c92cb3f1a26201745fa2/62f692314666f05920a28633_Reena%20Ramesh%20USA.avif",
    designation: "Primary Teacher",
  },
  {
    quote:
      "I became a Team Leader once and Teacher of the Month twice. I also saw a positive curve in my growth as I was promoted to the Head of Department in my school, which put me in charge of 17 teachers.",
    name: "Ethel Sojo",
    image:
      "https://cdn.prod.website-files.com/6156c92cb3f1a26201745fa2/62e10357fea1924a56528edf_Ethel%20sojo.avif",
    designation: "Head of Department",
  },
  {
    quote:
      "I can see a lot of difference in my teaching methodologies after I enrolled in Suraasa. I also got a job opportunity in the UAE after completing the program.",
    name: "Komal Mule",
    designation: "Computer Teacher",

    image:
      "https://cdn.prod.website-files.com/6156c92cb3f1a26201745fa2/62e10d0afea1923500530a57_Komal%20Mule%20.avif",
  },

  {
    quote:
      "I was successfully promoted from Teacher to Headmistress within a short span of time and I achieved incredible career growth with a significant salary hike.",
    name: "Vertika Mishra",
    designation: "Headmistress",
    image:
      "https://cdn.prod.website-files.com/6156c92cb3f1a26201745fa2/62e118ec1449c992281f722d_Vertika%20Mishra%20.avif",
  },
]

const workExperienceAndBackground: Testimonial[] = [
  {
    quote:
      "I have been teaching for 5 years and Suraasa's program has had a profound impact on my teaching journey. From learning differentiated instruction to mastering effective feedback, I've become a better teacher. The reflection techniques and guidance on personal growth have also empowered me to be a role model for my students.",
    name: "Rekha Joly",
    image:
      "https://cdn.prod.website-files.com/6156c92cb3f1a26201745fa2/62e10c935da41eaed218f44c_Rekha%20Joly.avif",
    designation: "English Instructor",
  },
  {
    quote:
      "Suraasa has truly refined my understanding of compassion and empathy in teaching. After years of teaching, I can now take care of the emotional well-being alongside academic progress of my students. It has transformed my approach to teaching, and I am now more confident in nurturing future champions with character and competence.",
    name: "Chandi Sharma",
    image:
      "https://assets.suraasa.com/lms/onboarding-assets/testimonials/Chandi Sharma.webp",
    designation: "Language & Literature Facilitator",
  },
  {
    quote:
      "Though I have 6 years of experience, after joining Suraasa, I saw significant improvements. My teaching skills are enhanced through new lesson plan formats, methodologies, and effective use of technology. Suraasa provided me with the tools and knowledge to excel in my profession and positively impact my life.",
    name: "Bilal Raza",
    image:
      "https://assets.suraasa.com/lms/onboarding-assets/testimonials/Bilal Raza.webp",
    designation: "English Language Teacher",
  },
  {
    quote:
      "I am able to apply new skills and strategies learned from Suraasa directly in my classroom, leading to tangible improvements in student engagement. Beyond my 7 years of professional background, my journey with Suraasa has fostered personal growth, instilling a sense of determination and resilience necessary to pursue and achieve my career goal of teaching in the USA.",
    name: "Reena Ramesh",
    image:
      "https://cdn.prod.website-files.com/6156c92cb3f1a26201745fa2/62f692314666f05920a28633_Reena%20Ramesh%20USA.avif",
    designation: "Primary Teacher",
  },
  {
    quote:
      "My journey is kind of going reverse, as in teaching first and getting the certification later. Although I have 20 years of teaching experience, I've always worked with coaching centers and academies. I've never worked in a school. Suraasa has been a game changer for me, giving me deeper insights into what teaching truly is, and helping me fetch my first job in a school.",
    name: "Ritu Sahni",
    image:
      "https://assets.suraasa.com/lms/onboarding-assets/testimonials/Ritu Sahni.webp",
    designation: "English Teacher",
  },
  {
    quote:
      "Suraasa allowed me to integrate technology like smart boards and Google Classroom, boosting student engagement. I gained 21st-century teaching skills and increased my effectiveness in international settings, enhancing my confidence in teaching diverse students, even though I already had 11 years of teaching experience.",
    name: "Abiola John Opeagbe",
    image:
      "https://assets.suraasa.com/lms/onboarding-assets/testimonials/Abiola John Opeagbe.webp",
    designation: "Business and Economics Teacher",
  },
]

const teachingSpecializations: Testimonial[] = [
  {
    quote:
      "Suraasa helped me land the role of Chemistry teacher (Grade 8-10) at Al Ain Junior Group of Schools, UAE. I thank each and everyone at Suraasa for their guidance and support at each stage of my learning.",
    name: "Sonu Radheshyam Soni",
    image:
      "https://assets.suraasa.com/lms/onboarding-assets/testimonials/Sonu Soni.webp",
    designation: "Chemistry Teacher",
  },
  /// NO IMAGE PRESENT FOR THIS TESTIMONIAL, NEITHER IS THIS PRESENT IN THE FIGMA FILE
  // {
  //   quote:
  //     "Suraasa helped me get a job at Candor International School as an IGCSE MYP and High School Computer Science Teacher.",
  //   name: "Krishna Sachdeva",
  //   designation: "Computer Science Teacher",
  // },
  {
    quote:
      "Suraasa helped me adopt modern tools like smartboards and AI to create interactive, student-centered lessons, boosting classroom engagement and improving students' understanding of complex concepts in my subject.",
    name: "Rahul Chakraborty",
    image:
      "https://assets.suraasa.com/lms/onboarding-assets/testimonials/Rahul Chakraborty.webp",
    designation: "Accountancy and Statistics Teacher",
  },
  {
    quote:
      "I learned student-centered strategies like differentiation and gamification, which significantly improved engagement. The program also helped me transition into leadership roles, becoming a Principal and later teaching in a Cambridge school in Indonesia.",
    name: "Pawan Bisht",
    image:
      "https://assets.suraasa.com/lms/onboarding-assets/testimonials/Pawan Bisht.webp",
    designation: "Physics & Math Teacher",
  },
  {
    quote:
      "I adopted techniques from Bloom’s Taxonomy and Socratic methods, boosting active learning. I gained confidence, created engaging lessons, and took on leadership roles, including joining the CBSE Action Research Program.",
    name: "Monica Srivastava",
    image:
      "https://assets.suraasa.com/lms/onboarding-assets/testimonials/Monica Srivastava.webp",
    designation: "Facilitator & Coordinator",
  },
  {
    quote:
      "I incorporated game-based learning and student-centered methods, making my lessons more engaging. The program also boosted my confidence in handling international teaching opportunities with a well-rounded, 21st-century approach.",
    name: "Ashwin James Lal",
    image:
      "https://assets.suraasa.com/lms/onboarding-assets/testimonials/Ashwin James Lal.webp",
    designation: "World History Teacher",
  },
  {
    quote:
      "I learned modern techniques and gained practical knowledge with mentor support. Now, as a Business Studies teacher, I feel empowered to make a meaningful impact by blending my corporate experience with my teaching skills.",
    name: "Baishali Nandi",
    image:
      "https://assets.suraasa.com/lms/onboarding-assets/testimonials/Baishali Nandi.webp",
    designation: "Business Studies Teacher",
  },
]

const generalPositiveFeedback: Testimonial[] = [
  {
    quote:
      "Suraasa improved my understanding of educational strategies, emphasizing differentiation and individualized instruction. I gained modern tools and assessment strategies to tailor my approach, boosting my confidence in teaching.",
    name: "Sikhanyiso Maviza Loveness",
    image:
      "https://assets.suraasa.com/lms/onboarding-assets/testimonials/Sikhanyiso Maviza Loveness.webp",
    designation: "Early Intervention Teacher",
  },
  {
    quote:
      "I learned Lesson Planning strategies and tools and started seeing an increase in student participation in my classroom",
    name: "Mounica Chowdhary",
    image:
      "https://cdn.prod.website-files.com/6156c92cb3f1a26201745fa2/62e10515e8fdad815c616b68_Mounica%20Chowdhary-min.avif",
    designation: "Montessori School Teacher",
  },
  {
    quote:
      "I learned modern-day teaching and pedagogical skills to become a future-ready teacher. Suraasa has given teachers hope to make them succeed in their journey and fulfill their dreams.",
    name: "Nikhil Menon",
    image:
      "https://cdn.prod.website-files.com/6156c92cb3f1a26201745fa2/62e10b8d0d200ada92e24701_Nikhil.avif",
    designation: "Science Teacher",
  },
  {
    quote:
      "Suraasa gave me the qualifications and teaching strategies to effectively teach science and health subjects at international schools, where I now apply advanced methods in my lessons.",
    name: "Shaikh Abdulla",
    image:
      "https://assets.suraasa.com/lms/onboarding-assets/testimonials/Shaikh Abdulla.webp",
    designation: "Lab Teacher",
  },
  {
    quote:
      "I have gained quite a lot of information—things I wasn't aware of before. Now, I know how to handle different students with different learning styles. Thanks to Suraasa, I feel confident when I enter a classroom. I know I can handle any challenge and have a plan B if something doesn’t work.",
    name: "Ghousia Tasneen Hashmi",
    image:
      "https://assets.suraasa.com/lms/onboarding-assets/testimonials/Ghousia Tasneem Hashmi.webp",
    designation: "Primary Teacher",
  },
  {
    quote:
      "Suraasa gave me the skills to create interactive, student-centered lessons and manage classrooms effectively, helping me build stronger student relationships and positively impact their learning.",
    name: "Wajiha Aamir",
    image:
      "https://assets.suraasa.com/lms/onboarding-assets/testimonials/Wajiha Aamir.webp",
    designation: "Head of Cycle KS1 & KS2",
  },
]

export const testimonials = shuffle([
  ...careerAdvancementAndPersonalGrowth,
  ...workExperienceAndBackground,
  ...teachingSpecializations,
  ...generalPositiveFeedback,
])
