import { AuthData } from "api/resources/users/types"
import { ValueOf } from "utils/types"

import { ReattemptType, UserReattemptCounterType } from "../assessments/types"
import { AccessStatus, UserPlannerItemStatus } from "../learningItems/types"

export const SUBMISSION_STATUS = {
  underReview: "under_review",
  notReviewed: "not_reviewed",
  savedForLater: "saved_for_later",
  accepted: "accepted",
  rejected: "rejected",
  plagiarised: "plagiarised",
} as const

export const IQA_STATUS = {
  underReview: "under_review",
  accepted: "accepted",
  rejected: "rejected",
  plagiarised: "plagiarised",
} as const

export const REVIEW_STATUS = {
  underReview: "under_review",
  notReviewed: "not_reviewed",
  accepted: "accepted",
  rejected: "rejected",
  plagiarised: "plagiarised",
} as const
// export type ReattemptRequestStatusEnum =
//   | "under_review"
//   | "accepted"
//   | "rejected"
export enum ReattemptRequestStatusEnum {
  UNDER_REVIEW = "under_review",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
}
export type AssignmentList = {
  id: number
  dueDate: string | null
  iqaReviewStatus: ValueOf<typeof IQA_STATUS> | null
  submissionStatus: ValueOf<typeof SUBMISSION_STATUS> | null
  uuid: string
  title: string
  shortTitle: string
  submissionUuid: string | null
  accessStatus: AccessStatus
  result?: SubmissionResultEnum | null
  submissionRejectionReason?: {
    previousStatus: ValueOf<typeof SUBMISSION_STATUS> | null
    reattemptRequestApproved: boolean | null
  } | null
  reattemptType?: ReattemptType
  userReattemptCounter?: UserReattemptCounterType | null
  reattemptRequest?: ReattemptRequestStatusEnum | null
}

export type AssignmentAttachment = {
  url: string
  name: string
}

export type SubmissionAttachment = {
  id: number
  name: string
}

export type AssignmentFAQ = {
  question: string
  answer: string | JSX.Element
}

export enum ExtensionRequestStatus {
  UNDER_REVIEW = "under_review",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  CANCELLED = "cancelled",
}
export type Assignment = {
  id: number
  reviewStatus: ValueOf<typeof REVIEW_STATUS>
  assignmentAssessmentCriteria: {
    name: string
    number: string
    learningOutcome: {
      name: string
      number: string
    }
  }[]
  attachments: AssignmentAttachment[]
  dueDate: string
  status: "overdue" | "pending"
  uuid: string
  title: string
  shortTitle: string
  instructions: string
  duration: string
  wordLimit: number | null
  allowAttachments: boolean
  allowCitations: boolean
  faqs?: AssignmentFAQ[]
  extensionRequest?: ExtensionRequestStatus | null
  reattemptType?: ReattemptType
  userReattemptCounter?: UserReattemptCounterType | null
  reattemptRequest?: ReattemptRequestStatusEnum | null
  rejectionReason: RejectionReasonType | null
}
export type RejectionReasonType = {
  reattemptRequestApproved: boolean
  previousStatus: ValueOf<typeof SUBMISSION_STATUS>
}

type RubricComment = {
  uuid: string
  comments: string
  scale: number
}

export type Rubric = {
  criteria: string
  task: string | null
  allComments: RubricComment[]
}

export type ReviewRubric = Rubric & {
  id: string
  marked: RubricComment
}

export type SubmissionsList = {
  dateCreated: string
  marks: number
  qaStatus: string | null
  review: string
  uuid: string
}[]

export enum SubmissionResultEnum {
  PASSED = "passed",
  FAILED = "failed",
}
export type Submission = {
  uuid: string
  submission: string
  assignment: Pick<Assignment, "title" | "shortTitle" | "uuid" | "instructions">
  attachments: SubmissionAttachment[]
  review: Review | null
  iqaReview: Review | null
  references: string | null
  citations: string | null
  status: ValueOf<typeof SUBMISSION_STATUS>
  user: Pick<AuthData["user"], "uuid">
  result: SubmissionResultEnum | null
}

export type Review = {
  uuid: string
  status: ValueOf<typeof REVIEW_STATUS>
  marks: number | null
  comments: string
}

export type DueAssignmentList = {
  id: number
  dueDate: string
  uuid: string
  title: string
  shortTitle: string
}

export type LearningContentType =
  | "course"
  | "assessment"
  | "assignment"
  | "article"
  | "class"
  | "video"
  | "discussion"
  | "scheduled_assessment"
  | "activity"

export type UserPlannerItemData = {
  id: number
  learningContentId: number
  learningContentType: LearningContentType
  status: UserPlannerItemStatus
  learningContent: {
    id: number
    uuid: string
    title: string
    shortTitle: string
    contentType: LearningContentType
  }
}
