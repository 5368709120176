import {
  Country,
  Currency,
  Curricula,
  Language,
  QualificationField,
  State,
  Subject,
  TeachingLevel,
  TeachingRole,
} from "api/resources/global/types"
import { ValueOf } from "utils/types"

import { Reference } from "../references/types"
import { PhoneNumber } from "../users/types"

export enum ModeOfLearning {
  ONLINE = "online",
  CLASSROOM = "classroom",
  BLENDED = "blended",
}

export enum LanguageProficiency {
  ELEMENTARY = "elementary",
  LIMITED_WORKING = "limited_working",
  PROFESSIONAL_WORKING = "professional_working",
  FULL_PROFESSIONAL = "full_professional",
  NATIVE = "native",
}

export const modeOfLearningChoices = [
  { label: "Online", value: ModeOfLearning.ONLINE },
  {
    label: "Classroom Programme (offline)",
    value: ModeOfLearning.CLASSROOM,
  },
  {
    label: "Blended (Online & Classroom)",
    value: ModeOfLearning.BLENDED,
  },
]

export enum EmploymentType {
  FULL_TIME = "full_time",
  PART_TIME = "part_time",
  FRESHER = "fresher",
  INTERN = "intern",
  FREELANCE = "freelance",
  SELF_EMPLOYED = "self_employed",
}

export type QualificationLevel = {
  uuid: string
  name: string
}

/**
 * Using object literal instead of enum to use null value.
 * Organisation type can either be null or an integer in the range of 1-3
 */
export const OrganisationType = {
  SCHOOL: "school",
  COLLEGE_OR_UNIVERSITY: "college_or_university",
  TUTORING: "tutoring",
  OTHERS: null,
} as const

export type WorkExperienceType = {
  id: string
  title: string
  employmentType: EmploymentType | null
  description: string | null
  organisationName: string
  organisationType: ValueOf<typeof OrganisationType> | null
  otherOrganisationType: string | null
  countryId: string | null
  country: Country | null
  stateId: string | null
  state: State | null
  currentlyWorking: boolean
  startDate: string | null // Date
  endDate: string | null // Date
  salary: number | null
  currencyId: string | null

  curriculum: Curricula | null
  teachingLevel: TeachingLevel | null
  teachingRole: TeachingRole | null

  curriculumId: string | null
  teachingLevelId: string | null
  teachingRoleId: string | null
  // subjects: { subjectId: string; name: string }[]

  subjects: {
    subjectId: string
    subject: {
      id: number
      uuid: string
      name: string
      addedById: string | null
      isApproved: boolean
    }
  }[]

  currency: Currency | null
  references?: Reference[]
}

export enum EvidenceType {
  URL = 1,
  File,
}

type BaseEvidence = {
  id: string
}

export type Evidence =
  | (BaseEvidence & {
      evidenceType: EvidenceType.URL
      url: string
    })
  | (BaseEvidence & {
      evidenceType: EvidenceType.File
      file: string
      fileName: string
    })

export enum UserAcademicStatus {
  IN_PROGRESS = 1,
  COMPLETED = 2,
  SUSPENDED = 3,
}

export type Qualification = {
  id: number
  organisationName: string
  name: string
  qualificationLevelId: string
  qualificationFieldId: string
  // This is due to old data imported from orbit. qualificationField doesn't exist there
  qualificationField: QualificationField | null
  qualificationLevel: QualificationLevel | null
  startDate: string // Date
  // This is due to add old data imported from orbit
  endDate: string | null // Date
  grade: string | null
  modeOfLearning: ModeOfLearning | null
  isVerified: boolean
  evidences?: Evidence[]
  status: UserAcademicStatus | null
  references?: Reference[] | null
}

export type Certification = {
  id: number
  name: string
  organisationName: string
  willExpire: boolean
  completionDate: string // Date
  expirationDate: string | null // Date
  modeOfLearning: ModeOfLearning | null
  isVerified: boolean
  evidences?: Evidence[]
}

export type LanguageResponse = {
  proficiency: LanguageProficiency
  languageId: string
  id: number
  language: Language
}

export type WorkLocationInterest = {
  countryId: string
  stateId: string | null
  city: string | null
  id: number
  country: Country
  state: State | null
  sequence: number
}

export type Skill = {
  id: string
  skillName: string
  sequence: number
}

export type Interest = {
  id: string
  interest: string
}

export type Publication = {
  id: string
  title: string
  publisher: string
  publishedOn: string | null // ISODate
  description: string | null
  url: string | null
}

export type TestScore = {
  id: string
  name: string
  score: string
  testDate: string | null // ISODate
  description: string | null
  evidenceDocumentName: string
  evidenceDocument: string | null
  evidenceUrl: string | null // url validations apply
}

export type Award = {
  id: string
  title: string
  issuer: string
  issuedOn: string | null
  description: string | null
  certificate?: string | null
}

export type Project = {
  id: string
  title: string
  currentlyWorking: boolean
  startDate: string // ISODate
  endDate: string | null // ISODate
  url: string | null
  description: string | null
}

export type SubjectResponse = {
  subjectId: string
  id: number
  subject: Subject
  sequence: number
}

export enum Gender {
  MALE = "male",
  FEMALE = "female",
  PREFER_NOT_TO_SAY = "prefer_not_to_say",
}

export const GenderMapping: { [key in Gender]: string } = {
  [Gender.MALE]: "Male",
  [Gender.FEMALE]: "Female",
  [Gender.PREFER_NOT_TO_SAY]: "Prefer not to say",
}
export enum CoverStyle {
  CANDY = "candy",
  DAWN = "dawn",
  AURORA = "aurora",
  OCEAN = "ocean",
  TERRA = "terra",
  DUSK = "dusk",
  OPAL = "opal",
}

export type Profile = {
  id: string
  totalWorkingExperience: number // in years
  coverStyle?: CoverStyle
  resume?: string | null
  careerAspiration: string | null
  countriesWorkedIn: { name: string }[]
  currentWorkingExperience?:
    | (Pick<WorkExperienceType, "title" | "organisationName"> & {
        subjects?: string[]
        teachingLevel?: string
      })
    | null
  yearsOfExperience: number | null
  country: Country | null
  dateOfBirth: string | null
  gender: Gender | null
  isVerified: boolean
  lookingForJobs: boolean | null
  nationality: Country | null
  city: string | null
  state: State | null
  phoneNumber: PhoneNumber | null
  picture: string | null
  user: {
    email: string
    firstName: string
    lastName: string | null
    middleName: string
    username: string
    uuid: string
    source?: string | null
  }
}

export type SkillEvidenceOverview = {
  advancedSkillCount: number
  beginnerSkillCount: number
  intermediateSkillCount: number
  professional: number
}

export type SkillEvidence = {
  name: string
  slug: string
  evidences: {
    submission: string
    title: string
    uuid: string
    visibility?: boolean
  }[]
}

export type MiscData = {
  desiredMilestones: string[] | null
  currentlyTeachingInSchool: boolean | null
  hasExperienceInTeaching: boolean | null
}

export type AdditionalData = {
  gradeInterests: string[] | null
  gradesTaught: string[] | null
  curriculaTaught: string[] | null
}

export type Organization = {
  id: number
  name: string
  slug: string
  abbreviation: string
  imageUrl: string | null
}

export type LearningCertificate = {
  id: string
  userId: string
  holderName: string
  serialNumber: string
  issuedAt: string
  organization: Organization
  item: {
    uuid: string
    name: string
    image: string | null
    slug: string
    itemType:
      | "course"
      | "assessment"
      | "assignment"
      | "package"
      | "certification"
      | "qualification"
    itemLevel: string | null
    duration: number | null // in days
  }
  file: string
}

export type ITOCertificate = {
  id: string
  userId: string
  holderName: string
  serialNumber: string
  issuedAt: string
  organization: Organization
  highResImage: string | null
  lowResImage: string | null
  additionalDetails?: {
    registeredUser?: {
      country?: Pick<Country, "name" | "uuid">
      state?: Pick<State, "name" | "uuid">
    }
  }
  item: {
    id: string
    name: string
    image: string | null
    slug: string
    itemType: "olympiad_award"
    olympiad: {
      id: number
      name: string
      slug:
        | "international-teacher-olympiad-2022"
        | "international-teacher-olympiad-2023"
      year: 2022 | 2023
    }
  }
  file: string
}

export type MasterclassCertificate = {
  certificate: string
  issuedAt: string
  masterclass: {
    title: string
    slug: string
    thumbnail: string | null
  }
  registrationId: string
}

export type CourseCompletionCertificate = Pick<
  LearningCertificate,
  "id" | "issuedAt" | "holderName" | "serialNumber"
> & {
  visibleOnProfile: boolean
  highResImage: string
  lowResImage: string
  pdfFile: string
}
