import { IconButton, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { ProfileQuickAccessTabs } from "features/Profile/utils"
import { Edit, Plus } from "iconoir-react"
import React, { useContext, useState } from "react"
import { createUseStyles } from "react-jss"

import ProfileContext from "../../context"
import Section from "../Section"
import SectionContent from "../Section/SectionContent"
import SectionHeading from "../Section/SectionHeading"
import SectionTitle from "../Section/SectionTitle"
import EditDialog from "./EditDialog"

const useStyles = createUseStyles({
  sectionContainer: {
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
})

const About = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const classes = useStyles()
  const toggle = () => setOpenDialog(!openDialog)

  const {
    updateProfile,
    isPublic,
    profile: { careerAspiration },
  } = useContext(ProfileContext)

  return (
    <>
      <EditDialog
        data={{ careerAspiration: careerAspiration || "" }}
        setOpen={setOpenDialog}
        open={openDialog}
        onUpdate={() => {
          updateProfile()
        }}
      />
      <Section id={ProfileQuickAccessTabs.about}>
        <SectionHeading
          heading={
            <SectionTitle
              actionIcon={
                isPublic ? undefined : (
                  <IconButton onClick={toggle}>
                    {careerAspiration ? (
                      <Edit className="!size-3" />
                    ) : (
                      <Plus className="!size-3" />
                    )}
                  </IconButton>
                )
              }
              title="About"
            />
          }
          xPadding={2}
        />
        <SectionContent className={classes.sectionContainer} xPadding={2}>
          <Typography
            variant="smallBody"
            className={clsx({
              "text-onSurface-800": careerAspiration,
              "text-onSurface-400": !careerAspiration,
            })}
          >
            {careerAspiration ||
              (isPublic
                ? "-"
                : "Tell viewers about yourself and your career aspiration")}
          </Typography>
        </SectionContent>
      </Section>
    </>
  )
}

export default About
