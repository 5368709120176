import { Avatar, Button, cn, IconButton, Typography } from "@suraasa/placebo-ui"
import { context } from "global/Context/context"
import { useFeatureToggle } from "global/FeatureToggleProvider"
import { NavArrowLeft, NavArrowRight } from "iconoir-react"
import { useContext } from "react"
import { Link } from "react-router-dom"
import routes from "routes"
import { BROWSER_STORAGE_KEYS, signupSources } from "utils/constants"
import { buildUserName } from "utils/helpers"
import { UserPreferenceManager } from "utils/userPreferenceManager"

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/Carousel"

import logo from "./assets/Suraasa.webp"
import { Popup } from "./Popup"

export const CompleteOnboardingCards = () => {
  const featureToggles = useFeatureToggle()

  const signupOnboardingCompleted =
    UserPreferenceManager.get(
      BROWSER_STORAGE_KEYS.signupOnboardingCompleted
    ) === "true"

  const profileTourCompleted =
    UserPreferenceManager.get(BROWSER_STORAGE_KEYS.profileTourCompleted) ===
    "true"

  if (!featureToggles.platformOnboarding.isEnabled) return null

  const banners = [
    {
      key: "signupOnboardingCompleted",
      value: signupOnboardingCompleted,
      component: <CompleteSignupOnboarding />,
    },
    {
      key: "profileTourCompleted",
      value: profileTourCompleted,
      component: <ExploreProfileCard />,
    },
  ].filter(item => !item.value)

  if (banners.length === 0) {
    return null
  }

  if (banners.length === 1) {
    return <div className="mb-2">{banners[0].component}</div>
  }

  return (
    <Carousel className="mb-3">
      <div className="mb-2 flex items-center justify-between gap-2">
        <Typography variant="title3">Take the Next Step</Typography>
        <div className="flex items-center gap-0.5">
          <CarouselPrevious
            render={props => (
              <IconButton
                variant="plain"
                className="rounded-full"
                disabled={!props.canScrollPrev}
                onClick={props.scrollPrev}
                color="black"
              >
                <NavArrowLeft />
              </IconButton>
            )}
          />
          <CarouselNext
            render={props => (
              <IconButton
                variant="plain"
                className="rounded-full"
                disabled={!props.canScrollNext}
                onClick={props.scrollNext}
                color="black"
              >
                <NavArrowRight />
              </IconButton>
            )}
          />
        </div>
      </div>
      <CarouselContent>
        {banners.map(item => (
          <CarouselItem key={item.key}>{item.component}</CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
  )
}

const CompleteSignupOnboarding = ({ className = "" }) => {
  const signupOnboardingCompleted =
    UserPreferenceManager.get(
      BROWSER_STORAGE_KEYS.signupOnboardingCompleted
    ) === "true"

  if (signupOnboardingCompleted) {
    return null
  }

  const link = `${routes.signUpOnboarding}?utm_source=dashboard-card&next=${window.location.pathname}`

  return (
    <>
      <Popup />
      <div
        className={cn(
          "relative overflow-hidden rounded-2xl text-black",
          "bg-[linear-gradient(92.69deg,#FBF8F2_1.83%,#FFF7E7_98.6%)]",
          "p-2.5",
          className
        )}
      >
        <Typography variant="preTitle" className="mb-2 text-[#9A8671]">
          GET STARTED
        </Typography>

        <div className="flex flex-wrap items-center justify-between gap-2">
          <div className="flex gap-1 sm:flex-row">
            <img
              src={logo}
              alt="logo"
              className="hidden w-[70px] object-contain md:block"
            />
            <div>
              <Typography variant="title3" className="mb-0.5">
                Make the Most of Suraasa - Update Your Profile!
              </Typography>
              <Typography
                variant="smallBody"
                className="max-w-[420px] text-onSurface-700 xl:max-w-full"
              >
                Completing your profile helps us connect you with the right
                opportunities to support your growth.
              </Typography>
            </div>
          </div>
          <Link to={link} className="w-full sm:max-w-[150px]">
            <Button color="black" variant="filled" size="md" className="w-full">
              Continue
            </Button>
          </Link>
        </div>
      </div>
    </>
  )
}

const ExploreProfileCard = ({ className = "" }) => {
  const profileTourCompleted =
    UserPreferenceManager.get(BROWSER_STORAGE_KEYS.profileTourCompleted) ===
    "true"

  const { profile } = useContext(context)
  const firstName = profile.user.firstName
  const fullName = buildUserName(profile.user)

  if (profileTourCompleted) {
    return null
  }

  const content = (() => {
    switch (profile.user.source) {
      case signupSources.talkToAMentor:
        return {
          title: "Let’s get your profile ready for your mentor!",
          description:
            "Completing your profile helps your mentor get to know you better, ensuring a more personalized and productive session.",
        }
      default:
        return {
          title: "Let's make your profile shine!",
          description:
            "A complete and updated profile not only reflects who you are but also helps you stand out from the competition.",
        }
    }
  })()

  const link = `${routes.profile.self}?utm_source=dashboard-card`

  return (
    <div
      className={cn(
        "relative overflow-hidden rounded-2xl text-black",
        "bg-[linear-gradient(92.69deg,#F3F3F3_1.83%,#F3EAFC_93.57%)]",
        "p-2.5",
        className
      )}
    >
      <Typography variant="preTitle" className="mb-2 text-decorative-two-100">
        GET STARTED
      </Typography>

      <div className="flex flex-wrap items-center justify-between gap-2">
        <div className="flex gap-1 sm:flex-row">
          <Avatar name={fullName} src={profile.picture} className="size-6" />
          <div>
            <Typography variant="title3" className="mb-0.5">
              Hi {firstName}, {content.title}
            </Typography>
            <Typography
              variant="smallBody"
              className="max-w-[420px] text-onSurface-700 xl:max-w-full"
            >
              {content.description}
            </Typography>
          </div>
        </div>
        <Link to={link} className="w-full sm:max-w-[150px]">
          <Button color="black" variant="filled" size="md" className="w-full">
            View Profile
          </Button>
        </Link>
      </div>
    </div>
  )
}
