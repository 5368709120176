import { Typography } from "@suraasa/placebo-ui"
import api from "api"
import CheckBoxTextContainer from "features/SignupOnboarding/CheckBoxTextContainer"
import { getNavigationPortal } from "features/SignupOnboarding/Navigation/utils"
import { FormPageProps } from "features/SignupOnboarding/types"
import { useState } from "react"

export enum CurriculumOptions {
  CBSE = "CBSE",
  ICSE_ISC = "ICSE/ISC",
  StateBoardsIndia = "State Boards (India)",
  InternationalBaccalaureateIB = "International Baccalaureate (IB)",
  CambridgeCAIE = "Cambridge (CAIE)",
  BritishCurriculum = "British Curriculum",
  AmericanCurriculum = "American Curriculum",
}
export const getCurriculumFromValue = (
  value: string
): CurriculumOptions | undefined => {
  return Object.values(CurriculumOptions).find(option => option === value)
}

const options = Object.values(CurriculumOptions)

const CurriculumInformation = ({
  data,
  updateData,
  onNext,
  ...props
}: FormPageProps) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    data.curricula
  )

  const updateBackend = async (options: string[]) => {
    try {
      await api.profile.updateAdditionalData({
        data: {
          curricula_taught: options,
        },
      })
    } catch (e) {
      console.error("Failed to update backend:", e)
    }
  }

  const toggleSelection = (selectedOption: string) => {
    const newOptions = selectedOptions.includes(selectedOption)
      ? selectedOptions.filter(option => option !== selectedOption)
      : [...selectedOptions, selectedOption]

    setSelectedOptions(newOptions)
    updateData({
      curricula: newOptions,
    })
  }

  const handleSubmit = async () => {
    await updateBackend(selectedOptions)
    await onNext()
  }

  const isDisabled = selectedOptions.length === 0

  return (
    <div>
      <Typography variant="title2" color="onSurface.900">
        {data.isTeachingInSchool
          ? "Which curricula are you currently teaching?"
          : "Which curricula have you taught?"}
      </Typography>
      <div className="mt-4 flex flex-wrap gap-1">
        {options.map((option, index) => (
          <CheckBoxTextContainer
            key={index}
            text={option}
            minSize={false}
            isSelected={selectedOptions.includes(option)}
            onClick={() => toggleSelection(option)}
          />
        ))}
      </div>

      {getNavigationPortal({
        disableNext: isDisabled,
        onNext: handleSubmit,
        ...props,
      })}
    </div>
  )
}

export default CurriculumInformation
