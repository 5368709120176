import { Typography } from "@suraasa/placebo-ui"
import api from "api"
import CheckBoxTextContainer from "features/SignupOnboarding/CheckBoxTextContainer"
import { getNavigationPortal } from "features/SignupOnboarding/Navigation/utils"
import { FormPageProps } from "features/SignupOnboarding/types"
import { useState } from "react"

import {
  CurriculumOptions,
  getCurriculumFromValue,
} from "../CurriculumInformation"

const defaultGrades = [
  "Pre Primary",
  "Grade 1",
  "Grade 2",
  "Grade 3",
  "Grade 4",
  "Grade 5",
  "Grade 6",
  "Grade 7",
  "Grade 8",
  "Grade 9",
  "Grade 10",
  "Grade 11",
  "Grade 12",
]

const gradeOptionsForIB = [
  "Early Years (EY1)",
  "Primary Year 1",
  "Primary Year 2",
  "Primary Year 3",
  "Primary Year 4",
  "Primary Year 5",
  "Primary Year 6",
  "Middle Years Programme (MYP) 1",
  "Middle Years Programme (MYP) 2",
  "Middle Years Programme (MYP) 3",
  "Middle Years Programme (MYP) 4",
  "Diploma Programme (DP) 1",
  "Diploma Programme (DP) 2",
]

const gradeOptionsForCambridge = [
  "Foundation Stage",
  "Year 1 (Primary)",
  "Year 2 (Primary)",
  "Year 3 (Primary)",
  "Year 4 (Primary)",
  "Year 5 (Primary)",
  "Year 6 (Primary)",
  "Year 7 (Secondary)",
  "Year 8 (Secondary)",
  "Year 9 (Secondary)",
  "Year 10 (Secondary)",
  "Year 11 (Secondary)",
  "Year 12 (Secondary)",
]

const gradeOptionsForBritishCurriculum = [
  "Reception (Early Years)",
  "Year 1 (Key Stage 1)",
  "Year 2 (Key Stage 1)",
  "Year 3 (Key Stage 2)",
  "Year 4 (Key Stage 2)",
  "Year 5 (Key Stage 2)",
  "Year 6 (Key Stage 2)",
  "Year 7 (Key Stage 3)",
  "Year 8 (Key Stage 3)",
  "Year 9 (Key Stage 3)",
  "Year 10 (Key Stage 4)",
  "Year 11 (Key Stage 4)",
  "Year 12 (Sixth Form)",
]

const americanCurriculum = [
  "Pre-K / Kindergarten",
  "Grade 1",
  "Grade 2",
  "Grade 3",
  "Grade 4",
  "Grade 5",
  "Grade 6",
  "Grade 7",
  "Grade 8",
  "Grade 9",
  "Grade 10",
  "Grade 11",
  "Grade 12",
]

const GradesTheyTeach = ({
  data,
  updateData,
  onNext,
  ...props
}: FormPageProps) => {
  const hasExperience = data.isTeachingInSchool || data.hasExperienceInTeaching

  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    hasExperience ? data.gradesTaught : data.gradeInterests
  )

  const updateBackend = async (options: string[]) => {
    try {
      await api.profile.updateAdditionalData({
        data: hasExperience
          ? { grades_taught: options }
          : { grade_interests: options },
      })
    } catch (e) {
      console.error("Failed to update backend:", e)
    }
  }

  const toggleSelection = (selectedOption: string) => {
    const newOptions = selectedOptions.includes(selectedOption)
      ? selectedOptions.filter(option => option !== selectedOption)
      : [...selectedOptions, selectedOption]

    setSelectedOptions(newOptions)

    if (hasExperience) {
      updateData({
        gradesTaught: newOptions,
      })
    } else {
      updateData({
        gradeInterests: newOptions,
      })
    }
  }

  const handleSubmit = async () => {
    await updateBackend(selectedOptions)
    await onNext()
  }

  const isDisabled = selectedOptions.length === 0

  const getOptions = () => {
    if (!hasExperience) {
      return defaultGrades
    }

    const curriculum =
      data.curricula.length > 0
        ? getCurriculumFromValue(data.curricula[0])
        : null

    if (!curriculum) {
      return defaultGrades
    }

    switch (curriculum) {
      case CurriculumOptions.CBSE:
      case CurriculumOptions.ICSE_ISC:
      case CurriculumOptions.StateBoardsIndia:
        return defaultGrades
      case CurriculumOptions.InternationalBaccalaureateIB:
        return gradeOptionsForIB
      case CurriculumOptions.CambridgeCAIE:
        return gradeOptionsForCambridge
      case CurriculumOptions.BritishCurriculum:
        return gradeOptionsForBritishCurriculum
      case CurriculumOptions.AmericanCurriculum:
        return americanCurriculum
      default:
        return defaultGrades
    }
  }

  const options = getOptions()

  return (
    <div>
      <Typography variant="title2" color="onSurface.900">
        {data.isTeachingInSchool
          ? "Which grade(s) are you currently teaching?"
          : data.hasExperienceInTeaching
          ? "Which grade(s) have you taught?"
          : "Which grades would you like to teach?"}
      </Typography>
      <div className="mt-4 flex max-w-3xl flex-wrap gap-1.5">
        {options.map((option, index) => (
          <CheckBoxTextContainer
            key={index}
            text={option}
            minSize={false}
            isSelected={selectedOptions.includes(defaultGrades[index])}
            onClick={() => toggleSelection(defaultGrades[index])}
          />
        ))}
      </div>

      {getNavigationPortal({
        disableNext: isDisabled,
        onNext: handleSubmit,
        ...props,
      })}
    </div>
  )
}

export default GradesTheyTeach
