import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
  TextField,
} from "@suraasa/placebo-ui"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import isURL from "validator/es/lib/isURL"

type Props = Pick<DialogProps, "open"> & {
  toggle: () => void
  onAdd: (url: string) => void
}

const UploadCertificationUrlDialog = ({ toggle, open, onAdd }: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<{ url: string }>()

  const onSubmit = handleSubmit(formData => {
    onAdd(formData.url)
    toggle()
  })

  useEffect(() => {
    if (!open) reset({})
  }, [open, reset])

  return (
    <Dialog open={open} onOpenChange={toggle}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle onBack={toggle} className="text-strong">
            Add Certificate Link
          </DialogTitle>
        </DialogHeader>
        <DialogBody>
          <form onSubmit={onSubmit}>
            <TextField
              label="Certificate LINK/URL"
              placeholder="Enter the link to the certificate"
              autoFocus
              {...register("url", {
                validate: value =>
                  isURL(value, {
                    protocols: ["https", "http"],
                  }) || "Invalid URL",
              })}
              errors={errors.url?.message}
            />
          </form>
        </DialogBody>
        <DialogFooter>
          <Button type="submit" loading={isSubmitting} onClick={onSubmit}>
            Add Link
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default UploadCertificationUrlDialog
